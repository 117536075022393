import { Carousel } from 'antd';
import styled from 'styled-components';

export const SCarousel = styled(Carousel)`
  background: transparent;
  height: 100%;
  width: 100%;
  text-align: center;

  .slick-slide.slick-active.slick-current > div,
  .slick-slide.slick-active.slick-current > div > div {
    height: 100%;
  }

  .slick-dots {
    justify-content: flex-start;
    margin-left: 30px;

    li {
      width: 16px;
      height: 4px;

      button {
        background-color: #cfcecf;
        border-radius: 5px;
        height: 3px;
        width: 100%;
        opacity: 1;
      }

      &.slick-active {
        width: 25px;

        button {
          background-color: #417dbb;
          height: 4px;
        }
      }
    }
  }

  img {
    width: 100%;
  }
`;

export type SlideFullHeight = {
  fullHeight?: boolean;
};

export const SSlide = styled.div<SlideFullHeight>`
  height: ${({ fullHeight }) => (fullHeight ? 'calc(100vh - 85px)' : '100%')};
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
  position: relative;
`;

export const SSlideTitle = styled.h2`
  color: ${({ theme: { colors } }) => colors.text};
  display: block;
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 30px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 25px;
`;

export const SSlideText = styled.p`
  color: ${({ theme: { colors } }) => colors.text};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
`;
