import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  sidebarIsMini: boolean;
}

const contentToFull = keyframes`
  0% {
    margin-left: 97px;
  }

  100% {
    margin-left: 265px;
  }
`;

const contentToSmall = keyframes`
  0% {
    margin-left: 265px;
  }

  100% {
    margin-left: 97px;
  }
`;

export const Container = styled.div<ContainerProps>`
  margin-top: 75px;
  height: calc(100vh - 75px);
  position: relative;
  display: flex;
  position: relative;

  &.sidebar-mini {
    animation: ${contentToFull} 0.6s;
  }

  &.sidebar-large {
    animation: ${contentToSmall} 0.6s;
  }

  ${({ sidebarIsMini }) => {
    if (sidebarIsMini) {
      return css`
        margin-left: 82px;
        width: calc(100% - 82px);
      `;
    }
    return css`
      margin-left: 265px;
      width: calc(100% - 265px);
    `;
  }}

  .content {
    width: 100%;
    height: 100%;
  }
`;
