import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

const maxWidthMd = '991px';

export const SContainer = styled.div<ContainerProps>`
  ${({ isSite }) => {
    if (isSite) {
      return css`
        background-color: #ffffff;
        padding-left: 0;
        padding-right: 0;
      `;
    }
    return css`
      background-color: transparent;
      padding-left: 30px;
      padding-right: 30px;

      @media (max-width: ${maxWidthMd}) {
        padding-left: 15px;
        padding-right: 15px;
      }
    `;
  }}
`;
