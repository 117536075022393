import React, { forwardRef } from 'react';

import { MaskedInput as AMaskedInput } from 'antd-mask-input';

import { MaskedInputProps } from './types';

const MaskedInput: React.RefForwardingComponent<any, MaskedInputProps> = (props, ref) => {
  return <AMaskedInput allowClear {...props} ref={ref} />;
};

export default forwardRef(MaskedInput);
