export function getTranslations(object: any, prop: string, lang = 'en', callbackProp?: string) {
  if (object && object.translations) {
    const { translations } = object;
    if (translations) {
      if (translations[lang.toLowerCase()]) {
        const language = translations[lang.toLowerCase()];
        if (language[prop]) {
          return language[prop];
        }
      }
    }
  }

  if (object && callbackProp) {
    if (object[callbackProp]) {
      return object[callbackProp];
    }
  }

  return '';
}
