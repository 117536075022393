import api from './api';

export default {
  connection: async (abortController?: AbortController): Promise<any> => {
    return api.get(`sap/connection`, { signal: abortController?.signal });
  },
  provider: {
    search: async (params: object = null): Promise<any> => {
      return api.get(`sap/provider`, params);
    },
  },
  contract: {
    search: async (params: object = null): Promise<any> => {
      return api.get(`sap/contract`, params);
    },
  },
  contractV2: {
    search: async (params: object = null): Promise<any> => {
      return api.get(`sap/contract/v2`, params);
    },
    force: async (params: object = null): Promise<any> => {
      return api.get(`contract/sap/force-update-by-number`, params);
    },
    forceContractById: async (contractId: string): Promise<any> => {
      return api.post(`contract/${contractId}/force-update`);
    },
  },
  ptaxFromDate: async (date: string, params = {}): Promise<any> => {
    return api.get(`sap/ptax/${date}`, params);
  },
};
