import styled from 'styled-components';

export const LinksNotificationContainer = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 1fr 1fr;
  padding: 24px 0;
`;

export const LinkNotification = styled.a`
  color: ${({ theme: { colors } }) => colors.text};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;

  & + a {
    border-left: solid ${({ theme: { colors } }) => colors.greys.grey02} 1px;
  }

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary};
  }

  svg {
    font-size: 16px;
    margin-right: 4px;
  }
`;

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`;

export const Notification = styled.a`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: solid 1px transparent;
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 12px;
  padding: 12px;
  width: calc(100% - 16px);
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  background: #ffffff;
  color: ${({ theme: { colors } }) => colors.text};

  & + a {
    /* border-top: solid 1px #42424240; */
  }

  &.not-read {
    /* background: rgba(255, 162, 60, 0.2); */
  }

  &:hover {
    background: ${({ theme: { colors } }) => colors.primary};
    color: #ffffff;
    /* border-top: solid 1px ${({ theme: { colors } }) => colors.greys.grey03};
    border-bottom: solid 1px ${({ theme: { colors } }) => colors.greys.grey03}; */
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    * + * {
      padding-left: 12px;
    }
  }
`;

export const Subtitle = styled.h4`
  color: ${({ theme: { colors } }) => colors.primary};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 8px 0;
`;
