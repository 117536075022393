import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { notification, Image } from 'antd';
import Echo from 'laravel-echo';
import { broadcaster } from 'laravel-echo-api-gateway';
import Pusher from 'pusher-js';

import DTOErrorReponse from '../@types/dtos/DTOErrorReponse';
import DTONotification from '../@types/dtos/user/DTONotification';
import NotificationData from '../@types/hooks/notifications';
import { ModalStatus } from '../components';
import adminApi from '../services/api/admin';
import { useAuth } from './auth';
import { usePage } from './page';

const NotificationContext = createContext<NotificationData>({} as NotificationData);

const NotificationProvider: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation();

  const { user, getToken } = useAuth();
  const { alertStatus } = usePage();
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<DTONotification[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isWSOpen, setIsWSOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isFetchingNotifications, setIsFetchingNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [totalPagesAllNotifications, setTotalPagesAllNotifications] = useState(1);

  const key = `open${Date.now()}`;
  (window as any).Pusher = Pusher;

  const websocket = useMemo(() => {
    if (!isWSOpen) {
      setIsWSOpen(true);

      return new Echo({
        broadcaster,
        host: import.meta.env.VITE_WEBSOCKET_URL,
      });
    }

    return null;
    // eslint-disable-next-line
  }, [user?.id]);

  /** estabilish websocket connection */
  const connectToWebSocket = useCallback(() => {
    if (websocket) {
      websocket.channel(`App.Model.User.${user.id}`).notification((response: any) => {
        setNotifications((item: any) => [
          { id: response.id, data: { ...response, created_at: response.created_at ?? new Date() } },
          ...item,
        ]);
        setNotificationCount(oldNotificationCount => oldNotificationCount + 1);
        notification.open({
          message: typeof response.title === 'string' ? response.title : response.title[i18n.language],
          description: typeof response.body === 'string' ? response.body : response.body[i18n.language],
          icon: <Image width={30} src={response.icon} />,
          placement: 'topRight',
          key,
          onClick: () => {
            notification.close(key);
            window.open(response.click_action, '_blank');
            handleMenuClick(response.id);
          },
        });
      });
    }
    // eslint-disable-next-line
  }, [getToken, history, key, user]);

  /**
   * load notifications and stabilish connection to websocket
   */
  const updateNotifications = (page?: number, unread = false) => {
    if (user) {
      const params = {
        unread,
      } as { language: string; page?: number; per_page?: number; unread: boolean };

      if (page) {
        params.page = page;
        params.per_page = 100;
      }

      setIsFetchingNotifications(true);

      adminApi.users.notifications
        .get(user.id, {
          params,
        })
        .then(({ data: responseData }) => {
          setTotalPages(responseData.last_page);
          setNotificationCount(Number(responseData.total || 0));
          setNotifications(notificationsState => [...notificationsState, ...responseData.data]);
          setIsFetchingNotifications(false);
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => connectToWebSocket());
    }
  };

  const getAllNotifications = useCallback(
    async (pageNumber: number) => {
      setLoadingNotifications(true);
      adminApi.users.notifications
        .get(user.id, { params: { per_page: 20, page: pageNumber } })
        .then(({ data: responseData }) => {
          const totalPagesQtd = responseData.last_page;

          setTotalPagesAllNotifications(totalPagesQtd);

          if (pageNumber === 1) {
            setAllNotifications([...responseData.data]);
          } else {
            setAllNotifications(notificationsState => [...notificationsState, ...responseData.data]);
          }
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoadingNotifications(false);
        });
    },
    // eslint-disable-next-line
    [user?.id],
  );

  const unreadNotifications = useMemo(() => {
    if (notifications !== undefined && notifications.length > 0) {
      return notifications.filter((item: DTONotification) => !item.read_at);
    }
    return [];
  }, [notifications]);

  /**
   * onClick event to children of notification dropdown
   *
   * @param e
   */
  const handleMenuClick = useCallback(
    (action?: string, callback?: () => void, isAllNotifications?: boolean) => {
      switch (action) {
        case 'read-all':
          adminApi.users.notifications
            .readAll(user.id)
            .then(response => {
              setNotificationCount(Number(response.total || 0));
              setNotifications(response.data);
              if (callback) {
                callback();
              }
            })
            .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
          break;
        case 'remove-all':
          adminApi.users.notifications
            .removeAll(user.id)
            .then(() => {
              setNotificationCount(0);
              setNotifications([]);
              if (callback) {
                callback();
              }
            })
            .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
          break;
        case 'view-all':
          navigate(`/notifications`);
          break;
        default:
          const findNotification = (isAllNotifications ? allNotifications : notifications).find(
            item => item.id === action,
          );
          if (findNotification && findNotification.data) {
            if (findNotification.data.click_action) {
              const shouldRemoveNotificationCount = !findNotification.read_at;
              window.open(findNotification.data.click_action, '_blank');
              adminApi.users.notifications
                .read(action)
                .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                .then(() => {
                  setNotifications(notifications.filter(item => item.id !== action));
                  if (shouldRemoveNotificationCount) setNotificationCount(notificationCount - 1);
                });
            }
          }
          break;
      }

      setDrawerVisible(false);
    },
    // eslint-disable-next-line
    [history, notifications, user, allNotifications],
  );

  const handleReadAll = useCallback(
    async (callback?: () => void) => {
      ModalStatus({
        type: 'confirm',
        title: t('modal.confirm'),
        subTitle: t('pages.notifications.modal.confirmReadAll'),
        cancelText: t('modal.cancel'),
        onOk: () => handleMenuClick('read-all', callback),
      });
    },
    // eslint-disable-next-line
    [handleMenuClick, t],
  );

  const handleRemoveAll = useCallback(
    async (callback?: () => void) => {
      ModalStatus({
        type: 'confirm',
        title: t('modal.confirm'),
        subTitle: t('pages.notifications.modal.confirmRemoveAll'),
        cancelText: t('modal.cancel'),
        onOk: () => handleMenuClick('remove-all', callback),
      });
    },
    // eslint-disable-next-line
    [handleMenuClick, t],
  );

  useEffect(() => {
    updateNotifications(1, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user) {
      setIsWSOpen(false);
      websocket?.disconnect();
    }
  }, [user]);

  return (
    <NotificationContext.Provider
      value={{
        handleMenuClick,
        handleReadAll,
        handleRemoveAll,
        notifications,
        unreadNotifications,
        drawerVisible,
        setDrawerVisible,
        updateNotifications,
        totalPages,
        isFetchingNotifications,
        notificationCount,
        getAllNotifications,
        loadingNotifications,
        totalPagesAllNotifications,
        allNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function useNotifications() {
  return useContext(NotificationContext);
}

export { NotificationProvider, useNotifications };
