import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IntegrationLeds = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 5px;
`;

const pulseOn = keyframes`
	0% {
		background: rgb(21, 151, 52);
		box-shadow: 0 0 10px 1px rgb(21, 151, 52);
	}

	100% {
		background: rgb(21, 151, 52, 0.9);
		box-shadow: 0 0 8px 1px rgb(21, 151, 52, 0.8);
	}
`;

const pulseOff = keyframes`
	0% {
		background: rgb(255, 107, 60);
		box-shadow: 0 0 10px 1px rgb(255, 107, 60);
	}

	100% {
		background: rgb(255, 107, 60, 0.9);
		box-shadow: 0 0 10px 1px rgb(255, 107, 60, 0.8);
	}
`;

const pulseLoading = keyframes`
	0% {
		background: rgb(255, 162, 60);
		box-shadow: 0 0 10px 1px rgb(255, 162, 60);
	}

	100% {
		background: rgb(97, 60, 19);
		box-shadow: 0 0 8px 1px rgb(97, 60, 19, 0.5);
	}
`;

export const IntegrationStatusLed = styled.div`
  &[data-status='on'] {
    animation: ${pulseOn} 2s alternate infinite;
  }
  &[data-status='off'] {
    animation: ${pulseOff} 2s alternate infinite;
  }
  &[data-status='loading'] {
    animation: ${pulseLoading} 1s alternate infinite;
  }
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
