import api from '@/services/api/api';
import exceptionHandler from '@/services/exceptions';
import { useMutation } from '@tanstack/react-query';

export const useToggleActiveUserMutation = () => {
  return useMutation({
    mutationFn: async (userId: string) => {
      const response = await api.delete(`admin/user/${userId}`);
      return response.data.data;
    },
    mutationKey: ['admin/user/delete'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};
