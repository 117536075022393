import { Tree as AntTree } from 'antd';
import styled from 'styled-components';

export const STree = styled(AntTree.DirectoryTree)`
  &.antd-tree .ant-tree-title {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 16px;
    color: ${({ theme: { colors } }) => colors.text};
  }
`;
