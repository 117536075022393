import styled, { css } from 'styled-components';

import { FixedFooterPropsStyles } from './types';

const footerVariations = {
  default: css`
    background: #ffffff;
    color: ${({ theme: { colors } }) => colors.text};
  `,
  warning: css`
    background: ${({ theme: { colors } }) => colors.warning}; /* #ffb461 */
    color: ${({ theme: { colors } }) => colors.text};
  `,
  danger: css`
    background: ${({ theme: { colors } }) => colors.danger};
    color: ${({ theme: { colors } }) => colors.text};
  `,
};

export const Container = styled.footer<FixedFooterPropsStyles>`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.16);
  color: #ffffff;
  padding: 16px;
  z-index: 2;

  min-height: 80px;

  ${({ sidebarMini }) =>
    sidebarMini
      ? css`
          width: calc(100% - 82px);
        `
      : css`
          width: calc(100% - 250px);
        `}
  ${({ status }) => (status ? footerVariations[status] : footerVariations.default)}

  .feedback-title {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
  }

  .feedback-description {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
  }
`;
