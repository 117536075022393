import { AxiosResponse } from 'axios';

import { ContentDisposition } from '../utils/ContentDisposition';

export const downloadHandler = (response: AxiosResponse | string | File, name?: string) => {
  let link: HTMLAnchorElement | null = null;
  if (typeof response === 'string') {
    link = document.createElement('a');
    link.href = response;
    link.setAttribute('download', name ?? 'files');
  } else if (response instanceof Blob) {
    const url = window.URL.createObjectURL(response);
    link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name ?? 'files');
  } else {
    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);

    const textHeader = response.headers['content-disposition'];
    const fileName = ContentDisposition.getFileName(textHeader);

    link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
