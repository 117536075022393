import { PaginatedQueryParams, usePaginatedQuery } from '@/hooks/use-paginated-query';
import { UserPermissionData } from '@/types/User/UserPermissionData';

type Params = PaginatedQueryParams & {
  users: string[];
};

export const useGetUserStatusQuery = ({ ...params }: Params) => {
  return usePaginatedQuery<UserPermissionData>({
    queryKey: ['credit-request/team-status', params.users, params.current, params.pageSize, params.sort, params.search],
    url: `credit-request/team-status`,
    params: {
      ...params,
      params: {
        users: params.users,
      },
    },
  });
};
