/* eslint-disable no-plusplus */
const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

const base64ToFile = (base64: string, filename: string) => {
  if (!base64) return null;
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const FileUtils = {
  base64ToFile,
  async getBase64(file: File): Promise<string> {
    try {
      const result = await toBase64(file);
      return result;
    } catch (error) {
      console.error(error);
      return '';
    }
  },
  getOriginalName(fileName: string, withExtension = false) {
    const splitName = fileName.split('.');
    const splitLength = splitName.length;
    const extension = splitName[splitLength - 1];
    delete splitName[splitLength - 1];
    const finalFileName = splitName
      .filter((s: string) => !!s)
      .join('_')
      .replace(/ /g, '_');
    return !withExtension ? finalFileName : `${finalFileName}.${extension}`;
  },
};
