import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 50vh;

  h1 {
    font-weight: bold;
    font-size: 24px;
    color: #cacaca;
  }
`;
