/* eslint-disable import/no-unresolved */
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Ability, AbilityBuilder, AnyAbility } from '@casl/ability';
import { Ability as AbilityType } from '@casl/ability/dist/types/Ability';
import { createContextualCan } from '@casl/react';
import { message } from 'antd';

import { useAuth } from './auth';

const AbilityContext = createContext<AnyAbility>({} as AnyAbility);
const Can = createContextualCan(AbilityContext.Consumer);

const AbilityProvider: React.FC = ({ children }) => {
  const subjectName = useCallback((item: any): string => {
    return !item || typeof item === 'string' ? item : typeof item;
  }, []);

  const [ability, setAbility] = useState<AbilityType>(() => {
    return new Ability([], { subjectName }) || (null as AbilityType);
  });
  const { user, permissions } = useAuth();

  const updateRules = useCallback((): any => {
    const { can, rules } = new AbilityBuilder();

    if (permissions) {
      permissions.map((item: string) => can(item, ''));
    }

    return rules;
  }, [permissions]);

  useEffect(() => {
    if (!ability) {
      setAbility(new Ability([], { subjectName }));
    } else {
      const rules = updateRules();
      setAbility(() => ability.update(rules));
    }
  }, [user, ability, subjectName, updateRules]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

function useAbility() {
  const { permissions } = useAuth();
  const updateRules = useCallback((): any => {
    const { can, rules } = new AbilityBuilder();

    if (permissions) {
      permissions.map((item: string) => can(item, ''));
    }

    return rules;
  }, [permissions]);

  const rules = updateRules();

  return new Ability(rules);
}

export const useCanRunFunction = () => {
  const ability = useAbility();
  const { t } = useTranslation();

  const canRunFunction = <T,>(permission: string, func: T): T =>
    ability.can(permission, '') || !permission ? (func as any)() : message.error(<span>{t('noPermission')}</span>);

  return canRunFunction;
};

export { AbilityProvider, Can, useAbility };
