/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PermissionedComponent from '@/components/PermissionedComponent';
import { cn } from '@/lib/utils';
import { CloudUpload, FileX, Trash } from 'lucide-react';
import { v4 as uuid } from 'uuid';

import { useToast } from './use-toast';

export type ProfilePickerProps = {
  classNames?: {
    container?: string;
    root?: string;
  };
  onChange?: ({ target: { name, value } }: { target: { name: string; value: File } }) => void;
  onValueChange?: (value: File) => void;
  value?: File;
  name?: string;
  onError?: (error: string) => void;
  allowedExtensions?: string[];
  disabled?: boolean;
  onUpload?: (file: File) => void;
  permissions?: string | string[];
};

export const ProfilePicker = ({
  name,
  onChange,
  classNames,
  value,
  onError,
  allowedExtensions,
  disabled,
  onValueChange,
  onUpload,
  permissions,
}: ProfilePickerProps) => {
  const { toast } = useToast();
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [_, setDragging] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [id] = useState(uuid());

  const isDisabled = !!value || disabled;

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    setHasError(false);
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files ?? []);
    if (files.length > 0 && handleValidateFile(files[0])) {
      if (onChange && name) {
        if (onValueChange) onValueChange(files[0]);
        onChange({ target: { name, value: files[0] } });
      }
      e.dataTransfer.clearData();
    }
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false);

    if (onChange && name && e.target.files.length && handleValidateFile(e.target.files[0])) {
      const file = e.target.files[0];
      if (onValueChange) onValueChange(file);
      if (onUpload) onUpload(file);
      onChange({ target: { name, value: file } });
    }
  };

  const handleValidateFile = (file: File) => {
    if (file.size / 1024 > 50000) {
      setHasError(true);
      if (onError) onError(t('components.upload.fileTooBig.title'));
      toast({
        title: t('components.upload.fileTooBig.title'),
        description: t('components.upload.fileTooBig.description'),
      });
      return false;
    }
    return true;
  };

  const removeFile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (onChange && name) {
      if (onValueChange) onValueChange(null);
      onChange({ target: { name, value: null } });
      if (inputRef.current) inputRef.current.value = '';
    }
  };

  return (
    <div
      className={cn(
        'flex h-[200px] w-[200px] min-w-[200px] flex-wrap items-center overflow-hidden rounded-full border border-dashed bg-white',
        hasError ? 'border-red-600' : '',
      )}
    >
      <PermissionedComponent permission={permissions}>
        {value ? (
          <div className="group relative h-full w-full">
            <img src={URL.createObjectURL(value)} alt="profile" className="h-full w-full object-cover" />
            <div
              className="overlay absolute left-0 top-0 flex h-full w-full cursor-pointer items-center justify-center bg-gray-200/70 opacity-0 transition-all group-hover:opacity-100"
              onClick={removeFile}
            >
              <Trash size={40} />
            </div>
          </div>
        ) : (
          <label
            htmlFor={id}
            className={cn(
              'custom-file-input group h-full w-full [&:focus-within>*]:bg-blue-100',
              classNames?.container,
            )}
          >
            <div
              className="h-full w-full"
              onDragOver={handleDrag}
              onDragEnter={handleDragIn}
              onDragLeave={handleDragOut}
              onDrop={handleDrop}
            >
              <div
                className={cn(
                  'text-mg relative flex h-full w-full flex-col items-center justify-center gap-2 border-b border-dashed border-gray-100 p-4 font-bold text-gray-400 shadow-sm',
                  isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
                  hasError ? 'text-red-600' : '',
                )}
              >
                <input
                  id={id}
                  type="file"
                  ref={inputRef}
                  accept={allowedExtensions?.join(',')}
                  className="h-0 w-0 opacity-0"
                  disabled={isDisabled}
                  onChange={handleChangeFile}
                  multiple={false}
                />
                {hasError ? <FileX size={30} /> : <CloudUpload size={30} />}
                <span className="text-center">
                  {hasError ? t('components.upload.fileTooBig.title') : t('components.upload.description')}
                </span>
              </div>
            </div>
          </label>
        )}
      </PermissionedComponent>
    </div>
  );
};
