import styled, { keyframes } from 'styled-components';

import bannerAlgodao from '../../assets/img/banners/bg-algodao.jpg';
import bannerCafe from '../../assets/img/banners/bg-cafe.jpg';
import bannerCana from '../../assets/img/banners/bg-cana.jpg';
import bannerMilho from '../../assets/img/banners/bg-milho.jpg';
import bannerSoja from '../../assets/img/banners/bg-soja.jpg';
import bannerTrigo from '../../assets/img/banners/bg-trigo.jpg';

const slide = keyframes`
  0% {
    background-image: url(${bannerAlgodao});
  }
  12% {
    background-image: url(${bannerAlgodao});
  }

  15% {
    background-image: url(${bannerMilho});
  }
  29% {
    background-image: url(${bannerMilho});
  }

  32% {
    background-image: url(${bannerSoja});
  }
  46% {
    background-image: url(${bannerSoja});
  }

  49% {
    background-image: url(${bannerCana});
  }
  63% {
    background-image: url(${bannerCana});
  }

  66% {
    background-image: url(${bannerCafe});
  }
  80% {
    background-image: url(${bannerCafe});
  }

  83% {
    background-image: url(${bannerTrigo});
  }
  97% {
    background-image: url(${bannerTrigo});
  }

  100% {
    background-image: url(${bannerAlgodao});
  }
`;

export const Container = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  animation: ${slide} 25s linear infinite;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 16px 24px;
  }
`;
