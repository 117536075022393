/**
 * (c) 2017 Rob Wu <rob@robwu.nl> (https://robwu.nl)
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/.
 *
 * @see {@doc https://github.com/Rob--W/open-in-browser/blob/master/extension/content-disposition.js}
 */
export const ContentDisposition = {
  /**
   * Extract file name from the Content-Disposition HTTP response header.
   *
   * @param {string} contentDisposition
   * @return {string} Filename, if found in the Content-Disposition header.
   */
  getFileName: (contentDisposition: string): string => {
    const search = 'filename';
    const result: RegExpExecArray = new RegExp(
      `(?:^|;)\\s*${search}\\s*=\\s*` +
        // Captures: value = token | quoted-string
        // (RFC 2616, section 3.6 and referenced by RFC 6266 4.1)
        `(` +
        `[^";\\s][^;\\s]*` +
        `|` +
        `"(?:[^"\\\\]|\\\\"?)+"?` +
        `)`,
      'i',
    ).exec(contentDisposition);
    return result[1] ?? '';
  },
};
