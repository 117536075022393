import { PaginatedQueryParams, usePaginatedQuery } from '@/hooks/use-paginated-query';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from '@/hooks/use-select-infinite.query';
import UserData from '@/types/User/UserData';

type Params = PaginatedQueryParams;

export const useGetUsersQuery = ({ dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<UserData>({
    queryKey: ['admin/user', params],
    url: `admin/user`,
    params: {
      ...params,
      dispatchPagination,
    },
  });
};

export const useGetUsersSelectQuery = (params: SelectInfiniteQueryParams) => {
  const query = useSelectInfiniteQuery<UserData>({
    url: 'admin/user',
    queryKey: ['admin/user', params],
    staleTime: 1000 * 60 * 5,
    permissions: ['admin.user.index'],
    params,
  });

  return query;
};
