import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdSmartphone } from 'react-icons/md';

import { useAlertDialog } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { FormGrid } from '@/components/ui/form-grid';
import { FormItem } from '@/components/ui/form-item';
import { PasswordInput } from '@/components/ui/input';
import Loading from '@/components/ui/loading';
import { PageTitle } from '@/components/ui/page-title';
import { UserFormData } from '@/types/User/UserFormData';

import { useAuthentication } from '../../../hooks/fetch/useAuthentication';
import UserData from '../../../types/User/UserData';
import { generatePassword } from '../../../utils/Password';
import { useUpdateUserMutation } from './api/use-update-user.mutation';
import { useFormPasswordValidation } from './hooks/useFormPasswordValidation';

const initialValues = {
  password: '',
  confirm: '',
};

export const AuthTab = ({ user, handleHideForm }: { user: UserData; handleHideForm: () => void }) => {
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: initialValues,
  });
  const { clear2FA, isFetching } = useAuthentication();
  const { alert } = useAlertDialog();
  const formPasswordValidation = useFormPasswordValidation();

  const updateUserMutation = useUpdateUserMutation();

  const handleSuccess = (messageSuccess = '') =>
    alert({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleEdit = async () => {
    const values = form.getValues();
    const response = await updateUserMutation.mutateAsync({ ...values, id: user.id } as UserFormData);

    if (response) {
      handleSuccess(t('pages.admin.user.passwordChanged'));
      handleHideForm();
      form.reset();
    }
  };

  const handleGeneratePassword = () => {
    const password = generatePassword();
    form.reset({ confirm: password, password });
  };

  return (
    <Form form={form} className="p-4" onSubmit={console.log}>
      <Loading isLoading={isFetching}>
        <PageTitle>{t('pages.admin.user.authTitle')}</PageTitle>
        <FormGrid>
          <FormItem
            name="password"
            label={t('new-password')}
            rules={{
              required: true,
              validate: formPasswordValidation,
            }}
          >
            <PasswordInput maxLength={255} />
          </FormItem>
          <FormItem
            name="confirm"
            label={t('confirm-password')}
            rules={{
              required: true,
              validate: {
                confirm: (value: string) => {
                  if (value !== form.getValues().password) {
                    return t('user.form.password.validator.confirm');
                  }
                  return true;
                },
              },
            }}
          >
            <PasswordInput maxLength={255} />
          </FormItem>

          <div className="flex h-full w-full items-end gap-4">
            <Button type="button" className="w-full" onClick={handleGeneratePassword}>
              {t('suggest-password')}
            </Button>
            <Button
              isLoading={isFetching}
              disabled={!user?.two_factor_is_configured}
              onClick={() => {
                alert({
                  type: 'confirm',
                  title: t('pages.admin.permission.delete.title'),
                  subTitle: t('pages.admin.permission.delete.subtitle'),
                  cancelText: t('pages.admin.permission.delete.cancel'),
                  okText: t('pages.admin.permission.delete.confirm'),
                  onOk: () => clear2FA(user.id),
                });
              }}
            >
              <MdSmartphone /> {t('reset-2fa')}
            </Button>
          </div>
        </FormGrid>

        <div className="flex w-full items-center justify-end gap-2">
          <Button variant="secondary" type="reset" onClick={handleHideForm} disabled={updateUserMutation.isPending}>
            {t('pages.admin.user.buttonCancel')}
          </Button>
          <Button
            type="submit"
            onClick={handleEdit}
            isLoading={updateUserMutation.isPending}
            disabled={updateUserMutation.isPending}
          >
            {t('pages.admin.user.buttonEdit')}
          </Button>
        </div>
      </Loading>
    </Form>
  );
};
