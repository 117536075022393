import api from './api';

export default {
  creditData: async (): Promise<any> => {
    return api.get('credit-request/importAll');
  },

  personData: async (): Promise<any> => {
    return api.get('person/importAll');
  },

  states: async (): Promise<any> => {
    return api.get('person/city/getAll');
  },

  cities: async (): Promise<any> => {
    return api.get('person/state/getAll');
  },
};
