import { ModalStatus } from '../components';

export const exceptionHandler = (e: any, callback?: () => void) => {
  let title = e.message;
  let errors = [];

  if (e.response !== undefined) {
    errors = e.message;

    if (e?.response) {
      if (e?.response?.data && typeof e?.response?.data === 'string') {
        title = e.response.data;
      } else {
        title = e.response.data.message;
      }
    }

    if (e.response.data.errors !== undefined) {
      errors = Array.isArray(e.response.data.errors)
        ? e.response.data.errors
        : Object.keys(e.response.data.errors).map(key => e.response.data.errors[key]);
    }
  }
  ModalStatus({
    type: 'error',
    title,
    subTitle: errors,
    onOk: callback,
  });
};

export default exceptionHandler;
