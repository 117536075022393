import { lazy } from 'react';
import { AiFillDatabase, AiFillDollarCircle, AiOutlineBarChart } from 'react-icons/ai';
import { BsGearFill, BsPersonSquare } from 'react-icons/bs';
import {
  FaBook,
  FaColumns,
  FaDownload,
  FaFileAlt,
  FaFolderOpen,
  FaLeaf,
  FaMapMarkerAlt,
  FaMoneyCheckAlt,
  FaSuitcase,
  FaUser,
  FaUserCheck,
  FaUsers,
  FaUserShield,
  FaUserTag,
  FaUserTie,
  FaFileInvoice,
  FaHome,
  FaShip,
  FaCalendarAlt,
} from 'react-icons/fa';
import { GiField, GiFiles } from 'react-icons/gi';
import { ImInsertTemplate } from 'react-icons/im';
import { MdAssignment, MdDashboard, MdMonetizationOn } from 'react-icons/md';
import { RiRoadMapFill, RiMoneyDollarCircleLine } from 'react-icons/ri';

import { PermissionGroups } from '@/pages/Admin/permission-groups/permission-groups';
import { PermissionGroupsForm } from '@/pages/Admin/permission-groups/permission-groups.form';
import { UserPage } from '@/pages/Admin/User/user';
import { User } from '@/pages/Admin/User/user-details';

const Login = lazy(() => import('../pages/Auth/Login'));
const Forgot = lazy(() => import('../pages/Auth/Forgot'));
const Expired = lazy(() => import('../pages/Auth/Expired'));
const ValidateEmail = lazy(() => import('../pages/Auth/ValidateEmail'));
const twoFactorAuth = lazy(() => import('../pages/Auth/TwoFactorAuth'));
const Recover = lazy(() => import('../pages/Auth/Recover'));
const Docusing = lazy(() => import('../pages/CreditRequest/components/organisms/FormalizationGuarantees/Docusing'));
const Home = lazy(() => import('../pages/Home'));
const Management = lazy(() => import('../pages/Dashboard/Management'));
const RequestsPrefin = lazy(() => import('../pages/Dashboard/RequestsPrefin'));
const WarrantyPrefin = lazy(() => import('../pages/Dashboard/WarrantyPrefin'));
const SlaDashboard = lazy(() => import('../pages/Dashboard/sla/sla-dashboard'));
const MonitoringPrefin = lazy(() => import('../pages/Dashboard/MonitoringPrefin'));
const Prepay = lazy(() => import('../pages/Dashboard/Prepay'));
const ExposurePrepay = lazy(() => import('../pages/Dashboard/ExposurePrepay'));
const Exposures = lazy(() => import('../pages/Dashboard/Exposures/exposures'));
const RegisteredCustomers = lazy(() => import('../pages/RegisteredCustomers'));
const CreditRequest = lazy(() => import('../pages/CreditRequest'));
const Downloads = lazy(() => import('../pages/Downloads'));
const Permission = lazy(() => import('../pages/Admin/Permission'));
const Role = lazy(() => import('../pages/Admin/Role'));
const ChangeLog = lazy(() => import('../pages/Admin/ChangeLog'));
const Position = lazy(() => import('../pages/Admin/Position'));
const Phases = lazy(() => import('../pages/Admin/Phases'));
const Teams = lazy(() => import('../pages/Admin/Teams'));
const Status = lazy(() => import('../pages/Admin/Status'));
const TeamStatus = lazy(() => import('../pages/Admin/TeamStatus'));
const CreditRequestParameterization = lazy(() => import('../pages/Admin/CreditRequestParameterization'));
const DataProviders = lazy(() => import('../pages/Integrations/DataProviders'));
const IntegrationRules = lazy(() => import('../pages/Integrations/IntegrationRules'));
const ExposureFileUpload = lazy(() => import('../pages/Integrations/FileUpload'));
const Groups = lazy(() => import('../pages/Integrations/Groups'));
const Harvest = lazy(() => import('../pages/Registers/Harvest'));
const Operation = lazy(() => import('../pages/Registers/Operation'));
const SerasaParameterization = lazy(() => import('../pages/Serasa/Parameterization'));
const SerasaIntegrations = lazy(() => import('../pages/Serasa/Integrations'));
const SerasaCounterpartyEdit = lazy(() => import('../pages/Serasa/Counterparty/Edit'));
const BasePort = lazy(() => import('../pages/Registers/BasePort'));
const Farm = lazy(() => import('../pages/Monitoring/Farm'));
const Cpr = lazy(() => import('../pages/Monitoring/Cpr'));
const Registration = lazy(() => import('../pages/Monitoring/Registration'));
const Survey = lazy(() => import('../pages/Monitoring/Survey'));
const SurveyUpdate = lazy(() => import('../pages/Monitoring/Survey/Update'));
const Operator = lazy(() => import('../pages/Monitoring/Owner'));
const SurveyRequest = lazy(() => import('../pages/Monitoring/SurveyRequest'));
const SurveyForm = lazy(() => import('../pages/Monitoring/SurveyRequest/Form'));
const Region = lazy(() => import('../pages/Registers/Region'));
const PriceDay = lazy(() => import('../pages/Registers/PriceDay'));
const Subsidiary = lazy(() => import('../pages/Registers/Subsidiary'));
const TemplateDocs = lazy(() => import('../pages/EDocuments/TemplateDocs'));
const ContractList = lazy(() => import('../pages/Contracts/ContractList'));
const Emission = lazy(() => import('../pages/Contracts/Emission'));
const ContractTypes = lazy(() => import('../pages/Contracts/ContractTypes'));
const Templates = lazy(() => import('../pages/Contracts/Templates'));
const Notifications = lazy(() => import('../pages/Notifications'));
const Sustainability = lazy(() => import('../pages/Sustainability'));
const SustainabilityRequests = lazy(() => import('../pages/Sustainability/Requests'));
const SustainabilityUserRules = lazy(() => import('../pages/Sustainability/UserRules'));
const SustainabilityTeamStatus = lazy(() => import('../pages/Sustainability/TeamStatus'));
const SerasaCounterparty = lazy(() => import('../pages/Serasa/Counterparty'));
const Profile = lazy(() => import('../pages/Auth/Profile'));
const NotFound = lazy(() => import('../pages/Exception'));
export interface Route {
  id?: string;
  forAll?: boolean;
  path?: string;
  exact?: boolean;
  isPrivate?: boolean;
  ifAuthRedirect?: boolean;
  permission?: string;
  component?: React.ComponentType;
  menu?: {
    icon?: string | React.ComponentType;
    key: string;
    label: string;
    parent?: string;
    submenu?: string;
  };
}

const routes: Route[] = [
  {
    path: '/',
    exact: true,
    isPrivate: false,
    component: Login,
  },
  {
    path: '/login',
    exact: true,
    isPrivate: false,
    component: Login,
  },
  {
    path: '/forgot', // Recuperação de senha - link para /recover/:token
    exact: true,
    isPrivate: false,
    component: Forgot,
  },
  {
    path: '/expired', // Recuperação de senha - link para /recover/:token
    exact: true,
    component: Expired,
    isPrivate: true,
    ifAuthRedirect: false,
  },
  {
    path: '/email/verify/:user?/:hash?',
    exact: true,
    isPrivate: false,
    component: ValidateEmail,
    ifAuthRedirect: false,
  },
  {
    path: '/two-factor-auth',
    exact: true,
    isPrivate: false,
    component: twoFactorAuth,
    ifAuthRedirect: false,
  },
  {
    path: '/recover', // Criar nova senha
    exact: true,
    isPrivate: false,
    component: Recover,
  },
  {
    path: '/docusing',
    exact: false,
    isPrivate: true,
    component: Docusing,
  },
  {
    path: '/home',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'home',
    component: Home,
    menu: {
      icon: FaHome,
      key: 'home',
      parent: 'home',
      label: 'menu.home',
    },
  },
  // dashboard
  {
    id: 'dashboard',
    permission: 'dashboard',
    exact: true,
    isPrivate: true,
    menu: {
      icon: MdDashboard,
      key: 'dashboard',
      parent: 'dashboard',
      label: 'menu.dashboard',
    },
  },
  {
    path: '/dashboard/management',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.management.index',
    component: Management,
    menu: {
      icon: AiOutlineBarChart,
      key: 'management',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.management',
    },
  },
  {
    path: '/dashboard/requests-prefin',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.requests.prefin.index',
    component: RequestsPrefin,
    menu: {
      icon: MdAssignment,
      key: 'requests-prefin',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.requests-prefin',
    },
  },
  {
    path: '/dashboard/warranty-prefin',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.warranty.prefin.index',
    component: WarrantyPrefin,
    menu: {
      icon: AiOutlineBarChart,
      key: 'warranty-prefin',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.warranty-prefin',
    },
  },
  {
    path: '/dashboard/sla',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.sla.index',
    component: SlaDashboard,
    menu: {
      icon: FaCalendarAlt,
      key: 'slas',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.sla',
    },
  },
  {
    path: '/dashboard/monitoring-prefin',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.monitoring.prefin.index',
    component: MonitoringPrefin,
    menu: {
      icon: FaMoneyCheckAlt,
      key: 'monitoring-prefin',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.monitoring-prefin',
    },
  },
  /** Cadastro de Pessoas */
  {
    path: '/dashboard/prepay',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.prepay.index',
    component: Prepay,
    menu: {
      icon: MdMonetizationOn,
      key: 'prepay',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.prepay',
    },
  },
  {
    path: '/dashboard/expoprepay',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.exposure.index',
    component: ExposurePrepay,
    menu: {
      icon: MdMonetizationOn,
      key: 'expoprepay',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.expoprepay',
    },
  },
  {
    path: '/dashboard/exposures',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.exposure.operationalrisk',
    component: Exposures,
    menu: {
      icon: MdMonetizationOn,
      key: 'exposures',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.exposures',
    },
  },
  {
    path: '/registered-customers',
    exact: true,
    isPrivate: true,
    permission: 'person',
    component: RegisteredCustomers,
    menu: {
      icon: FaUser,
      key: 'register-prefin',
      parent: 'registered-customers',
      label: 'menu.register-prefin',
    },
  },
  {
    path: '/registered-customers/:personType/:permission',
    exact: true,
    isPrivate: true,
    permission: 'person.index',
    component: RegisteredCustomers,
  },
  {
    path: '/registered-customers/:personType/:permission/:id',
    exact: true,
    isPrivate: true,
    permission: 'person.index',
    component: RegisteredCustomers,
  },
  /** Solicitação de Crédito */
  {
    path: '/credit-request',
    exact: true,
    isPrivate: true,
    permission: 'credit',
    component: CreditRequest,
    menu: {
      icon: FaMoneyCheckAlt,
      key: 'credit-request',
      parent: 'credit-request',
      label: 'menu.credit-request',
    },
  },
  {
    path: '/credit-request/:permission/',
    exact: false,
    isPrivate: true,
    permission: 'credit.request.index',
    component: CreditRequest,
  },
  {
    path: '/credit-request/:permission/:id',
    exact: false,
    isPrivate: true,
    permission: 'credit.request.index',
    component: CreditRequest,
  },
  {
    path: '/credit-request/:permission/:id/:activeTab',
    exact: false,
    isPrivate: true,
    permission: 'credit.request.index',
    component: CreditRequest,
  },
  /** Downloads */
  {
    path: '/downloads',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'downloads',
    component: Downloads,
    menu: {
      icon: FaDownload,
      key: 'downloads',
      parent: 'downloads',
      label: 'menu.downloads',
    },
  },
  /** Administração */
  {
    id: 'admin',
    permission: 'admin',
    menu: {
      icon: FaUserShield,
      key: 'admin',
      parent: 'admin',
      label: 'menu.admin',
    },
  },
  {
    path: '/admin/permission',
    exact: true,
    isPrivate: true,
    permission: 'admin.permission.index',
    component: Permission,
    menu: {
      icon: FaUserCheck,
      key: 'permission',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_permission',
    },
  },
  {
    path: '/admin/role',
    exact: true,
    isPrivate: true,
    permission: 'admin.role.index',
    component: Role,
    menu: {
      icon: FaUserTag,
      key: 'role',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_role',
    },
  },
  {
    path: '/admin/user',
    exact: true,
    isPrivate: true,
    permission: 'admin.user.index',
    component: UserPage,
    menu: {
      icon: FaUsers,
      key: 'user',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_users',
    },
  },
  {
    path: '/admin/permission-groups',
    exact: true,
    isPrivate: true,
    permission: 'admin.group.index',
    component: PermissionGroups,
    menu: {
      icon: FaUsers,
      key: 'permission-groups',
      submenu: 'admin',
      label: 'permission-groups',
    },
  },
  {
    path: '/admin/user/:id',
    exact: true,
    isPrivate: true,
    permission: 'admin.user.index',
    component: User,
  },
  {
    path: '/admin/permission-groups/:id',
    exact: true,
    isPrivate: true,
    permission: 'admin.group.store',
    component: PermissionGroupsForm,
  },
  {
    path: '/admin/changelog',
    exact: true,
    isPrivate: true,
    permission: 'admin.changelog.index',
    component: ChangeLog,
    menu: {
      icon: FaBook,
      key: 'changelog',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_changelog',
    },
  },
  {
    path: '/admin/position',
    exact: true,
    isPrivate: true,
    permission: 'admin.position.index',
    component: Position,
    menu: {
      icon: FaSuitcase,
      key: 'position',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_position',
    },
  },
  {
    path: '/admin/phase',
    exact: true,
    isPrivate: true,
    permission: 'credit.phase.index',
    forAll: true,
    component: Phases,
    menu: {
      icon: FaColumns,
      key: 'phase',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_phase',
    },
  },
  {
    path: '/admin/teams',
    exact: true,
    isPrivate: true,
    permission: 'admin.team.index',
    component: Teams,
    menu: {
      icon: FaColumns,
      key: 'teams',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_teams',
    },
  },
  {
    path: '/admin/status',
    exact: true,
    isPrivate: true,
    permission: 'credit.request.status.index',
    component: Status,
    menu: {
      icon: FaColumns,
      key: 'status',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_status',
    },
  },
  {
    path: '/admin/team-credit-status',
    exact: true,
    isPrivate: true,
    permission: 'credit.request.teamstatus.index',
    component: TeamStatus,
    menu: {
      icon: FaColumns,
      key: 'team-credit-status',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_team_credit_status',
    },
  },
  {
    path: '/admin/credit-request-parameterization',
    exact: true,
    isPrivate: true,
    permission: 'credit.request.parametrization',
    component: CreditRequestParameterization,
    menu: {
      icon: BsGearFill,
      key: 'credit-request-parameterization',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_credit_request_parameterization',
    },
  },
  /** Integrações */
  {
    id: 'integrations',
    permission: 'admin',
    menu: {
      icon: AiFillDatabase,
      key: 'integrations',
      parent: 'integrations',
      label: 'menu.integrations',
    },
  },
  {
    path: '/integrations/dataProviders',
    exact: true,
    isPrivate: true,
    permission: 'admin.integration.import.index',
    component: DataProviders,
    menu: {
      icon: BsPersonSquare,
      key: 'dataProviders',
      submenu: 'integrations',
      label: 'menu.submenu.integrations.dataProviders',
    },
  },
  {
    path: '/integrations/rules',
    exact: true,
    isPrivate: true,
    permission: 'admin.integration.rule.index',
    component: IntegrationRules,
    menu: {
      icon: BsPersonSquare,
      key: 'rules',
      submenu: 'integrations',
      label: 'menu.submenu.integrations.rules',
    },
  },
  {
    path: '/integrations/fileUpload',
    exact: true,
    isPrivate: true,
    permission: 'admin.integration.import.index',
    component: ExposureFileUpload,
    menu: {
      icon: BsPersonSquare,
      key: 'exposurefileupload',
      submenu: 'integrations',
      label: 'menu.submenu.integrations.exposurefileupload',
    },
  },
  {
    path: '/integrations/groups',
    exact: true,
    isPrivate: true,
    permission: 'admin.integration.import.index',
    component: Groups,
    menu: {
      icon: BsPersonSquare,
      key: 'groups',
      submenu: 'integrations',
      label: 'menu.submenu.integrations.groups',
    },
  },
  /** Registros - MasterData */
  {
    id: 'register',
    permission: 'register',
    menu: {
      icon: FaBook,
      key: 'register',
      parent: 'register',
      label: 'menu.register',
    },
  },
  {
    path: '/register/harvest',
    exact: true,
    isPrivate: true,
    permission: 'register.harvest.index',
    component: Harvest,
    menu: {
      icon: FaLeaf,
      key: 'harvest',
      submenu: 'register',
      label: 'menu.submenu.register.menu_harvest',
    },
  },
  {
    path: '/register/operation',
    exact: true,
    isPrivate: true,
    permission: 'register.operation.index',
    component: Operation,
    menu: {
      icon: BsGearFill,
      key: 'operation',
      submenu: 'register',
      label: 'menu.submenu.admin.menu_operation',
    },
  },
  {
    path: '/register/port',
    exact: true,
    isPrivate: true,
    permission: 'register.baseport.index',
    component: BasePort,
    menu: {
      icon: FaShip,
      key: 'port',
      submenu: 'register',
      label: 'menu.submenu.admin.menu_port',
    },
  },
  /** Monitoramento */
  {
    id: 'monitoring',
    permission: 'monitoring',
    menu: {
      icon: FaUserTie,
      key: 'monitoring',
      parent: 'monitoring',
      label: 'menu.monitoring',
    },
  },
  {
    path: '/monitoring/farm',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.farm.index',
    component: Farm,
    menu: {
      icon: GiField,
      key: 'farm',
      submenu: 'monitoring',
      label: 'menu.submenu.register.menu_farm',
    },
  },
  {
    path: '/monitoring/cpr',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.cpr.index',
    component: Cpr,
    menu: {
      icon: FaLeaf,
      key: 'cpr',
      submenu: 'monitoring',
      label: 'menu.submenu.monitoring.menu_cpr',
    },
  },
  {
    path: '/monitoring/cpr/:cpr/registration',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.registration.index',
    component: Registration,
  },
  {
    path: '/monitoring/cpr/:cpr/registration/:registration/survey',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.survey.index',
    component: Survey,
  },
  {
    path: '/monitoring/cpr/:cpr/registration/:registration/survey/new',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.survey.store',
    component: SurveyUpdate,
  },
  {
    path: '/monitoring/cpr/:cpr/registration/:registration/survey/edit/:survey',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.survey.update',
    component: SurveyUpdate,
  },
  {
    path: '/monitoring/operator',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.operator.index',
    component: Operator,
    menu: {
      icon: FaLeaf,
      key: 'operator',
      submenu: 'monitoring',
      label: 'menu.submenu.monitoring.operator',
    },
  },
  {
    path: '/monitoring/surveyrequest',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.surveyrequest.index',
    component: SurveyRequest,
    menu: {
      icon: FaLeaf,
      key: 'surveyrequest',
      submenu: 'monitoring',
      label: 'menu.submenu.monitoring.surveyrequest',
    },
  },
  {
    path: '/monitoring/surveyrequest/new',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.surveyrequest.create',
    component: SurveyForm,
  },
  {
    path: '/monitoring/surveyrequest/edit/:surveyRequest',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.surveyrequest.create',
    component: SurveyForm,
  },
  {
    path: '/register/region',
    exact: true,
    isPrivate: true,
    permission: 'register.region.index',
    component: Region,
    menu: {
      icon: RiRoadMapFill,
      key: 'region',
      submenu: 'register',
      label: 'menu.submenu.register.menu_region',
    },
  },
  {
    path: '/register/price',
    exact: true,
    isPrivate: true,
    permission: 'register.dailyprice.index',
    component: PriceDay,
    menu: {
      icon: AiFillDollarCircle,
      key: 'price',
      submenu: 'register',
      label: 'menu.submenu.register.menu_price',
    },
  },
  {
    path: '/register/subsidiary',
    exact: true,
    isPrivate: true,
    permission: 'register.subsidiary.index',
    component: Subsidiary,
    menu: {
      icon: FaMapMarkerAlt,
      key: 'subsidiary',
      submenu: 'register',
      label: 'menu.submenu.register.menu_subsidiary',
    },
  },
  /** E-Documents */
  {
    id: 'edocuments',
    permission: 'edocument',
    menu: {
      icon: FaFolderOpen,
      key: 'edocuments',
      parent: 'edocuments',
      label: 'menu.edocuments',
    },
  },
  // {
  //   path: '/edocuments/contracts',
  //   exact: true,
  //   isPrivate: true,
  //   permission: 'edocument.contract.index',
  //   component: Contracts,
  //   menu: {
  //     icon: FaFileAlt,
  //     key: 'contracts',
  //     submenu: 'edocuments',
  //     label: 'menu.submenu.edocuments.menu_contracts',
  //   },
  // },
  {
    path: '/edocuments/template-docs',
    exact: true,
    isPrivate: true,
    permission: 'edocument.contract.index',
    component: TemplateDocs,
    menu: {
      icon: FaFileAlt,
      key: 'templatedocs',
      submenu: 'edocuments',
      label: 'menu.submenu.edocuments.menu_template_docs',
    },
  },
  {
    path: '/edocuments/contracts',
    exact: true,
    isPrivate: true,
    permission: 'contract.index',
    component: ContractList,
    menu: {
      icon: GiFiles,
      key: 'constractList',
      submenu: 'edocuments',
      label: 'menu.submenu.edocuments.contractlist',
    },
  },
  {
    path: '/edocuments/emission/:id',
    exact: true,
    isPrivate: true,
    permission: 'contract.show',
    component: Emission,
  },
  {
    path: '/edocuments/contracttypes',
    exact: true,
    isPrivate: true,
    permission: 'contract.type.index',
    component: ContractTypes,
    menu: {
      icon: FaFileInvoice,
      key: 'contracttypes',
      submenu: 'edocuments',
      label: 'menu.submenu.edocuments.contracttypes',
    },
  },
  {
    path: '/edocuments/templates',
    exact: true,
    isPrivate: true,
    permission: 'contract.template.index',
    component: Templates,
    menu: {
      icon: ImInsertTemplate,
      key: 'templates',
      submenu: 'edocuments',
      label: 'menu.submenu.edocuments.templates',
    },
  },
  // {
  //   path: '/edocuments/contracts/importations',
  //   exact: true,
  //   isPrivate: true,
  //   permission: 'admin.permission.index',
  //   component: ContractImportations,
  // },
  /** Notificações */
  {
    path: '/notifications',
    exact: true,
    isPrivate: true,
    component: Notifications,
  },

  /** Sustainability */
  {
    id: 'sustainability',
    exact: true,
    isPrivate: true,
    permission: 'sustainability',
    menu: {
      icon: FaLeaf,
      key: 'sustainability',
      parent: 'sustainability',
      label: 'menu.sustainability',
    },
  },
  {
    path: '/sustainability/request-report',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'sustainability.request.index',
    component: Sustainability,
    menu: {
      icon: FaLeaf,
      key: 'sustainability-request-report',
      submenu: 'sustainability',
      label: 'menu.submenu.sustainability.menu_sustainability_request_report',
    },
  },
  {
    path: '/sustainability/request-report/new/:creditrequest',
    exact: true,
    isPrivate: true,
    permission: 'sustainability.request.index',
    component: Sustainability,
  },
  {
    path: '/sustainability/request-report/:id',
    exact: true,
    isPrivate: true,
    permission: 'sustainability.request.index',
    component: Sustainability,
  },
  {
    path: '/sustainability/requests',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'sustainability.request.index',
    component: SustainabilityRequests,
    menu: {
      icon: FaLeaf,
      key: 'sustainability-requests',
      submenu: 'sustainability',
      label: 'menu.submenu.sustainability.menu_sustainability_requests',
    },
  },
  {
    path: '/sustainability/parameterization',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'sustainability.userrule.index',
    component: SustainabilityUserRules,
    menu: {
      icon: BsGearFill,
      key: 'sustainability_parameterization',
      submenu: 'sustainability',
      label: 'menu.submenu.sustainability.menu_sustainability_parameterization',
    },
  },
  {
    path: '/sustainability/team-status',
    exact: true,
    isPrivate: true,
    permission: 'sustainability.rule.index',
    component: SustainabilityTeamStatus,
    menu: {
      icon: FaColumns,
      key: 'sustainability-team-status',
      submenu: 'sustainability',
      label: 'menu.submenu.sustainability.menu_sustainability_team_status',
    },
  },
  /** Serasa */
  {
    id: 'serasa',
    permission: 'serasa',
    menu: {
      icon: BsGearFill,
      key: 'serasa',
      parent: 'serasa',
      label: 'menu.serasa',
    },
  },
  {
    path: '/serasa/counterparty',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'serasa.counterparty.index',
    component: SerasaCounterparty,
    menu: {
      icon: RiMoneyDollarCircleLine,
      key: 'counterparty-index',
      submenu: 'serasa',
      label: 'menu.submenu.serasa.menu_counterparty',
    },
  },
  {
    path: '/serasa/counterparty/:id',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'counterparty.index',
    component: SerasaCounterpartyEdit,
  },
  {
    path: '/serasa/parameterization',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'serasa.parameterization.index',
    component: SerasaParameterization,
    menu: {
      icon: BsGearFill,
      key: 'parameterization-index',
      submenu: 'serasa',
      label: 'menu.submenu.serasa.menu_parameterization',
    },
  },
  {
    path: '/serasa/integrations',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'serasa.import.index',
    component: SerasaIntegrations,
    menu: {
      icon: BsGearFill,
      key: 'integrations-index',
      submenu: 'serasa',
      label: 'menu.submenu.serasa.menu_integrations',
    },
  },

  /** Profile */
  {
    path: '/profile',
    exact: true,
    isPrivate: true,
    component: Profile,
  },
  /** Error */
  {
    path: '/error',
    isPrivate: false,
    component: NotFound,
  },
  // {
  //   path: '*',
  //   isPrivate: false,
  //   component: NotFound,
  // },
  // {
  //   path: '/contracts',
  //   permission: '',
  //   isPrivate: true,
  //   exact: true,
  //   component: Contract,
  //   menu: {
  //     icon: FaFileAlt,
  //     key: 'contracts',
  //     parent: 'contracts',
  //     label: 'menu.contracts',
  //   },
  // },
];

export default routes;
