import styled, { css } from 'styled-components';

import CellBorderColorProps from './types';

export const Container = styled.div<CellBorderColorProps>`
  ${({ color }) => {
    if (color) {
      return css`
        border-left: 10px solid ${color};
      `;
    }
    return css`
      border-left: 10px solid transparent;
    `;
  }}
  padding: 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-select {
    flex: 1;
    padding-right: 8px;
  }
`;
