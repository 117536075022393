import React, { forwardRef } from 'react';

import { Input as AntInput } from 'antd';
import { InputProps as AntInputProps } from 'antd/lib/input';

const Input: React.RefForwardingComponent<any, AntInputProps> = (props, ref) => {
  return <AntInput allowClear size="middle" {...props} ref={ref} />;
};

export default forwardRef(Input);
