import { PaginatedQueryParams, usePaginatedQuery } from '@/hooks/use-paginated-query';
import { UserPermissionData } from '@/types/User/UserPermissionData';

type Params = PaginatedQueryParams & {
  users: string[];
};

export const useGetUserSubdivisionsQuery = ({ ...params }: Params) => {
  return usePaginatedQuery<UserPermissionData>({
    queryKey: [
      'credit-request/sub-division',
      params.users,
      params.current,
      params.pageSize,
      params.sort,
      params.search,
    ],
    url: `credit-request/sub-division`,
    params: {
      ...params,
      params: {
        users: params.users,
      },
    },
  });
};
