import React, { useState, useEffect } from 'react';

import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import MultiCheckbox from './multi-checkbox';

function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(...inputs));
}

interface TreeNode {
  id: string;
  label: React.ReactNode;
  readable: string;
  children?: TreeNode[];
  /** Custom class names */
  className?: string;
  labelClassName?: string;
  expandButtonClassName?: string;
  checkboxClassName?: string;
}

interface TreeProps {
  data: TreeNode[];
  name?: string;
  renderNode?: (nodeProps: {
    node: TreeNode;
    checked: boolean;
    indeterminate: boolean;
    expanded: boolean;
    toggleCheck: () => void;
    toggleExpand: () => void;
  }) => React.ReactNode;
  value?: string[];
  expandedItems?: string[];
  onChange?: any;
  onExpandChange?: (expandedItems: string[]) => void;
  className?: string;
  search?: string;
}

const Tree: React.FC<TreeProps> = ({
  data,
  renderNode,
  value: controlledCheckedItems,
  expandedItems: controlledExpandedItems,
  onChange,
  onExpandChange,
  className,
  name,
  search,
}) => {
  const [checkedItems, setCheckedItems] = useState<string[]>(() => controlledCheckedItems || []);
  const [expandedItems, setExpandedItems] = useState<string[]>(() => controlledExpandedItems || []);

  // Filter the nodes, only applying the search filter to the leaf nodes
  // if the node hasn't any children that match the search, the node is not rendered
  const filteredData = data
    .map(node => {
      const filterNode = (n: TreeNode): TreeNode | null => {
        if (n.children) {
          const filteredChildren = n.children.map(filterNode).filter((c): c is TreeNode => c !== null);
          if (filteredChildren.length > 0) {
            return { ...n, children: filteredChildren };
          }
        }
        if (!search || n.readable.toLowerCase().includes(search.toLowerCase())) {
          return n;
        }
        return null;
      };
      return filterNode(node);
    })
    .filter((node): node is TreeNode => node !== null);

  // Sync with controlled checked items
  useEffect(() => {
    if (controlledCheckedItems) {
      setCheckedItems([...controlledCheckedItems]);
    }
  }, [controlledCheckedItems]);

  // Sync with controlled expanded items
  useEffect(() => {
    if (controlledExpandedItems) {
      setExpandedItems([...controlledExpandedItems]);
    }
  }, [controlledExpandedItems]);

  const handleCheckChange = (node: TreeNode, checked: boolean) => {
    const newCheckedItems = [...checkedItems];

    const updateLeafNodes = (nodes: TreeNode[]) => {
      nodes.forEach(childNode => {
        if (childNode.children) {
          updateLeafNodes(childNode.children);
        } else {
          if (checked) {
            if (!newCheckedItems.includes(childNode.id)) {
              newCheckedItems.push(childNode.id);
            }
          } else {
            const index = newCheckedItems.indexOf(childNode.id);
            if (index > -1) {
              newCheckedItems.splice(index, 1);
            }
          }
        }
      });
    };

    if (node.children) {
      // Node is a parent node
      updateLeafNodes([node]);
    } else {
      // Node is a leaf node
      if (checked) {
        if (!newCheckedItems.includes(node.id)) {
          newCheckedItems.push(node.id);
        }
      } else {
        const index = newCheckedItems.indexOf(node.id);
        if (index > -1) {
          newCheckedItems.splice(index, 1);
        }
      }
    }

    setCheckedItems(newCheckedItems);
    if (onChange) {
      onChange?.({
        target: {
          value: newCheckedItems,
          name: name ?? '',
        },
      });
    }
  };

  const handleExpandChange = (nodeId: string, expanded: boolean) => {
    const newExpandedItems = [...expandedItems];
    if (expanded) {
      if (!newExpandedItems.includes(nodeId)) {
        newExpandedItems.push(nodeId);
      }
    } else {
      const index = newExpandedItems.indexOf(nodeId);
      if (index > -1) {
        newExpandedItems.splice(index, 1);
      }
    }
    setExpandedItems(newExpandedItems);
    if (onExpandChange) {
      onExpandChange(newExpandedItems);
    }
  };

  const isNodeChecked = (node: TreeNode): [boolean, boolean] => {
    if (node.children) {
      const childStatuses = node.children.map(child => isNodeChecked(child));
      const allChecked = childStatuses.every(([checked]) => checked);
      const someChecked = childStatuses.some(([checked, indeterminate]) => checked || indeterminate);
      const indeterminate = someChecked && !allChecked;
      return [allChecked, indeterminate];
    }
    const isChecked = checkedItems.includes(node.id);
    return [isChecked, false];
  };

  const renderTree = (nodes: TreeNode[]) => {
    return nodes.map(node => {
      const expanded = expandedItems.includes(node.id);
      const [checked, indeterminate] = isNodeChecked(node);

      const toggleCheck = () => {
        handleCheckChange(node, !checked);
      };

      const toggleExpand = () => {
        handleExpandChange(node.id, !expanded);
      };

      return (
        <div key={node.id} className={node.className}>
          {(renderNode || defaultRenderNode)({
            node,
            checked,
            indeterminate,
            expanded,
            toggleCheck,
            toggleExpand,
          })}
          {node.children && expanded && <div className="ml-4">{renderTree(node.children)}</div>}
        </div>
      );
    });
  };

  // Default renderNode function
  const defaultRenderNode = ({
    node,
    checked,
    indeterminate,
    expanded,
    toggleCheck,
    toggleExpand,
  }: {
    node: TreeNode;
    checked: boolean;
    indeterminate: boolean;
    expanded: boolean;
    toggleCheck: () => void;
    toggleExpand: () => void;
  }) => (
    <div className={cn('flex items-center', node.labelClassName)}>
      {node.children ? (
        <button
          type="button"
          onClick={toggleExpand}
          className={cn('mr-2 text-gray-600 hover:text-gray-800', node.expandButtonClassName)}
        >
          {expanded ? '▾' : '▸'}
        </button>
      ) : (
        <span className="mr-4" /> // Placeholder for alignment
      )}
      <MultiCheckbox
        checked={checked}
        indeterminate={indeterminate}
        onChange={toggleCheck}
        className={cn('mr-2', node.checkboxClassName)}
      />
      <span>{node.label}</span>
    </div>
  );

  return <div className={className}>{renderTree(filteredData)}</div>;
};

export default Tree;
