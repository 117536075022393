import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalStatus } from '../../components';
import api from '../../services/api/admin';
import { handleRequest } from '../../services/api/api';

export const useAuthentication = () => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);

  const clear2FA = async (id: string) => {
    const response = await handleRequest(() => api.users.TwoFAClear(id), setIsFetching);
    if (response)
      ModalStatus({
        type: 'success',
        title: t('modal.success'),
        subTitle: '',
      });
  };

  return { clear2FA, isFetching };
};
