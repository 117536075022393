import React from 'react';
import { Routes as Switch, Route as ReactDOMRoute } from 'react-router-dom';

import Route from './Route';
import routes from './routes';

const Routes: React.FC = () => {
  return (
    <Switch>
      {routes.map(route =>
        route.component ? (
          <ReactDOMRoute
            key={route.path}
            path={route.path}
            element={
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
                isPrivate={route.isPrivate}
                ifAuthRedirect={route.ifAuthRedirect}
              />
            }
          />
        ) : null,
      )}
    </Switch>
  );
};

export default Routes;
