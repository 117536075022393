import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-break: break-word;

  > .back-icon {
    color: ${props => props.theme.colors.text};
    display: flex;
    align-items: center;
    margin-right: 8px;
    padding: 5px;

    > a {
      color: ${props => props.theme.colors.text};

      > svg {
        font-size: 20px;
      }

      &:hover {
        color: ${props => props.theme.colors.text};
      }
    }
  }

  > .back-text {
    color: ${props => props.theme.colors.text};
    font-family: ${props => props.theme.fonts.primary};
    font-size: 20px;
    font-weight: 600;
  }
`;
