import { ResponseSingle } from '../../@types/data/ResponseData';
import SerasaParameterizationData from '../../@types/data/SerasaParameterizationData';
import SustainabilityData from '../../types/Sustainability/SustainabilityData';
import api from './api';

export default {
  connection: async (abortController?: AbortController): Promise<any> => {
    return api.get(`serasa/status`, { signal: abortController?.signal });
  },
  counterparties: {
    get: async (params = {}): Promise<any> => {
      return api.get(`serasa/counterparty`, params);
    },
    show: async (id: string, params = {}): Promise<any> => {
      return api.get(`serasa/counterparty/${id}`, params);
    },
    create: async (values: any, params = {}): Promise<any> => {
      return api.post(`serasa/counterparty`, values, params);
    },
    status: async (params = {}): Promise<any> => {
      return api.get(`serasa/counterparty/status`, params);
    },
    store: async (data: unknown, params = {}): Promise<ResponseSingle<SustainabilityData>> => {
      return api.post('sustainability/report', data, params);
    },
    update: async (id: string, data: unknown, params = {}): Promise<ResponseSingle<SustainabilityData>> => {
      return api.put(`serasa/counterparty/${id}`, data, params);
    },
    destroy: async (id: string, params = {}): Promise<ResponseSingle<any>> => {
      return api.delete(`serasa/counterparty/${id}`, params);
    },
    generateExport: async (params: object = null): Promise<any> => {
      return api.post(`serasa/counterparty/report/all`, params);
    },
    listReport: async (params: object = null): Promise<any> => {
      return api.get(`serasa/counterparty/report/all`, params);
    },
    generateExportRestrictionRow: async (params: object = null): Promise<any> => {
      return api.post(`serasa/counterparty/report/restriction-list/all`, params);
    },
    listReportRestrictionRow: async (params: object = null): Promise<any> => {
      return api.get(`serasa/counterparty/report/restriction-list/all`, params);
    },
    generateExportRestrictionColumn: async (params: object = null): Promise<any> => {
      return api.post(`serasa/counterparty/report/restriction-list/by-type`, params);
    },
    listReportRestrictionColumn: async (params: object = null): Promise<any> => {
      return api.get(`serasa/counterparty/report/restriction-list/by-type`, params);
    },
  },
  parameterization: {
    show: async (params = {}): Promise<any> => {
      return api.get(`serasa/config`, params);
    },
    update: async (id: string, data: unknown, params = {}): Promise<ResponseSingle<SerasaParameterizationData>> => {
      return api.put(`serasa/config/${id}`, data, params);
    },
  },
  integrations: {
    show: async (params = {}): Promise<any> => {
      return api.get(`serasa/import`, params);
    },
  },
};
