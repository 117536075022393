import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  overflow: hidden;
  z-index: 2;
  position: relative;
  margin: 0 auto;

  p {
    text-align: center;
    width: 70%;
  }

  button {
    width: 80%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
`;
