import { UserConfig } from '../../types/User/UserData';
import api from './api';

export default {
  config: {
    update: async (id: string, config: UserConfig, params = {}): Promise<UserConfig> => {
      return api.post(`admin/user/${id}/config`, { config, locale: config.app.language, _method: 'PATCH' }, params);
    },
  },
};
