import api from '@/services/api/api';
import { useMutation } from '@tanstack/react-query';

export const useUpdateNotificationConfig = () => {
  return useMutation({
    mutationKey: ['updateNotificationConfig'],
    mutationFn: async ({
      userId,
      notificationsIds,
      shouldSendEmail,
      shouldSendTeamsNotification,
    }: {
      userId: string;
      notificationsIds: any;
      shouldSendEmail: boolean;
      shouldSendTeamsNotification: boolean;
    }) => {
      const response = await api.put(`admin/user/${userId}/message`, {
        messages: notificationsIds,
        send_email: shouldSendEmail ? '1' : '0',
        send_teams_notification: shouldSendTeamsNotification ? '1' : '0',
      });

      return response.data;
    },
  });
};
