import React from 'react';

import UserData from '@/types/User/UserData';

import { useGetUserOperationsQuery } from '../../api/use-get-user-operations.query';
import { UserPermissionsTable } from './user-permissions.table';

type Props = {
  user: UserData;
};

export const UserPermissionsOperationsTable = ({ user }: Props) => {
  return <UserPermissionsTable query={useGetUserOperationsQuery} user={user} />;
};
