import React from 'react';

export default function renderComponent(
  conditional: boolean,
  childrenIf: any,
  childrenElse?: any,
): React.ReactNode | null {
  if (childrenElse) return conditional ? childrenIf : childrenElse;
  return conditional ? childrenIf : null;
}
