import { Badge } from 'antd';
import styled from 'styled-components';

export const BadgeContainer = styled(Badge)`
  .ant-badge-count {
    background: #e03400;
    color: #ffffff;
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-weight: 400;
  }

  .ant-badge-count-sm {
    font-size: 10px;
  }
`;
