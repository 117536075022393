import styled from 'styled-components';

import { ListMenuProps } from './types';

const maxWidthMd = '991px';
const minWidthMd = '992px';

export const SListMenu = styled.ul<ListMenuProps>`
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #ffffff;

  .list-menu-item-mobile {
    min-height: 40px;
    padding: 10px;
  }

  @media (max-width: ${maxWidthMd}) {
    position: fixed;
    left: 0;
    top: 0;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    z-index: 1000;
    flex-direction: column;
    height: 100vh;
    width: 100vw;

    display: ${({ type }) => (type === 'desktop' ? 'none' : 'block')};
    overflow: ${({ show }) => (show ? 'hidden' : 'scroll')};
    transform: ${({ show }) => (show ? 'translate3d(0vw, 0, 0)' : 'translate3d(-100vw, 0, 0)')};
  }

  @media (min-width: ${minWidthMd}) {
    display: ${({ type }) => (type === 'desktop' ? 'flex' : 'none')};
  }
`;
