import { AxiosResponse } from 'axios';

import CropConditionData from '../../@types/data/CropConditionData';
import { HighRiskSurveyData } from '../../@types/data/HighRiskSurveyData';
import RegistrationData from '../../@types/data/RegistrationData';
import { ResponseList, ResponseListData } from '../../@types/data/ResponseData';
import DTOCprModel from '../../@types/dtos/monitoring/DTOCprModel';
import DTOSurvey from '../../@types/dtos/monitoring/DTOSurvey';
import DTOSurveyType from '../../@types/dtos/monitoring/DTOSurveyType';
import DTOOperator from '../../@types/dtos/monitoring/SurveyRequest/DTOOperator';
import DTOSurveyRequest from '../../@types/dtos/monitoring/SurveyRequest/DTOSurveyRequest';
import { formatValuesToFormData } from '../form';
import api from './api';

export default {
  cpr: {
    get: async (params: object = null): Promise<ResponseListData<DTOCprModel>> => {
      return api.get(`monitoring/cpr`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/cpr/all`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`monitoring/cpr/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`monitoring/cpr`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/cpr/${id}`, params);
    },
  },
  registration: {
    getById: async (id: string, params: object = null): Promise<any> => {
      return api.get(`monitoring/registration/${id}`, params);
    },
    get: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/registration`, params);
    },
    all: async (params: object = null): Promise<AxiosResponse<RegistrationData[]>> => {
      return api.get(`monitoring/registration/all`, params);
    },
    update: async (id: string, data: object = {}, params: object = {}): Promise<any> => {
      return api.post(`monitoring/registration/${id}`, data, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`monitoring/registration`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/registration/${id}`, params);
    },
    listReport: async (registration: string, params: object = null): Promise<any> => {
      return api.get(`monitoring/report/${registration}`, params);
    },
    generateReport: async (registration: string, params: object = null): Promise<any> => {
      return api.post(`monitoring/report/${registration}`, params);
    },
  },
  situation: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/registration/situation`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/registration/situation/all`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`monitoring/registration/situation/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`monitoring/registration/situation`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/registration/situation/${id}`, params);
    },
  },
  owner: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/owner`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/owner/all`, params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.put(`monitoring/owner/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`monitoring/owner`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/owner/${id}`, params);
    },
  },
  farm: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/farm`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/farm/all`, params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.put(`monitoring/farm/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`monitoring/farm`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/farm/${id}`, params);
    },
    destroyContact: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/farm/destroycontact/${id}`, params);
    },
  },
  survey: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/survey`, params);
    },
    find: async (id: string, params: object = null): Promise<any> => {
      return api.get(`/monitoring/survey/${id}`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/survey/all`, params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.put(`monitoring/survey/${id}`, params);
    },
    store: async (params: object = {}): Promise<any> => {
      return api.post(`monitoring/survey`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/survey/${id}`, params);
    },
    highRisk: async (params: object = null): Promise<ResponseList<HighRiskSurveyData>> => {
      return api.get(`monitoring/survey/warning`, params);
    },
    approve: async (id: string, params: object = null): Promise<ResponseList<DTOSurvey>> => {
      return api.patch(`monitoring/survey/${id}/approve`, params);
    },
    stage: {
      get: async (params = {}): Promise<ResponseListData<any>> => {
        return api.get('monitoring/survey/stage', params);
      },
    },
    type: {
      get: async (params: object = null): Promise<DTOSurveyType> => {
        return api.get(`monitoring/survey/type`, params);
      },
    },
  },
  surveyrequest: {
    getById: async (id: string, params: object = null): Promise<AxiosResponse<DTOSurveyRequest>> => {
      return api.get(`monitoring/survey-request/${id}`, params);
    },
    getAll: async (params: object = null): Promise<ResponseList<DTOSurveyRequest>> => {
      return api.get(`monitoring/survey-request`, params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi =
          params instanceof FormData
            ? params
            : formatValuesToFormData({ ...params, _method: 'PUT' }, data, ['documents[]']);
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.post(`monitoring/survey-request/${id}`, paramsApi);
    },
    store: async (payload: object = null): Promise<any> => {
      return api.post(`monitoring/survey-request`, payload);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/survey-request/${id}`, params);
    },
  },
  cropCondition: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`monitoring/crop-condition`, params);
    },
    all: async (params: object = null): Promise<ResponseListData<CropConditionData>> => {
      return api.get(`monitoring/crop-condition/all`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`monitoring/crop-condition/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`monitoring/crop-condition`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/crop-condition/${id}`, params);
    },
  },
  operator: {
    get: async (params: object = null): Promise<ResponseListData<DTOOperator>> => {
      return api.get(`monitoring/operator`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`monitoring/operator/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`monitoring/operator`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`monitoring/operator/${id}`, params);
    },
  },
  typeSurvey: {
    all: async (params: object = null): Promise<ResponseListData<DTOSurveyType>> => {
      return api.get(`monitoring/survey/type/all`, params);
    },
  },
};
