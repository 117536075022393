import React from 'react';
import { Link } from 'react-router-dom';

import { SBreadcrumb } from './styles';
import { CustomBreadcrumbItem, CustomBreadcrumbProps } from './types';

const Breadcrumb: React.FC<CustomBreadcrumbProps> = ({ items }) => {
  return (
    <SBreadcrumb>
      {items.map((item: CustomBreadcrumbItem) => {
        if (item.to) {
          return (
            <SBreadcrumb.Item key={item.title}>
              <Link to={item.to}>{item.title}</Link>
            </SBreadcrumb.Item>
          );
        }
        return <SBreadcrumb.Item key={item.title}>{item.title}</SBreadcrumb.Item>;
      })}
    </SBreadcrumb>
  );
};

export default Breadcrumb;
