import React, { createContext, useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import DTOErrorReponse from '../@types/dtos/DTOErrorReponse';
import PageContextState, {
  ApiProps,
  DeselectOptionParams,
  ItemResult,
  ItemsType,
  SelectOptionParams,
  UpdateOptionsParams,
} from '../@types/hooks/page';
import { ModalStatus } from '../components';
import { ModalStatusProps } from '../components/ModalStatus/types';
import api from '../services/api/api';

const PageContext: React.Context<PageContextState> = createContext({} as PageContextState);

const PageProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const [lastFetchId, setLastFetchId] = useState(0);
  const [fetchDataTimeoutId, setFetchDataTimeoutId] = useState<number>();

  const [apiFetching, setApiFetching] = useState(false);
  const [search, setSearch] = useState('');

  /**
   * Create debounce to call an api with method GET
   * @param config: ApiProps
   * @returns ItemsTypes[]
   */
  async function debounceApi({ minSearchLength = 3, ...config }: ApiProps) {
    const { apiUrl, fieldName, value, callback, paramsFilter, mappingItems } = config;
    let { apiPerPage, delay } = config;

    // Se valor vazio não chama a api
    if (!value && !config.allowEmpty) return;
    // Se valor menor que 3 caracteres não chama a api
    if (value.length < minSearchLength) return;

    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    // Se não for a requisição atual não chama a api
    if (fetchId !== lastFetchId) return;

    // Set loading
    setApiFetching(true);
    // Set search with received value
    setSearch(value);
    // If not setted, set default values to items per page and delay to call api
    if (!apiPerPage) apiPerPage = 100;
    if (!delay) delay = 800;

    // Clear id of last setTimeout
    clearTimeout(fetchDataTimeoutId);
    // Create new id of setTimeout
    setFetchDataTimeoutId(
      // Create in memory a function to call api after delay
      window.setTimeout(async () => {
        // Call api with params and search by custom field
        let paramsApi = {
          per_page: apiPerPage,
          [fieldName]: search,
        };
        if (paramsFilter !== null) {
          paramsApi = {
            ...paramsApi,
            ...paramsFilter,
          };
        }
        const response = await api.get(apiUrl, {
          params: paramsApi,
        });

        const { data: responseData } = response.data;
        // Map item from api response for type ItemsTypes to be used in
        // select components
        let resultItems: ItemsType[] = [];
        if (mappingItems) {
          resultItems = await responseData.map(mappingItems);
        } else {
          resultItems = await responseData.map((item: ItemResult) => ({
            label: item[fieldName],
            value: item.id,
          }));
        }

        // Execute the callback with items in format ItemsTypes
        callback(resultItems);
        // Clean the search;
        setSearch('');
        // Clean the loader
        setApiFetching(false);
      }, delay),
    );
  }

  const getDistinctOptions = useCallback((params: UpdateOptionsParams) => {
    const { items, stateSelectedOptions } = params;
    // Does distinct between items and stateSelectedOptions
    const distinctItems = [...items, ...stateSelectedOptions].filter(
      (itemValue, index, self) => self.indexOf(itemValue) === index,
    );
    // Set setStateOptions with distinct
    return distinctItems;
  }, []);

  const onSelectOption = useCallback((params: SelectOptionParams) => {
    const { value, stateOptions, stateSelectedOptions, setStateSelectedOptions, form, fieldname } = params;
    let { multipleValues } = params;
    multipleValues = multipleValues || false;

    const findItem = stateOptions.find(item => item.value === value.toString());
    if (findItem) {
      setStateSelectedOptions(
        [...stateSelectedOptions, findItem].filter((itemValue, index, self) => self.indexOf(itemValue) === index),
      );

      const values = [...stateSelectedOptions, findItem].map(item => item.value);
      form.setFieldsValue({
        [fieldname]: multipleValues === false ? values[0] : values,
      });
    }
  }, []);

  const onDeselectOption = useCallback((params: DeselectOptionParams) => {
    const { value, stateSelectedOptions, setStateSelectedOptions, form, fieldname } = params;
    let { multipleValues } = params;
    multipleValues = multipleValues || false;

    const findIndexItem = stateSelectedOptions.findIndex(item => item.value === value.toString());
    if (findIndexItem > -1) {
      const filteredItems = stateSelectedOptions.filter(item => item.value !== value.toString());
      setStateSelectedOptions(filteredItems);
      const values = filteredItems.map(item => item.value);
      form.setFieldsValue({
        [fieldname]: multipleValues === false ? values[0] : values,
      });
    }
  }, []);

  function alertStatus(
    messages: any,
    status: 'success' | 'info' | 'warning' | 'errorLogin' | 'error' = 'success',
    iconName: 'confirm' | 'success' | 'error' | 'errorLogin' | 'warning' | 'info' | 'edit' | 'delete' | '' = '',
    callback: () => void,
  ) {
    let title: any = status ? t(`modal.${status}`) : t('modal.success');
    let errors = [];

    if (status === 'error' || status === 'errorLogin') {
      console.error(messages);
      const errorResponse: DTOErrorReponse = messages as DTOErrorReponse;
      const { message } = errorResponse;

      if (message) {
        title = message;
      }

      if (errorResponse.errorFields !== undefined) {
        errors = errorResponse.errorFields?.map((item: any) => item.errors);
      }

      if (errorResponse.response !== undefined) {
        if (typeof messages.response.data === 'string') {
          title = messages.response.data;
        }
        if (messages.response.data?.message && typeof messages.response.data?.message === 'string') {
          title = errorResponse.response.data.message;
        }
        errors = message;

        if (errorResponse.response.data.errors !== undefined) {
          errors = errorResponse.response.data.errors;
        }
      }
    }

    const subTitle = status === 'error' ? { subTitle: errors } : status === 'errorLogin' ? {} : { subTitle: messages };
    let configs: ModalStatusProps = {
      type: status ?? 'success',
      title,
      ...subTitle,
    };

    if (iconName) {
      configs = {
        ...configs,
        iconName,
      };
    }

    if (callback !== null && callback !== undefined) {
      configs = {
        ...configs,
        onOk: () => callback(),
      };
    }

    return ModalStatus(configs);
  }

  return (
    <PageContext.Provider
      value={{
        debounceApi,
        apiFetching,
        getDistinctOptions,
        onSelectOption,
        onDeselectOption,
        alertStatus,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

function usePage(): PageContextState {
  return useContext(PageContext);
}

export { PageProvider, usePage };

export type { ItemsType };
