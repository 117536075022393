import styled from 'styled-components';

export const SIconContainer = styled.div`
  color: ${({ theme: { colors } }) => colors.secondary};
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
  padding-bottom: 4px;
  padding-top: 4px;

  &.icon-in-input-suffix {
    margin-left: 4px;
    margin-right: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary};
    cursor: pointer;
  }

  .visibility_hidden {
    visibility: hidden;
  }
`;

export const SIconButton = styled.button`
  display: block;
  background: transparent;
  border: none;
  border-radius: 0;
  font-size: 18px;
  line-height: 1;

  &:hover {
    cursor: pointer;
  }
`;

export const SIcon = styled.div`
  display: block;
  background: transparent;
  border: none;
  border-radius: 0;
  font-size: 18px;
  line-height: 1;

  &:hover {
    cursor: pointer;
  }
`;

export const FakeIcon = styled.div`
  width: 18px;
  height: 21px;
`;
