import { useLocation } from 'react-router-dom';

// eslint-disable-next-line consistent-return
const useCurrentLocation = (isPrivate?: boolean, user?: object | undefined) => {
  const { pathname, search } = useLocation();

  const heavyCheck = () => {
    return isPrivate && pathname !== '/login' && pathname !== '/' && pathname !== undefined;
  };

  const getPathFromSessionStorage = () => {
    return sessionStorage.getItem('currentLocation');
  };

  const clearPathFromSessionStorage = () => {
    sessionStorage.removeItem('currentLocation');
  };

  if (user === undefined && getPathFromSessionStorage() !== null) {
    const canSalveToSessionStorage = heavyCheck();

    if (canSalveToSessionStorage) {
      sessionStorage.setItem('currentLocation', pathname + search);
    }
  }

  if (getPathFromSessionStorage() === null && pathname === '/login') {
    sessionStorage.removeItem('currentLocation');
  }

  return [getPathFromSessionStorage, clearPathFromSessionStorage] as const;
};

export default useCurrentLocation;
