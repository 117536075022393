import { useState } from 'react';

import moment from 'moment';

import api, { handleRequest } from '../../services/api/api';

export const usePtax = () => {
  const [isFetching, setIsFetching] = useState(false);

  const getLastPtax = async () => {
    const response = await handleRequest(() => api.get(`/admin/ptax/${moment().format('YYYY-MM-DD')}`), setIsFetching);

    return response;
  };

  const getPtax = async (date: string) => {
    const response = await handleRequest(() => api.get(`/admin/ptax/${date}`), setIsFetching);

    return response;
  };

  return { getLastPtax, isFetching, getPtax };
};
