import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLock } from 'react-icons/fa';

import { useAbility } from '@/hooks/ability';

import { Input } from './ui/input';

type Props = {
  children: React.ReactNode;
  permission?: string | string[];
  needAllPermissions?: boolean;
};

export const FieldWithoutPermission = () => {
  const { t } = useTranslation();

  return (
    <div className="relative overflow-hidden rounded-md bg-gray-400/20">
      <Input className="opacity-0" disabled />

      <span className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center gap-2 whitespace-nowrap bg-gray-200 text-primary">
        <FaLock />
        {t('noPermission')}
      </span>
    </div>
  );
};

export const PermissionedField = forwardRef(({ permission, children, needAllPermissions, ...props }: Props, ref) => {
  const ability = useAbility();

  const hasPermission = Array.isArray(permission)
    ? needAllPermissions
      ? permission.every(p => ability.can(p, ''))
      : permission.some(p => ability.can(p, ''))
    : ability.can(permission, '');

  if (!permission || hasPermission) {
    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...props, ...child.props, ref } as any);
      }
      return child;
    });
    return <>{childrenWithProps}</>;
  }

  return <FieldWithoutPermission />;
});
