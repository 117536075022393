import PasswordGenerator from 'generate-password-browser';

export const generatePassword = () =>
  PasswordGenerator.generate({
    length: 10,
    numbers: true,
    symbols: true,
    lowercase: true,
    uppercase: true,
    strict: true,
    excludeSimilarCharacters: true,
  });
