import { useTranslation } from 'react-i18next';

export const useFormPasswordValidation = () => {
  const { t } = useTranslation();

  return {
    lowerCase: (value: string) => {
      if (!value.match(/(?=.*[a-z])/)) {
        return t('user.form.password.validator.lower');
      }
      return true;
    },
    upperCase: (value: string) => {
      if (!value.match(/(?=.*[A-Z])/)) {
        return t('user.form.password.validator.upper');
      }
      return true;
    },
    numeric: (value: string) => {
      if (!value.match(/(?=.*[0-9])/)) {
        return t('user.form.password.validator.numeric');
      }
      return true;
    },
    especialChar: (value: string) => {
      if (!value.match(/(?=.*[-+_!@#$%^&*., ?^()=§{}[\]ªº¨¢'"~^|<>:;`´])/)) {
        return t('user.form.password.validator.especial_char');
      }
      return true;
    },
    min: (value: string) => {
      if (value.length < 8) {
        return t('user.form.password.validator.min');
      }
      return true;
    },
  };
};
