import React from 'react';

import { ShouldRenderProps } from './types';

export const ShouldRender = ({ children, condition, fallback }: ShouldRenderProps) => {
  if (condition) return <>{children}</>;

  if (!fallback) return null;

  return <>{fallback}</>;
};
