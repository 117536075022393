import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PermissionedField } from '@/components/PermissionedField';
import { useAlertDialog } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { ComboBoxInfinite } from '@/components/ui/combo.infinite';
import { Form } from '@/components/ui/form';
import { FormGrid } from '@/components/ui/form-grid';
import { FormItem } from '@/components/ui/form-item';
import Loading from '@/components/ui/loading';
import { PageTitle } from '@/components/ui/page-title';
import { useAuth } from '@/hooks/auth';
import { usePage } from '@/hooks/page';
import { usePagination } from '@/hooks/usePagination';
import UserData from '@/types/User/UserData';
import { UserFormData } from '@/types/User/UserFormData';
import { Converter } from '@/utils/Converter';
import { useQueryClient } from '@tanstack/react-query';

import { useGetPermissionGroupsOptionsQuery } from '../../permission-groups/api/use-get-permission-groups.query';
import { useUpdateUserMutation } from '../api/use-update-user.mutation';

type Props = {
  user: UserData;
};

export const UserPermissionsForm = ({ user }: Props) => {
  const { t } = useTranslation();
  const { alert } = useAlertDialog();
  const { updateUserData } = useAuth();
  const { alertStatus } = usePage();
  const { dispatchPagination, paginationConfig } = usePagination();
  const queryClient = useQueryClient();

  const form = useForm({
    defaultValues: {
      groups: [],
    },
  });
  const handleSuccess = (messageSuccess = '') =>
    alert({
      type: 'success',
      title: t('modal.success'),
      description: messageSuccess,
    });

  const updateUserMutation = useUpdateUserMutation();
  const getPermissionGroupsOptions = useGetPermissionGroupsOptionsQuery({
    ...paginationConfig,
    dispatchPagination,
  });

  const handleEdit = async (values: Pick<UserFormData, 'groups'>) => {
    await updateUserMutation.mutateAsync(
      {
        id: user.id,
        ...(values as UserFormData),
      },
      {
        onSuccess: (response: UserData) => {
          handleSuccess(t('user-updated-with-success'));

          queryClient.refetchQueries({ queryKey: ['credit-request/phase'] });
          queryClient.refetchQueries({ queryKey: ['credit-request/operation'] });
          queryClient.refetchQueries({ queryKey: ['credit-request/status'] });
          queryClient.refetchQueries({ queryKey: ['credit-request/team'] });
          queryClient.refetchQueries({ queryKey: ['credit-request/subdivision'] });

          if (response)
            form.reset({
              groups: response.groups.map(r => r.id),
            });
          updateUserData();
        },
        onError: err => {
          alertStatus(err, 'error');
        },
      },
    );
  };

  useEffect(() => {
    if (user) {
      form.reset({
        groups: user.groups.map(r => r.id),
      });
    }
  }, [user]);

  return (
    <Form form={form} className="bg-white p-4" onSubmit={form.handleSubmit(handleEdit)}>
      <Loading isLoading={false}>
        <PageTitle>{t('change-permission-groups')}</PageTitle>

        <FormGrid>
          <FormItem name="groups" label={t('groups')} rules={{ required: true }}>
            <PermissionedField permission="admin.group.index">
              <ComboBoxInfinite
                initialOptions={Converter.selectOptionArray(user?.groups ?? [])}
                infiniteQuery={getPermissionGroupsOptions}
                mode="multi"
                onSearch={value => dispatchPagination({ type: 'SET_SEARCH', payload: value })}
                selectAll
              />
            </PermissionedField>
          </FormItem>
        </FormGrid>

        <div className="mt-6 flex w-full items-center justify-end gap-2">
          <Button type="submit" isLoading={updateUserMutation.isPending} disabled={updateUserMutation.isPending}>
            {t('pages.admin.user.buttonEdit')}
          </Button>
        </div>
      </Loading>
    </Form>
  );
};
