import { Button } from 'antd';
import { shade } from 'polished';
import styled, { css } from 'styled-components';

import { ButtonCustomProps, ButtonDropdownCustomProps } from './types';

const variations = {
  primary: css`
    background: ${({ theme: { colors } }) => colors.buttons.primary.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.primary.border};
    color: ${({ theme: { colors } }) => colors.buttons.primary.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.primary.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.primary.border};
      color: ${({ theme: { colors } }) => colors.buttons.primary.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.background)};
      border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.border)};
      color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.label)};
    }
  `,
  secondary: css`
    background: ${({ theme: { colors } }) => colors.buttons.secondary.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.secondary.border};
    color: ${({ theme: { colors } }) => colors.buttons.secondary.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.secondary.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.secondary.border};
      color: ${({ theme: { colors } }) => colors.buttons.secondary.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.secondary.background)};
      border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.secondary.border)};
      color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.secondary.label)};
    }
  `,
  success: css`
    background: ${({ theme: { colors } }) => colors.buttons.success.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.success.border};
    color: ${({ theme: { colors } }) => colors.buttons.success.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.success.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.success.border};
      color: ${({ theme: { colors } }) => colors.buttons.success.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.success.background)};
      border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.success.border)};
      color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.success.label)};
    }
  `,
  danger: css`
    background: ${({ theme: { colors } }) => colors.buttons.danger.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.danger.border};
    color: ${({ theme: { colors } }) => colors.buttons.danger.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.danger.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.danger.border};
      color: ${({ theme: { colors } }) => colors.buttons.danger.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.danger.background)};
      border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.danger.border)};
      color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.danger.label)};
    }
  `,
  info: css`
    background: ${({ theme: { colors } }) => colors.buttons.info.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.info.border};
    color: ${({ theme: { colors } }) => colors.buttons.info.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.info.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.info.border};
      color: ${({ theme: { colors } }) => colors.buttons.info.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.info.background)};
      border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.info.border)};
      color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.info.label)};
    }
  `,
  warning: css`
    background: ${({ theme: { colors } }) => colors.buttons.warning.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.warning.border};
    color: ${({ theme: { colors } }) => colors.buttons.warning.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.warning.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.warning.border};
      color: ${({ theme: { colors } }) => colors.buttons.warning.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.warning.background)};
      border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.warning.border)};
      color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.warning.label)};
    }
  `,
  primaryOutline: css`
    background: ${({ theme: { colors } }) => colors.buttons.primaryOutline.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.primaryOutline.border};
    color: ${({ theme: { colors } }) => colors.buttons.primaryOutline.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.primaryOutline.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.primaryOutline.border};
      color: ${({ theme: { colors } }) => colors.buttons.primaryOutline.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.buttons.primaryOutline.border};
      border-color: ${({ theme: { colors } }) => colors.buttons.primaryOutline.border};
      color: #ffffff;
    }
  `,
  secondaryOutline: css`
    background: ${({ theme: { colors } }) => colors.buttons.secondaryOutline.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.secondaryOutline.border};
    color: ${({ theme: { colors } }) => colors.buttons.secondaryOutline.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => shade(0.3, colors.buttons.secondaryOutline.background)};
      border-color: ${({ theme: { colors } }) => shade(0.3, colors.buttons.secondaryOutline.border)};
      color: ${({ theme: { colors } }) => colors.buttons.secondaryOutline.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => shade(0.3, colors.buttons.secondaryOutline.border)};
      border-color: ${({ theme: { colors } }) => shade(0.3, colors.buttons.secondaryOutline.border)};
      color: #ffffff;
    }
  `,
  successOutline: css`
    background: ${({ theme: { colors } }) => colors.buttons.successOutline.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.successOutline.border};
    color: ${({ theme: { colors } }) => colors.buttons.successOutline.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.successOutline.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.successOutline.border};
      color: ${({ theme: { colors } }) => colors.buttons.successOutline.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.buttons.successOutline.border};
      border-color: ${({ theme: { colors } }) => colors.buttons.successOutline.border};
      color: #ffffff;
    }
  `,
  dangerOutline: css`
    background: ${({ theme: { colors } }) => colors.buttons.dangerOutline.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.dangerOutline.border};
    color: ${({ theme: { colors } }) => colors.buttons.dangerOutline.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.dangerOutline.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.dangerOutline.border};
      color: ${({ theme: { colors } }) => colors.buttons.dangerOutline.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.buttons.dangerOutline.border};
      border-color: ${({ theme: { colors } }) => colors.buttons.dangerOutline.border};
      color: #ffffff;
    }
  `,
  infoOutline: css`
    background: ${({ theme: { colors } }) => colors.buttons.infoOutline.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.infoOutline.border};
    color: ${({ theme: { colors } }) => colors.buttons.infoOutline.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.infoOutline.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.infoOutline.border};
      color: ${({ theme: { colors } }) => colors.buttons.infoOutline.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.buttons.infoOutline.border};
      border-color: ${({ theme: { colors } }) => colors.buttons.infoOutline.border};
      color: #ffffff;
    }
  `,
  warningOutline: css`
    background: ${({ theme: { colors } }) => colors.buttons.warningOutline.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.warningOutline.border};
    color: ${({ theme: { colors } }) => colors.buttons.warningOutline.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.warningOutline.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.warningOutline.border};
      color: ${({ theme: { colors } }) => colors.buttons.warningOutline.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.buttons.warningOutline.border};
      border-color: ${({ theme: { colors } }) => colors.buttons.warningOutline.border};
      color: #ffffff;
    }
  `,
  lightDanger: css`
    background: ${({ theme: { colors } }) => colors.buttons.lightDanger.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.lightDanger.border};
    color: ${({ theme: { colors } }) => colors.buttons.lightDanger.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.lightDanger.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.lightDanger.border};
      color: ${({ theme: { colors } }) => colors.buttons.lightDanger.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.buttons.lightDanger.border};
      border-color: ${({ theme: { colors } }) => colors.buttons.lightDanger.border};
      color: #ffffff;
    }
  `,
  lightGreen: css`
    background: ${({ theme: { colors } }) => colors.buttons.lightGreen.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.lightGreen.border};
    color: ${({ theme: { colors } }) => colors.buttons.lightGreen.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.lightGreen.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.lightGreen.border};
      color: ${({ theme: { colors } }) => colors.buttons.lightGreen.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.buttons.lightGreen.border};
      border-color: ${({ theme: { colors } }) => colors.buttons.lightGreen.border};
      color: #ffffff;
    }
  `,
  lightYellow: css`
    background: ${({ theme: { colors } }) => colors.buttons.lightYellow.background};
    border-color: ${({ theme: { colors } }) => colors.buttons.lightYellow.border};
    color: ${({ theme: { colors } }) => colors.buttons.lightYellow.label};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.buttons.lightYellow.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.lightYellow.border};
      color: ${({ theme: { colors } }) => colors.buttons.lightYellow.label};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.buttons.lightYellow.border};
      border-color: ${({ theme: { colors } }) => colors.buttons.lightYellow.border};
      color: #ffffff;
    }
  `,
  white: css`
    background: #ffffff;
    border-color: #ffffff;
    color: ${({ theme: { colors } }) => colors.text};

    &:focus,
    &:active {
      background: shade(0.1, #fafafa);
      border-color: #fafafa;
      color: ${({ theme: { colors } }) => colors.text};
    }

    &:hover {
      background: shade(0.1, #ffffff);
      border-color: #ffffff;
      color: ${({ theme: { colors } }) => shade(0.1, colors.text)};
    }
  `,
  whiteOutline: css`
    background: transparent;
    border-color: #ffffff;
    color: #ffffff;

    &:focus,
    &:active {
      background: transparent;
      border-color: #fafafa;
      color: #fafafa;
    }

    &:hover {
      background: shade(0.1, #ffffff);
      border-color: #ffffff;
      color: ${({ theme: { colors } }) => shade(0.1, colors.text)};
    }
  `,
  grey: css`
    background: ${({ theme: { colors } }) => colors.greys.grey03};
    border-color: ${({ theme: { colors } }) => colors.greys.grey03};
    color: ${({ theme: { colors } }) => colors.greys.grey07};

    &:focus,
    &:active {
      background: ${({ theme: { colors } }) => colors.greys.grey03};
      border-color: ${({ theme: { colors } }) => colors.greys.grey03};
      color: ${({ theme: { colors } }) => colors.white};
    }

    &:hover {
      background: ${({ theme: { colors } }) => colors.greys.grey03};
      border-color: ${({ theme: { colors } }) => colors.greys.grey03};
      color: ${({ theme: { colors } }) => colors.white};
    }
  `,
};

export const Container = styled(Button)<ButtonCustomProps>`
  border-radius: 5px;
  font-size: 14px;
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-weight: 600;
  text-align: center;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  ${({ size }) => {
    if (size === 'large') {
      return css`
        padding: 8px 24px;
      `;
    }
    return css``;
  }};

  ${({ text }) => {
    if (text === 'uppercase') {
      return css`
        text-transform: uppercase;
      `;
    }
    return css`
      text-transform: none;
    `;
  }};

  ${({ status }) => {
    switch (status) {
      case 'none':
        break;
      case 'primary':
        return variations.primary;
      case 'secondary':
        return variations.secondary;
      case 'success':
        return variations.success;
      case 'danger':
        return variations.danger;
      case 'info':
        return variations.info;
      case 'warning':
        return variations.warning;
      case 'white':
        return variations.white;
      case 'primary-outline':
        return variations.primaryOutline;
      case 'secondary-outline':
        return variations.secondaryOutline;
      case 'success-outline':
        return variations.successOutline;
      case 'danger-outline':
        return variations.dangerOutline;
      case 'info-outline':
        return variations.infoOutline;
      case 'warning-outline':
        return variations.warningOutline;
      case 'white-outline':
        return variations.whiteOutline;
      case 'light-danger':
        return variations.lightDanger;
      case 'light-green':
        return variations.lightGreen;
      case 'light-yellow':
        return variations.lightYellow;
      case 'grey':
        return variations.grey;
      default:
        return variations.primary;
    }
    return undefined;
  }}

  & > svg {
    margin-right: 5px;
  }

  ${({ button }) => {
    if (button === 'icon') {
      return css`
        padding: 4px 8px;
        font-size: 1.2rem;

        & > svg {
          margin-right: 0;
        }
      `;
    }
    return css``;
  }}
`;

export const DropdownButtonChild = styled(Button)<ButtonDropdownCustomProps>`
  border-radius: 5px;
  font-size: 14px;
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-weight: 600;
  text-align: center;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isdropdownvisible }) =>
    isdropdownvisible
      ? css`
          background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.background)};
          border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.border)};
          color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.label)};
        `
      : css`
          background: ${({ theme: { colors } }) => colors.buttons.primaryOutline.background};
          border-color: ${({ theme: { colors } }) => colors.buttons.primaryOutline.border};
          color: ${({ theme: { colors } }) => colors.buttons.primaryOutline.label};
        `};

  &:hover {
    background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.background)};
    border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.border)};
    color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.label)};
  }

  ${({ size }) => {
    if (size === 'large') {
      return css`
        padding: 8px 24px;
      `;
    }
    return css``;
  }};

  ${({ text }) => {
    if (text === 'uppercase') {
      return css`
        text-transform: uppercase;
      `;
    }
    return css`
      text-transform: none;
    `;
  }};

  & > svg {
    margin-right: 5px;
  }

  ${({ button }) => {
    if (button === 'icon') {
      return css`
        padding: 4px 8px;
        font-size: 1.2rem;

        & > svg {
          margin-right: 0;
        }
      `;
    }
    return css``;
  }}
`;
