/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import type { RegisterOptions } from 'react-hook-form';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { twMerge } from 'tailwind-merge';

export const FormItem = ({
  children,
  name,
  label,
  rules,
  hidden,
  description,
  readable,
  className,
  hideLabel,
  ...props
}: {
  children: React.ReactNode;
  name: string;
  description?: string | React.ReactNode;
  rules?: RegisterOptions;
  label?: string | React.ReactNode;
  readable?: string;
  hideLabel?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const { control, formState } = useFormContext();
  const value = useWatch({ name });

  const labelIsString = typeof label === 'string';

  return (
    <div
      className={twMerge(
        'flex w-auto flex-col gap-2 font-medium dark:text-white',
        hidden ? 'h-0 w-0 overflow-hidden' : '',
        className,
      )}
      {...props}
    >
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          required: rules?.required ? `O campo ${labelIsString ? label : readable} é obrigatório` : undefined,
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              {hideLabel ? null : (
                <label className="flex w-full flex-col" title={labelIsString ? label : readable}>
                  <div className="flex w-full gap-1 overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <span className="max-w-fit overflow-hidden overflow-ellipsis whitespace-nowrap">{label}</span>
                    <span className="text-red-600">{rules?.required && label ? ' *' : ''}</span>
                  </div>
                </label>
              )}
              {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, {
                    control,
                    ...child.props,
                    ...field,
                    onChange: child.props.onChange
                      ? (e: any) => {
                          child.props.onChange(e);
                          field.onChange(e);
                        }
                      : field.onChange,
                    value,
                    name,
                    disabled: formState.disabled || child.props.disabled,
                    className: twMerge(child.props.className, error?.message ? 'border-red-600' : ''),
                  });
                }
                return child;
              })}
              {description ? <div className="text-sm text-neutral-500">{description}</div> : null}

              {error?.message ? (
                <span className="w-full whitespace-break-spaces text-sm text-red-600">
                  {error?.message as string | undefined}
                </span>
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
};
