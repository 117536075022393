import styled, { css } from 'styled-components';

import { CellStatusProps } from './types';

export const Container = styled.div<CellStatusProps>`
  ${({
    status,
    theme: {
      colors: { status: statusColors },
    },
  }) => {
    if (status) {
      const themeColors: any = statusColors;
      const statusColor = themeColors[`${status}`];

      return css`
        border-left: 6px solid ${statusColor};
        padding: 8px 8px;
      `;
    }
    return css`
      border-left: 6px solid transparent;
      padding: 8px 8px;
    `;
  }}

  .ant-select {
    width: 100%;
  }
`;
