import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { Breadcrumb } from '@/components';
import { FlatButton } from '@/components/ui/button';

// import { Select as AllSelect } from '../../../components/AllSelect';
import { Can } from '../../../hooks/ability';
import { UserTable } from './user.table';

export const UserPage: React.FC = () => {
  const { t } = useTranslation(['translation', 'admin']);

  return (
    <div className="flex h-full w-full flex-col overflow-y-hidden">
      <Breadcrumb items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.users') }]} />

      <div className="flex h-full flex-col gap-2 overflow-y-hidden p-4">
        <div className="flex justify-between">
          <div className="text-xl font-bold text-primary-400">{t('pages.admin.user.title_page')}</div>

          <Can I="admin.user.store" a="">
            <a href="admin/user/new" target="_blank" rel="noreferrer">
              <FlatButton>
                <span className="flex items-center gap-2">
                  <FaPlus />
                  {t('pages.admin.user.buttonAdd')}
                </span>
              </FlatButton>
            </a>
          </Can>
        </div>

        <UserTable />
      </div>
    </div>
  );
};
