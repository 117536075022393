import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#FFA23C',
    secondary: '#262626',
    text: '#514E49',
    success: '#159734',
    danger: '#FF6B3C',
    verydanger: '#e03400',
    info: '#659BD1',
    warning: '#FFA23C',
    lightOrange: '#FFE1BA',
    lightGrey: '#F0F0F0',
    white: '#FFFFFF',
    darkBlue: '#01649D',
    lightBlue: '#E6F4FD',
    lightGreen: '#DAF2DA',
    lightRed: '#FCE5E5',
    greys: {
      grey01: '#F5F5F5',
      grey02: '#E2E9E9',
      grey03: '#E3EAEA',
      grey04: '#DAE3E3',
      grey05: '#BBBBBB',
      grey06: '#AABBBB',
      grey07: '#84827F',
      grey08: '#868F9A',
      grey09: '#424242',
      grey10: '#262626',
      grey11: '#E1DFDF',
    },
    status: {
      status01: '#E03400',
      status02: '#FF6B3C',
      status03: '#FFA23C',
      status04: '#DBDA48',
      status05: '#159734',
      status06: '#3470AD',
      status07: '#659BD1',
      status08: '#424242',
      status09: '#929292',
      status10: '#BBBBBB',
    },
    fields: {
      icon: '#868F9A',
      primary: {
        value: '#FFA23C',
        label: '#262626',
        border: '#262626',
      },
      secondary: {
        value: '#FFA23C',
        label: '#262626',
        border: '#DAE3E3',
      },
    },
    titles: {
      primary: '#FFFFFF',
      secondary: '#514E49',
    },
    buttons: {
      primary: {
        label: '#FFFFFF',
        background: '#FFA23C',
        border: '#FFA23C',
      },
      secondary: {
        label: '#868F9A',
        background: '#F5F5F5',
        border: '#F5F5F5',
      },
      success: {
        label: '#FFFFFF',
        background: '#159734',
        border: '#159734',
      },
      danger: {
        label: '#FFFFFF',
        background: '#FF6B3C',
        border: '#FF6B3C',
      },
      info: {
        label: '#FFFFFF',
        background: '#659BD1',
        border: '#659BD1',
      },
      warning: {
        label: '#FFFFFF',
        background: '#FFA23C',
        border: '#FFA23C',
      },
      primaryOutline: {
        label: '#FFA23C',
        background: 'transparent',
        border: '#FFA23C',
      },
      secondaryOutline: {
        label: '#868F9A',
        background: 'transparent',
        border: '#E3EAEA',
      },
      successOutline: {
        label: '#159734',
        background: 'transparent',
        border: '#159734',
      },
      dangerOutline: {
        label: '#FF6B3C',
        background: 'transparent',
        border: '#FF6B3C',
      },
      infoOutline: {
        label: '#659BD1',
        background: 'transparent',
        border: '#659BD1',
      },
      warningOutline: {
        label: '#FFA23C',
        background: 'transparent',
        border: '#FFA23C',
      },
      lightDanger: {
        label: '#A22224',
        background: '#FFC9CA',
        border: '#FFC9CA',
      },
      lightGreen: {
        label: '#377C45',
        background: '#98EEC3',
        border: '#98EEC3',
      },
      lightYellow: {
        label: '#F1D20D',
        background: '#FCFE9C',
        border: '#FCFE9C',
      },
    },
  },
  fonts: {
    primary: 'Montserrat, sans serif',
  },
};

export default theme;
