import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCheckSquare } from 'react-icons/ai';
import { BiRefresh, BiCommentError } from 'react-icons/bi';
import {
  BsUnlock,
  BsCardChecklist,
  BsFileEarmarkCheck,
  BsCardList,
  BsFileEarmarkSpreadsheet,
  BsFileRichtext,
} from 'react-icons/bs';
import {
  FaDownload,
  FaEye,
  FaRegFolderOpen,
  FaRegTrashAlt,
  FaSeedling,
  FaSearch,
  FaPlus,
  FaUserTie,
  FaFileCsv,
  FaCheck,
  FaRegClone,
} from 'react-icons/fa';
import { FcApproval, FcMediumPriority, FcHighPriority, FcSurvey, FcPlus, FcSearch } from 'react-icons/fc';
import { FiEdit } from 'react-icons/fi';
import { GoCloudUpload } from 'react-icons/go';

import { useAbility } from '@/hooks/ability';
import { cn } from '@/lib/utils';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ScanEye } from 'lucide-react';

import { SIconContainer, SIcon, FakeIcon, SIconButton } from './styles';
import { IconWithTooltipProps } from './types';

const IconWithTooltip: React.FC<
  IconWithTooltipProps & { tabIndex?: number; button?: boolean; permission?: string }
> = ({
  action,
  className,
  title,
  loading,
  placement = 'top',
  visibility,
  color,
  tabIndex,
  button = true,
  permission,
  ...rest
}) => {
  const { t } = useTranslation();
  const ability = useAbility();

  const hasPermission = !permission || ability.can(permission, '');

  const renderIconLabel = (action: string) => {
    switch (action) {
      case 'edit':
        return t('edit');
      case 'destroy':
        return t('remove');
      case 'view':
        return t('view');
      case 'download':
        return t('download.title');
      default:
        return '';
    }
  };

  const renderIconAction = (type: string) => {
    switch (type) {
      case 'beneficiary':
        return <FaUserTie />;
      case 'csv':
        return <FaFileCsv />;
      case 'create':
        return <FaPlus />;
      case 'edit':
        return <FiEdit />;
      case 'preview-watermark':
        return <ScanEye />;
      case 'view':
        return <FaEye />;
      case 'search':
        return <FaSearch />;
      case 'detail':
        return <FaEye />;
      case 'destroy':
        return <FaRegTrashAlt />;
      case 'permission':
        return <BsUnlock />;
      case 'download':
        return <FaDownload />;
      case 'upload':
        return <GoCloudUpload />;
      case 'farm':
        return <FaSeedling />;
      case 'attachment':
        return <FaRegFolderOpen />;
      case 'registration':
        return <BsCardList />;
      case 'averbation':
        return <BsFileEarmarkCheck />;
      case 'license':
        return <BsCardChecklist />;
      case 'registrationArea':
        return <BsFileEarmarkSpreadsheet />;
      case 'registrationField':
        return <BsFileRichtext />;
      case 'approval':
        return <AiFillCheckSquare />;
      case 'check':
        return <FaCheck />;
      // eslint-disable-next-line
      case 'approval':
        return <FcApproval />;
      case 'medium-priority':
        return <FcMediumPriority />;
      case 'high-priority':
        return <FcHighPriority />;
      case 'survey':
        return <FcSurvey />;
      case 'plus':
        return <FcPlus />;
      case 'fc-search':
        return <FcSearch />;
      case 'clone':
        return <FaRegClone />;
      case 'refresh':
        return <BiRefresh />;
      case 'error':
        return <BiCommentError />;
      case 'fake':
        return <FakeIcon />;
      default:
        return null;
    }
  };

  if (!hasPermission) return null;

  return (
    <SIconContainer className={className}>
      <Tooltip title={title} placement={placement}>
        {/**
         * type "button" para o botão não realizar a ação de "submit" nos formulários
         */}
        {button ? (
          <SIconButton
            {...rest}
            type="button"
            tabIndex={tabIndex}
            className={cn(visibility, 'hover:opacity-50 focus:opacity-50')}
            style={{ color }}
            title={renderIconLabel(action)}
            onClick={loading ? undefined : (rest.onClick ?? undefined)}
          >
            {loading ? <LoadingOutlined style={{ fontSize: 20, color: '#FFA23C' }} spin /> : renderIconAction(action)}
          </SIconButton>
        ) : (
          <SIcon
            {...rest}
            tabIndex={tabIndex}
            className={cn(visibility, 'hover:opacity-50 focus:opacity-50')}
            style={{ color }}
            title={renderIconLabel(action)}
            onClick={loading ? undefined : (rest.onClick ?? undefined)}
          >
            {loading ? <LoadingOutlined style={{ fontSize: 20, color: '#FFA23C' }} spin /> : renderIconAction(action)}
          </SIcon>
        )}
      </Tooltip>
    </SIconContainer>
  );
};

export default IconWithTooltip;
