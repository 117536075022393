import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin as ASpin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

const Spin: React.FC<SpinProps> = ({ children, ...rest }) => {
  return (
    <ASpin indicator={<LoadingOutlined style={{ fontSize: 32, color: '#FFA23C' }} spin />} {...rest}>
      {children}
    </ASpin>
  );
};

export default Spin;
