import styled from 'styled-components';

interface ContainerProps {
  visible: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  width: 280px;
  background: #ffffff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 4px 0 8px;
  position: absolute;
  top: 57px;
  right: -30px;
  border: solid 1px #e3eaea;

  h6 {
    color: ${({ theme: { colors } }) => colors.text};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 4px 8px;
    padding-right: 36px;
  }

  .ant-collapse {
    color: ${({ theme: { colors } }) => colors.text};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 14px;
    font-weight: 400;
    border: 0;
    border-radius: 0;
    background: transparent;

    > .ant-collapse-item > .ant-collapse-header {
      color: ${({ theme: { colors } }) => colors.text};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 14px;
      font-weight: 400;
      border-radius: 0;

      &:hover,
      &:hover svg,
      &:hover .anticon {
        cursor: pointer;
        color: ${({ theme: { colors } }) => colors.primary};
      }
    }

    > .ant-collapse-item:last-child,
    > .ant-collapse-item:last-child > .ant-collapse-header {
      border: 0;
      border-radius: 0 0 0 0;

      &:hover,
      &:hover svg,
      &:hover .anticon {
        cursor: pointer;
        color: ${({ theme: { colors } }) => colors.primary};
      }
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 4px 8px;
    }

    .ant-collapse-content {
      border-top: 0;
      border-radius: 0;
    }

    .ant-list {
      color: ${({ theme: { colors } }) => colors.text};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 14px;
      font-weight: 400;

      .ant-list-item {
        padding: 4px 0;

        &.language-inactive,
        &.language-inactive:hover {
          cursor: not-allowed;
          filter: opacity(0.3);
        }

        &:hover:not(.language-inactive) {
          cursor: pointer;
          color: ${({ theme: { colors } }) => colors.primary};
        }
      }
    }
  }

  .language-active {
    color: ${({ theme: { colors } }) => colors.primary};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-weight: 600;
  }
`;

export const CollapseHeader = styled.div`
  display: flex;
  align-items: center;

  > svg {
    color: ${({ theme: { colors } }) => colors.text};
    font-size: 18px;
    margin-right: 4px;
  }

  &:hover,
  &:hover svg {
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors.primary};
  }
`;

export const DropdownItem = styled.div`
  > a {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    padding-right: 36px;
    color: ${({ theme: { colors } }) => colors.text};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 14px;
    font-weight: 400;

    > svg {
      color: ${({ theme: { colors } }) => colors.text};
      font-size: 18px;
      margin-right: 4px;
    }

    &:hover,
    &:hover svg {
      cursor: pointer;
      color: ${({ theme: { colors } }) => colors.primary};
    }
  }
`;

export const SFlag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  img {
    height: 15px;
    width: 15px;
  }

  * {
    margin: 0 3px;
  }
`;
