import { PaginatedQueryParams, usePaginatedQuery } from '@/hooks/use-paginated-query';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from '@/hooks/use-select-infinite.query';
import PaginationData from '@/types/Api/PaginationData';
import PermissionGroupData from '@/types/PermissionGroup/PermissionGroupData';

type Params = PaginatedQueryParams;

export const useGetPermissionGroupsQuery = ({ dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<PermissionGroupData>({
    queryKey: ['admin/group', params],
    url: `admin/group`,
    permissions: 'admin.group.index',
    params: {
      ...params,
      dispatchPagination,
    },
  });
};

export const useGetPermissionGroupsOptionsQuery = (params: SelectInfiniteQueryParams) => {
  const query = useSelectInfiniteQuery<PaginationData<PermissionGroupData>>({
    url: 'admin/group',
    queryKey: ['admin/group', params],
    staleTime: 1000 * 60 * 5,
    permissions: ['admin.group.index'],
    params,
  });

  return query;
};
