import React from 'react';

import { BadgeProps } from 'antd/lib/badge';

import { BadgeContainer } from './styles';

const Badge: React.FC<BadgeProps> = ({ children, ...rest }) => {
  return <BadgeContainer {...rest}>{children}</BadgeContainer>;
};

export default Badge;
