import { AssigneeData } from '@/types/CreditRequest/AssigneeData';
import { StateCreator } from 'zustand';

import { CreditRequestSlice } from '../../use-credit-request-store';

export type AssigneesSlice = {
  hasAssignees: boolean;
  assigneeList: AssigneeData[];
  assigneeToEdit: AssigneeData | null;
  isAssigneeModalOpen: boolean;
  setAssigneeList: (value: AssigneeData[]) => void;
  setHasAssignees: (value: boolean) => void;
  setAssigneeToEdit: (value: AssigneeData | null) => void;
  setAssigneeModalOpen: (value: boolean) => void;
};

export const assigneesSliceInitialState = {
  hasAssignees: false,
  assigneeList: [],
  assigneeToEdit: null,
  isAssigneeModalOpen: false,
} as AssigneesSlice;

export const createAssigneesSlice: StateCreator<AssigneesSlice & CreditRequestSlice, [], [], AssigneesSlice> = set => ({
  ...assigneesSliceInitialState,
  setAssigneeList: value => set(state => ({ ...state, assigneeList: value })),
  setHasAssignees: value => set(state => ({ ...state, hasAssignees: value })),
  setAssigneeToEdit: value =>
    set(state => ({
      ...state,
      assigneeToEdit: value,
      isAssigneeModalOpen: value !== null,
    })),
  setAssigneeModalOpen: value => set(state => ({ ...state, isAssigneeModalOpen: value })),
});
