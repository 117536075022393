export function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getOriginalName(fileName: string, withExtension = false) {
  const splitName = fileName.split('.');
  const splitLength = splitName.length;
  const extension = splitName[splitLength - 1];
  delete splitName[splitLength - 1];
  const finalFileName = splitName
    .filter((s: string) => !!s)
    .join('_')
    .replace(/ /g, '_');
  return !withExtension ? finalFileName : `${finalFileName}.${extension}`;
}

export function downloadDocument(base64: string, filename: string) {
  const link = document.createElement('a');
  link.href = base64;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
