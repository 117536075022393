import { ThirdProviderEnum } from '@/types/CreditRequest/enums/ThirdProvidersEnum';
import { StateCreator } from 'zustand';

export type ThirdProviderSlice = {
  thirdProviders: ThirdProviderEnum[];
  setThirdProviders: (providers: ThirdProviderEnum[]) => void;
  addThirdProvider: (provider: ThirdProviderEnum) => void;
  removeThirdProvider: (provider: ThirdProviderEnum) => void;
  shouldShowSap: boolean;
  shouldShowDatasul: boolean;
};

export const thirdProviderSliceInitialState = {
  thirdProviders: [],
  shouldShowSap: false,
  shouldShowDatasul: false,
} as ThirdProviderSlice;

export const createThirdProviderSlice: StateCreator<ThirdProviderSlice, [], [], ThirdProviderSlice> = set => ({
  ...thirdProviderSliceInitialState,
  setThirdProviders: (providers: ThirdProviderEnum[]) =>
    set({
      thirdProviders: providers,
      shouldShowSap: providers.includes('sap'),
      shouldShowDatasul: providers.includes('datasul'),
    }),
  addThirdProvider: (provider: ThirdProviderEnum) =>
    set(state => ({
      thirdProviders: [...state.thirdProviders, provider],
      shouldShowSap: provider === 'sap',
      shouldShowDatasul: provider === 'datasul',
    })),
  removeThirdProvider: (provider: ThirdProviderEnum) =>
    set(state => ({
      thirdProviders: state.thirdProviders.filter(p => p !== provider),
      shouldShowSap: !(provider === 'sap'),
      shouldShowDatasul: !(provider === 'datasul'),
    })),
});
