import styled from 'styled-components';

import { LogoProps } from './types';

export const LogoContainer = styled.div`
  img {
    display: block;
    height: 48px;
  }
`;

export const LogoSmall = styled.div<LogoProps>`
  img {
    height: ${({ size }) => (size ? `${size}px` : '76px')};
    display: block;
  }
`;
