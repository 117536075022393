import React, { createContext, useContext, useEffect, useReducer } from 'react';

import moment from 'moment';

import datasulApi from '../services/api/datasul';
import sapApi from '../services/api/sap';
import serasaApi from '../services/api/serasa';

type GetProviderDataBySlugReturnType = IntegrationData & {
  dispatchKeys: {
    status: SetConnectionAction['type'];
    loading: SetLoadingAction['type'];
    lastPing: SetLastPingAction['type'];
  };
  cacheKeys: {
    status: string;
    lastPing: string;
  };
  labels: {
    ok: string;
    failed: string;
    displayName: string;
  };
  connectionRequest: (abortController?: AbortController) => Promise<any>;
};

interface IntegrationContextProps {
  serasa: IntegrationData;
  datasul: IntegrationData;
  sap: IntegrationData;
  checkStatus: (providerSlug: IntegrationProvider, refresh?: boolean) => void;
  getProviderDataBySlug: (slug: IntegrationProvider) => GetProviderDataBySlugReturnType | null;
}

export const IntegrationContext = createContext<IntegrationContextProps>({} as IntegrationContextProps);

export const useIntegrationContext = () => useContext(IntegrationContext);

export interface IntegrationData {
  status: boolean;
  loading: boolean;
  lastPing: string | null;
}

interface IntegrationReducerState {
  serasa: IntegrationData;
  datasul: IntegrationData;
  sap: IntegrationData;
}

export enum IntegrationProvider {
  SERASA = 'serasa',
  DATASUL = 'datasul',
  SAP = 'sap',
}

export interface SetConnectionAction {
  type: 'SET_CONNECTION_SERASA' | 'SET_CONNECTION_DATASUL' | 'SET_CONNECTION_SAP';
  payload: boolean;
}

export interface SetLoadingAction {
  type: 'SET_LOADING_SERASA' | 'SET_LOADING_DATASUL' | 'SET_LOADING_SAP';
  payload: boolean;
}

export interface SetLastPingAction {
  type: 'SET_LAST_PING_SERASA' | 'SET_LAST_PING_DATASUL' | 'SET_LAST_PING_SAP';
  payload: string | null;
}

type IntegrationAction = SetConnectionAction | SetLoadingAction | SetLastPingAction;

const integrationReducer = (state: IntegrationReducerState, action: IntegrationAction) => {
  switch (action.type) {
    case 'SET_CONNECTION_SERASA':
      return { ...state, serasa: { ...state.serasa, status: action.payload } };
    case 'SET_CONNECTION_DATASUL':
      return { ...state, datasul: { ...state.datasul, status: action.payload } };
    case 'SET_CONNECTION_SAP':
      return { ...state, sap: { ...state.sap, status: action.payload } };
    case 'SET_LOADING_SERASA':
      return { ...state, serasa: { ...state.serasa, loading: action.payload } };
    case 'SET_LOADING_DATASUL':
      return { ...state, datasul: { ...state.datasul, loading: action.payload } };
    case 'SET_LOADING_SAP':
      return { ...state, sap: { ...state.sap, loading: action.payload } };
    case 'SET_LAST_PING_SERASA':
      return { ...state, serasa: { ...state.serasa, lastPing: action.payload } };
    case 'SET_LAST_PING_DATASUL':
      return { ...state, datasul: { ...state.datasul, lastPing: action.payload } };
    case 'SET_LAST_PING_SAP':
      return { ...state, sap: { ...state.sap, lastPing: action.payload } };
    default:
      return state;
  }
};

const serasaInitialState = {
  status: localStorage.getItem('serasa_conn') === 'true',
  loading: false,
  lastPing: (() => {
    const value = localStorage.getItem('serasa_last_ping');
    if (value) {
      return moment(localStorage.getItem('serasa_last_ping')).toString();
    }
    return null;
  })(),
} as IntegrationData;

const datasulInitialState = {
  status: localStorage.getItem('datasul_conn') === 'true',
  loading: false,
  lastPing: (() => {
    const value = localStorage.getItem('datasul_last_ping');
    if (value) {
      return moment(localStorage.getItem('datasul_last_ping')).toString();
    }
    return null;
  })(),
} as IntegrationData;

const sapInitialState = {
  status: localStorage.getItem('sap_conn') === 'true',
  loading: false,
  lastPing: (() => {
    const value = localStorage.getItem('sap_last_ping');
    if (value) {
      return moment(localStorage.getItem('sap_last_ping')).toString();
    }
    return null;
  })(),
} as IntegrationData;

export const IntegrationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ serasa, datasul, sap }, dispatchIntegrationStatus] = useReducer(integrationReducer, {
    serasa: serasaInitialState,
    datasul: datasulInitialState,
    sap: sapInitialState,
  });

  const getProviderDataBySlug = (slug: IntegrationProvider): GetProviderDataBySlugReturnType | null => {
    switch (slug) {
      case IntegrationProvider.SERASA:
        return {
          ...serasa,
          dispatchKeys: {
            status: 'SET_CONNECTION_SERASA',
            loading: 'SET_LOADING_SERASA',
            lastPing: 'SET_LAST_PING_SERASA',
          },
          cacheKeys: {
            status: 'serasa_conn',
            lastPing: 'serasa_last_ping',
          },
          connectionRequest: serasaApi.connection,
          labels: {
            ok: 'serasa.ok',
            failed: 'serasa.failed',
            displayName: 'Serasa',
          },
        };
      case IntegrationProvider.DATASUL:
        return {
          ...datasul,
          dispatchKeys: {
            status: 'SET_CONNECTION_DATASUL',
            loading: 'SET_LOADING_DATASUL',
            lastPing: 'SET_LAST_PING_DATASUL',
          },
          cacheKeys: {
            status: 'datasul_conn',
            lastPing: 'datasul_last_ping',
          },
          connectionRequest: datasulApi.connection,
          labels: {
            ok: 'datasul.ok',
            failed: 'datasul.failed',
            displayName: 'Datasul',
          },
        };
      case IntegrationProvider.SAP:
        return {
          ...sap,
          dispatchKeys: {
            status: 'SET_CONNECTION_SAP',
            loading: 'SET_LOADING_SAP',
            lastPing: 'SET_LAST_PING_SAP',
          },
          cacheKeys: {
            status: 'sap_conn',
            lastPing: 'sap_last_ping',
          },
          connectionRequest: sapApi.connection,
          labels: {
            ok: 'sap.ok',
            failed: 'sap.failed',
            displayName: 'SAP',
          },
        };
      default:
        return null;
    }
  };

  const checkStatus = (providerSlug: IntegrationProvider, refresh = false, abortController?: AbortController) => {
    const provider = getProviderDataBySlug(providerSlug);
    if (!provider) return;

    if (moment().diff(moment(provider.lastPing)) > 300000 || provider.lastPing === null || refresh) {
      localStorage.setItem(provider.cacheKeys.lastPing, moment().toString());
      dispatchIntegrationStatus({ type: provider.dispatchKeys.lastPing, payload: moment().toString() });

      dispatchIntegrationStatus({ type: provider.dispatchKeys.loading, payload: true });
      provider
        .connectionRequest(abortController)
        .then(response => {
          if (response === 'disconnected') throw new Error('Disconnected');
          dispatchIntegrationStatus({ type: provider.dispatchKeys.status, payload: true });
          localStorage.setItem(provider.cacheKeys.status, 'true');
        })
        .catch(() => {
          dispatchIntegrationStatus({ type: provider.dispatchKeys.status, payload: false });
          localStorage.setItem(provider.cacheKeys.status, 'false');
        })
        .finally(() => dispatchIntegrationStatus({ type: provider.dispatchKeys.loading, payload: false }));
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    checkStatus(IntegrationProvider.SERASA, true, controller);
    checkStatus(IntegrationProvider.DATASUL, true, controller);
    checkStatus(IntegrationProvider.SAP, true, controller);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <IntegrationContext.Provider value={{ serasa, datasul, sap, checkStatus, getProviderDataBySlug }}>
      {children}
    </IntegrationContext.Provider>
  );
};
