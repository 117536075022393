import React, { forwardRef } from 'react';

import { Input as AInput } from 'antd';
import { PasswordProps } from 'antd/lib/input/Password';

const Password: React.RefForwardingComponent<any, PasswordProps> = (props, ref) => {
  return <AInput.Password {...props} ref={ref} />;
};

export default forwardRef(Password);
