import React, { useState, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBars, FaCaretUp, FaCaretDown, FaChevronLeft } from 'react-icons/fa';
import { MdNotifications, MdNotificationsActive } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

import avatarClient from '../../../assets/img/client/logo-mini.png';
import { Badge } from '../../../components';
import appConfigs from '../../../configs/app';
import { useAuth } from '../../../hooks/auth';
import { useCache } from '../../../hooks/cache';
import { useNotifications } from '../../../hooks/notifications';
import { useSidebar } from '../../../hooks/sidebar';
import theme from '../../../styles/theme';
import renderComponent from '../../../utils/RenderComponent';
import Dropdown from './Dropdown';
import { NotificationsDrawer } from './NotificationsDrawer';
import { Container, ContainerDropdown, ToggleSidebar, BackLink } from './styles';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();
  const { position } = useCache();
  const { unreadNotifications, setDrawerVisible, notificationCount } = useNotifications();
  const { isChanging, isMini, toggle } = useSidebar();

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const handleDropdown = (event: MouseEvent) => {
    event.preventDefault();
    setVisibleDropdown(!visibleDropdown);
  };

  const userPosition = useMemo(() => {
    if (position) {
      const findPosition = position.find(positionItem => positionItem.id === user.position_id);
      if (findPosition) return findPosition.name;
    }
    return '';
    // eslint-disable-next-line
  }, [isMini, isChanging]);

  const classCSS = useMemo(() => {
    if (isChanging) return isMini ? 'sidebar-mini' : 'sidebar-large';

    return '';
    // eslint-disable-next-line
  }, [isMini, isChanging]);

  const showBackLink = useMemo(() => {
    const locationPath = location.pathname.split('/');
    if (locationPath[1] === 'credit-request' && (locationPath[2] === 'edit' || locationPath[2] === 'view')) {
      return false;
    }
    if (locationPath[1] === 'dashboard') {
      return false;
    }
    return true;
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      <Container className={classCSS} sidebarIsMini={isMini}>
        <div className="header-base-actions">
          <ToggleSidebar onClick={toggle}>
            <FaBars />
          </ToggleSidebar>

          {showBackLink && (
            <BackLink onClick={() => navigate(-1)}>
              <FaChevronLeft />
              {t('header.back_page')}
            </BackLink>
          )}
        </div>

        {/* <Menu /> */}

        <ContainerDropdown>
          <button type="button" className="notifications" onClick={() => setDrawerVisible(true)}>
            <Badge overflowCount={99} count={notificationCount} size="small">
              {renderComponent(
                unreadNotifications.length > 0,
                <MdNotificationsActive color={theme.colors.primary} style={{ paddingLeft: 12, fontSize: 32 }} />,
                <MdNotifications color={theme.colors.primary} style={{ paddingLeft: 12, fontSize: 32 }} />,
              )}
            </Badge>
          </button>
          <a href="#menu" onClick={handleDropdown} className="person-data">
            <div className="person">
              <strong className="name-person">{user?.name}</strong>
              <span className="profile-person">
                <span>{userPosition}</span>
              </span>
            </div>
            <div className="avatar">
              <img
                src={user.photo !== null ? user.photo : avatarClient}
                alt={appConfigs.clientTitle}
                className="img-avatar"
              />

              {visibleDropdown ? <FaCaretUp /> : <FaCaretDown />}
            </div>
          </a>
          <Dropdown visible={visibleDropdown} />
        </ContainerDropdown>

        <NotificationsDrawer toggleVisibility={() => setDrawerVisible(false)} />
      </Container>
    </>
  );
};

export default Header;
