import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';

import { LoadingOutlined } from '@ant-design/icons';
import { Select as AntSelect, Divider } from 'antd';

import { Latinise } from '../../utils/Latinise';
import Button from '../Button';
import { SelectProps } from './types';

const Select: React.RefForwardingComponent<any, SelectProps> = (
  { options, dropdownOptions, loading, autocomplete = false, ...rest },
  ref,
) => {
  const { t } = useTranslation();
  const { Option } = AntSelect;

  let dropdownRender = (menu: React.ReactElement) => menu;
  if (dropdownOptions) {
    const { buttonText, buttonIcon, buttonOnClick } = dropdownOptions;
    const CustomIcon = buttonIcon;
    dropdownRender = (menu: React.ReactElement) => (
      <>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <Button status="primary-outline" onClick={e => buttonOnClick(e)} block>
          {buttonIcon ? <CustomIcon /> : <FiPlus />}
          {buttonText}
        </Button>
      </>
    );
  }

  const restProps = rest;
  if (autocomplete) {
    restProps.notFoundContent = () => (loading ? <LoadingOutlined /> : t('form.autocompleteEmpty'));
  }

  return (
    <AntSelect
      allowClear
      optionFilterProp="label"
      optionLabelProp="label"
      dropdownRender={dropdownRender}
      loading={loading}
      showSearch
      filterOption={(input, option) => {
        return Latinise.normalize(option.children).indexOf(Latinise.normalize(input)) >= 0;
      }}
      {...restProps}
      ref={ref}
    >
      {options
        ? options
            .filter((itemValue, index, self) => self.indexOf(itemValue) === index)
            .map(option => (
              <Option key={option.value} selected={option?.selected} disabled={option?.disabled} {...option}>
                {option.label}
              </Option>
            ))
        : // : getOptions().then(response => {
          //     response.map(option => (
          //       <Option key={option.value} {...option}>
          //         {option.label}
          //       </Option>
          //     ));
          //   })
          []}
    </AntSelect>
  );
};

export default forwardRef(Select);
