import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiRefreshFill } from 'react-icons/ri';

import { useIntegrationContext } from '@/context/IntegrationContext';
import { CheckCircleFilled, CloseCircleFilled, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import app from '../../configs/app';
import theme from '../../styles/theme';
import { Rotate } from './styles';
import IntegrationtatusProps from './types';

export const IntegrationStatus: React.FC<IntegrationtatusProps> = ({ provider: providerSlug }) => {
  const { t } = useTranslation();
  const { serasa, checkStatus, datasul, getProviderDataBySlug } = useIntegrationContext();

  const provider = useMemo(
    () => getProviderDataBySlug(providerSlug),
    [providerSlug, serasa, datasul, getProviderDataBySlug],
  );

  const renderStatusText = () => {
    if (provider?.loading)
      return (
        <span style={{ color: theme.colors.warning, display: 'flex', gap: '2px', alignItems: 'center' }}>
          <Rotate>
            <RiRefreshFill size={18} />
          </Rotate>{' '}
          {t('loading')}
        </span>
      );
    return provider?.status ? (
      <span style={{ color: theme.colors.success }}>
        <CheckCircleFilled size={24} /> {t(provider.labels.ok)}
      </span>
    ) : (
      <span style={{ color: theme.colors.danger }}>
        <CloseCircleFilled size={24} /> {t(provider?.labels.failed ?? '')}
      </span>
    );
  };

  return (
    <div style={{ fontSize: 13, height: 50, lineHeight: 2, textAlign: 'center' }}>
      <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
          {renderStatusText()}
          {'  '}
          <RedoOutlined onClick={() => checkStatus(providerSlug, true)} />
        </div>

        <div style={{ fontSize: 12 }}>
          {t('updated_at')} {provider ? moment(provider.lastPing).format(app.formatDateTime) : t('not-provided')}
        </div>
      </>
    </div>
  );
};

export default IntegrationStatus;
