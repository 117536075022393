import { AxiosResponse } from 'axios';

import { GuaranteesResumeData } from '../../@types/data/GuaranteesResumeData';
import { GuaranteeTypeData } from '../../@types/data/GuaranteeTypeData';
import PaginationData from '../../@types/data/PaginationData';
import CreditRequestData from '../../types/CreditRequest/CreditRequestData';
import { formatValuesToFormData } from '../form';
import api from './api';

export default {
  requests: {
    get: async (params: object = null): Promise<any> => {
      return api.get('credit-request', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('credit-request', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.post(`credit-request/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`credit-request/${id}`, params);
    },
    getById: async (id: string, params: object = null): Promise<AxiosResponse<CreditRequestData>> => {
      return api.get(`credit-request/${id}`, params);
    },
    getDocuments: async (id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/${id}/attachment`, params);
    },
    contractVolume: async (contractNumber: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/contract/${contractNumber}/volume`, params);
    },
    getKanban: async (params: object = null): Promise<any> => {
      return api.get(`credit-request/kanban`, params);
    },
    getKanbanByColumn: async (statusId: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/kanban/${statusId}`, params);
    },
    updateStatus: async (credit_request_id: string, params: object): Promise<any> => {
      return api.post(`credit-request/status/${credit_request_id}`, params);
    },
    generateReport: async (params: object = null, credit_request_id: string): Promise<any> => {
      return api.post(`credit-request/${credit_request_id}/report`, params);
    },
    listReport: async (credit_request_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/${credit_request_id}/report`, params);
    },
    generateExport: async (params: object = null): Promise<any> => {
      return api.post(`credit-request/report/all`, params);
    },
    listReportExposure: async (params: object = null): Promise<any> => {
      return api.get(`credit-request/report/exposure`, params);
    },
    generateExportExposure: async (params: object = null): Promise<any> => {
      return api.post(`credit-request/report/exposure`, params);
    },
    listReportAdvance: async (params: object = null): Promise<any> => {
      return api.get(`credit-request/report/advance`, params);
    },
    generateExportPrefinLimits: async (params: object = null): Promise<any> => {
      return api.post(`credit-request/report/limits-prefin`, params);
    },
    listReportPrefinLimits: async (params: object = null): Promise<any> => {
      return api.get(`credit-request/report/limits-prefin`, params);
    },
    generateExportAdvance: async (params: object = null): Promise<any> => {
      return api.post(`credit-request/report/advance`, params);
    },
    listExport: async (params: object = null): Promise<any> => {
      return api.get(`credit-request/report/all`, params);
    },
    getCreditRequestStatusHistory: async (credit_request_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/${credit_request_id}/history`, params);
    },
    getCreditRequestStatusHistoryAll: async (credit_request_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/${credit_request_id}/history/all`, params);
    },
    getCreditRequestHistory: async (credit_request_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/${credit_request_id}/changelog`, params);
    },
    getCreditRequestResume: async (credit_request_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/${credit_request_id}/resume`, params);
    },
    getComments: async (credit_request_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/${credit_request_id}/comment`, params);
    },
    comment: async (credit_request_id: string, params: object = null): Promise<any> => {
      return api.post(`credit-request/${credit_request_id}/comment`, params);
    },
    justify: async (credit_request_id: string, data: unknown, params: object = null): Promise<any> => {
      return api.post(`credit-request/${credit_request_id}/justify`, data, params);
    },
    upload: async (credit_request_id: string, data: unknown, params: object = null): Promise<any> => {
      return api.post(`credit-request/${credit_request_id}/upload`, data, params);
    },
    getDepositary: async (credit_request_id: string) => {
      return api.get(`credit-request/${credit_request_id}/depositary`);
    },
    deleteDepositary: async (id: string) => {
      return api.delete(`credit-request/depositary/${id}`);
    },
  },
  integrations: {
    getByWorkerId: async (workerId: string, params = {}) => {
      return api.get(`/worker/${workerId}/errors`, params);
    },
    updateSapStatus: async (integrationId: string, params = {}) => {
      return api.put(`credit-request/import/${integrationId}`, params);
    },
    forceSapIntegration: async (creditRequestId: string, params = {}) => {
      return api.post(`sap/commodity-limits/${creditRequestId}`, params);
    },
    forceIntegration: async (route: string, params = {}) => {
      return api.post(route, {}, params);
    },
    getSubsidiarySapCode: async (providerSapCode: string) => {
      return api.get(`person/bpcode/${providerSapCode}`);
    },
  },
  phase: {
    get: async (params: object = null): Promise<any> => {
      return api.get('credit-request/phase', params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get('credit-request/phase/all', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('credit-request/phase', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`credit-request/phase/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`credit-request/phase/${id}`, params);
    },
  },

  status: {
    get: async (params: object = null): Promise<any> => {
      return api.get('credit-request/status', params);
    },
    show: async (status_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/status/${status_id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('credit-request/status', params);
    },
    update: async (status_id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.post(`credit-request/status/${status_id}`, paramsApi);
    },
    delete: async (status_id: string, params: object = null): Promise<any> => {
      return api.delete(`credit-request/status/${status_id}`, params);
    },
  },
  teamStatus: {
    get: async (params: object = null): Promise<any> => {
      return api.get('credit-request/team-status', params);
    },
    show: async (status_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/team-status/${status_id}`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.post('credit-request/team-status/all', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('credit-request/team-status', params);
    },
    update: async (status_id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.post(`credit-request/team-status/${status_id}`, paramsApi);
    },
    delete: async (status_id: string, params: object = null): Promise<any> => {
      return api.delete(`credit-request/team-status/${status_id}`, params);
    },
  },

  partialLimit: {
    get: async (creditRequestId: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/${creditRequestId}/partial-limit`, params);
    },
    store: async (params: object = null, creditRequestId: string): Promise<any> => {
      return api.post(`credit-request/${creditRequestId}/partial-limit`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`credit-request/partial-limit/${id}`, params);
    },
    delete: async (id: string): Promise<any> => {
      return api.delete(`credit-request/partial-limit/${id}`);
    },
    status: async (): Promise<any> => {
      return api.get(`credit-request/partial-limit/status`);
    },
    getTypes: async (params = {}): Promise<PaginationData<GuaranteeTypeData>> => {
      return api.get(`credit-request/partial-limit/types`, params);
    },
    getStatuses: async (params = {}): Promise<PaginationData<GuaranteeTypeData>> => {
      return api.get(`credit-request/partial-limit/status`, params);
    },
    getResume: async (creditRequestId: string, params: object = null): Promise<AxiosResponse<GuaranteesResumeData>> => {
      return api.get<GuaranteesResumeData>(`credit-request/${creditRequestId}/resume-limits`, params);
    },
    updateSapStatus: async (importId: string, params = {}) => {
      return api.put(`credit-request/partial-limit/import/${importId}`, params);
    },
    forceSapIntegration: async (guaranteeId: string, params = {}) => {
      return api.post(`sap/commodity-limits-partial/${guaranteeId}`, params);
    },
    getIntegrationsByGuaranteeId: async (guaranteeId: string, params = {}) => {
      return api.get(`admin/integration/import-errors`, { params: { partialLimit: guaranteeId, ...params } });
    },
  },

  partialLimitApproval: {
    approval: async (params: object = null, partialLimitId: string): Promise<any> => {
      return api.post(`credit-request/${partialLimitId}/partial-limit-approval`, params);
    },
    store: async (params: object = null, partialLimitId: string): Promise<any> => {
      return api.post(`credit-request/${partialLimitId}/partial-limit-store`, params);
    },
    validated: async (params: object = null, partialLimitId: string): Promise<any> => {
      return api.post(`credit-request/${partialLimitId}/partial-limit-validated`, params);
    },
    disapproved: async (params: object = null, partialLimitId: string): Promise<any> => {
      return api.post(`credit-request/${partialLimitId}/partial-limit-disapproved`, params);
    },
    refused: async (params: object = null, partialLimitId: string): Promise<any> => {
      return api.post(`credit-request/${partialLimitId}/partial-limit-refused`, params);
    },
  },

  package: {
    get: async (params: object = null): Promise<any> => {
      return api.get('credit-request/package', params);
    },
    checkPackage: async (params: object = null): Promise<any> => {
      return api.get('credit-request/package/checkPackage', params);
    },
    show: async (status_id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/package/${status_id}`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get('credit-request/package/all', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('credit-request/package', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`credit-request/package/${id}`, params);
    },
    delete: async (status_id: string, params: object = null): Promise<any> => {
      return api.delete(`credit-request/package/${status_id}`, params);
    },
    updatedocument: async (id: string, params: object = null): Promise<any> => {
      return api.put(`credit-request/package/document/${id}`, params);
    },
    destroycomment: async (status_id: string, params: object = null): Promise<any> => {
      return api.delete(`credit-request/package/comment/${status_id}`, params);
    },
    deleteItem: async (status_id: string, params: object = null): Promise<any> => {
      return api.delete(`credit-request/package/document/${status_id}`, params);
    },
  },
  envelope: {
    all: async (params: object = null): Promise<any> => {
      return api.get('envelope/all', params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('envelope/store', params);
    },
    send: async (params: object = null): Promise<any> => {
      return api.post('envelope/send', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.post(`envelope/update/${id}`, params);
    },
    open: async (id: string, params: object = null): Promise<any> => {
      return api.get(`envelope/open/${id}`, params);
    },
    destroycomment: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`envelope/destroy-comment/${id}`, params);
    },
  },

  packageType: {
    get: async (params: object = null): Promise<any> => {
      return api.get('credit-request/package-type', params);
    },
  },
  documentStatus: {
    get: async (params: object = null): Promise<any> => {
      return api.get('credit-request/document-status', params);
    },
  },
  verifyPackageIntegrity: {
    get: async (id: string, params: object = null): Promise<any> => {
      return api.get(`credit-request/verifyPackageIntegrity/${id}`, params);
    },
  },
  signatureType: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`signature-type/all`, params);
    },
  },
  envelopeStatus: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`envelope-status/all`, params);
    },
  },
  recipientType: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`recipient-type/all`, params);
    },
  },
  recipientDelete: {
    delete: async (enveloperecipient: string, params: object = null): Promise<any> => {
      return api.delete(`envelope/destroy-recipient/${enveloperecipient}`, params);
    },
  },
  exposure: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`credit-request/exposure`, params);
    },
  },
  amortization: {
    update: async (creditRequestId: string, params: object = null): Promise<any> => {
      return api.patch(`credit-request/${creditRequestId}/amortization`, params);
    },
  },
  custody: {
    states: async (params: object = null): Promise<any> => {
      return api.get(`/custodia/states`, params);
    },
    cities: async (params: object = null): Promise<any> => {
      return api.get(`/custodia/cities`, params);
    },
    warehouses: async (params: object = null): Promise<any> => {
      return api.get(`/custodia/storages`, params);
    },
  },
};
