import React from 'react';

import { msalConfig } from '@/authConfig';
import { AlertDialogProvider } from '@/components/ui/alert-dialog';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { AbilityProvider } from './ability';
import { AuthProvider } from './auth';
import { CacheProvider } from './cache';
import { CreditStatusProvider } from './creditStatus';
import { NotificationProvider } from './notifications';
import { PageProvider } from './page';
import { SidebarProvider } from './sidebar';

const msalInstance = new PublicClientApplication(msalConfig);

const AppProviders: React.FC = ({ children }) => {
  return (
    <PageProvider>
      <CacheProvider>
        <AlertDialogProvider>
          <MsalProvider instance={msalInstance}>
            <AuthProvider>
              <AbilityProvider>
                <NotificationProvider>
                  <SidebarProvider>
                    <CreditStatusProvider>{children}</CreditStatusProvider>
                  </SidebarProvider>
                </NotificationProvider>
              </AbilityProvider>
            </AuthProvider>
          </MsalProvider>
        </AlertDialogProvider>
      </CacheProvider>
    </PageProvider>
  );
};

export default AppProviders;
