import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  padding: 8px 4px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  > input {
    border-radius: 5px;
    margin-bottom: 10px !important;
  }
`;
