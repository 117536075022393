import { useTranslation } from 'react-i18next';

import { useAlertDialog } from '@/components/ui/alert-dialog';
import api from '@/services/api/api';
import exceptionHandler from '@/services/exceptions';
import PermissionGroupData from '@/types/PermissionGroup/PermissionGroupData';
import { useMutation } from '@tanstack/react-query';

export const useDeletePermissionGroupMutation = () => {
  const { alert } = useAlertDialog();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (data: PermissionGroupData) => {
      if (!data?.id) return alert({ title: t('record-not-found'), type: 'error' });
      const response = await api.delete(`admin/group/${data.id}`);
      return response.data.data;
    },
    mutationKey: ['deletePermissionGroup'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};
