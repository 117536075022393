// import { AxiosRequestConfig } from 'axios';

import api from './api';

export default {
  login: async (params: object = null): Promise<any> => {
    return api.post('auth/login', params);
  },

  validateEmail: async (id: string, hash: string, params: object = null): Promise<any> => {
    return api.get(`email/verify/${id}/${hash}`, params);
  },

  microsoftLogin: async (params: object = null): Promise<any> => {
    return api.post('auth/microsoft', params);
  },

  getUserData: async (): Promise<any> => {
    return api.get('auth/me');
  },

  logout: async (params: object = null): Promise<any> => {
    return api.get('auth/logout', params);
  },

  recoverPassword: async (params: object = null): Promise<any> => {
    return api.post('auth/change-password', params);
  },

  forgotPassword: async (params: object = null): Promise<any> => {
    return api.post('auth/forgot-password', params);
  },
  updateExpiredPassword: async (params: object = null): Promise<any> => {
    return api.post('password/post-expired', params);
  },
  passwordLastChange: async (params: object = null): Promise<any> => {
    return api.get('password/last-change', params);
  },

  twoFactorAuthentication: {
    getQrCode: async (params: object = null): Promise<any> => {
      return api.get('user/two-factor-qr-code', params);
    },
    checkEnabled: async (params: object = null): Promise<any> => {
      return api.get(`user/check`, params);
    },
    sendChallengeCode: async (params: object = null): Promise<any> => {
      return api.post('two-factor-challenge', params);
    },
    enable: async (params: object = null): Promise<any> => {
      return api.post('user/two-factor-authentication', params);
    },
  },
};
