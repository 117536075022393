import { shade } from 'polished';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: #ffffff;
    color: ${({ theme: { colors } }) => colors.text};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 16px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    scrollbar-face-color: ${({ theme: { colors } }) => colors.primary};
    scrollbar-track-color: ${({ theme: { colors } }) => colors.secondary};
    scrollbar-color: ${({ theme: { colors } }) => `${colors.primary} ${colors.secondary}`};
    scrollbar-width: thin;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    background-color: ${({ theme: { colors } }) => colors.greys.grey06};
    width: 8px;

    &:horizontal {
      height: 8px;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme: { colors } }) => colors.secondary};
    width: 8px;
    border-radius: 8px;

    &:horizontal {
      height: 8px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { colors } }) => colors.primary};
    border: 1px solid ${({ theme: { colors } }) => colors.secondary};
    border-radius: 10px;
  }

  html, body {
    display: block;
    height: auto;
    max-height: 100%;
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 99%;
    max-width: 99%;
  }

  .ant-table-pagination.ant-pagination {
    display: flex;
    float: none;
    position: relative;
    padding-top: 40px;
    margin: 0 0 16px 0;

    .ant-pagination-total-text {
      display: flex;
      flex: 1;
      position: absolute;
      left: 0;
      top: 0;
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 12px;
      font-weight: 500;
      color: ${({ theme: { colors } }) => colors.text};
    }

    .ant-pagination-options {
      position: absolute;
      right: 0;
      cursor: pointer;

      .ant-pagination-options-size-changer.ant-select {
        margin-right: 0;

        .ant-select-selector {
          height: 32px;
          display: flex;
          align-items: center;
          border-color: ${({ theme: { colors } }) => colors.primary};
          border-radius: 5px;
          box-shadow: none;
          color: ${({ theme: { colors } }) => colors.primary};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-size: 14px;
          font-weight: 500;
          padding: 4px 8px;
        }

        .ant-select-arrow {
          color: ${({ theme: { colors } }) => colors.primary};
        }
      }
    }


    li > a {
      display: block;
      padding: 3px;
    }

    .ant-menu-inline.ant-menu-root {
      justify-content: center;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      background-color: #ffffff;
      border: solid 1px ${({ theme: { colors } }) => colors.primary};
      border-radius: 5px;
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 14px;
      font-weight: 500;
      height: 32px;
      min-width: 32px;

      a,
      .anticon {
        color: ${({ theme: { colors } }) => colors.primary};
      }

      &:hover,
      &:focus {
        background-color: ${({ theme: { colors } }) => colors.primary};
        border-color: ${({ theme: { colors } }) => colors.primary};
        color: #ffffff;
        cursor: pointer;

        a,
        .anticon {
          color: #ffffff;
        }
      }

      &.ant-pagination-disabled,
      &.ant-pagination-disabled:hover,
      &.ant-pagination-disabled:focus {
        background-color: ${({ theme: { colors } }) => colors.greys.grey03};
        border-color: ${({ theme: { colors } }) => colors.greys.grey05};
        color: ${({ theme: { colors } }) => colors.greys.grey05};

        a,
        .anticon {
          color: ${({ theme: { colors } }) => colors.greys.grey05};
        }
      }
    }

    .ant-pagination-prev {
      margin-right: 6px;
    }

    .ant-pagination-next {
      margin-left: 6px;
    }

    .ant-pagination-item + .ant-pagination-item {
      margin-left: 6px;
    }

    .ant-pagination-item,
    .ant-pagination-item:not(.ant-pagination-item-active) {
      background-color: #ffffff;
      border-color: ${({ theme: { colors } }) => colors.primary};
      color: ${({ theme: { colors } }) => colors.primary};
      border-radius: 5px;
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 14px;
      font-weight: 500;
      height: 32px;
      min-width: 32px;

      a,
      .anticon {
        color: ${({ theme: { colors } }) => colors.primary};
      }

      &:hover,
      &:focus {
        background-color: ${({ theme: { colors } }) => colors.primary};
        border-color: ${({ theme: { colors } }) => colors.primary};
        color: #ffffff;
        cursor: pointer;

        a,
        .anticon {
          color: #ffffff;
        }
      }
    }

    .ant-pagination-item-active {
      background-color: ${({ theme: { colors } }) => colors.primary};
      border-color: ${({ theme: { colors } }) => colors.primary};
      color: #ffffff;
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 14px;
      font-weight: 500;

      a,
      .anticon {
        color: #ffffff;
      }
    }
  }

  .ant-table-wrapper {
    margin-top: 16px;
  }

  .ant-table-filter-dropdown {
    border-radius: 0 0 5px 5px;
    padding: 4px;

    .ant-input-affix-wrapper {
      margin-bottom: 10px;
    }
  }

  .ant-picker {
    border-radius: 5px;
    width: 100%;
  }

  .ant-radio-group {
    &.ant-radio-group-solid {
      border-radius: 0;
    }

    .ant-radio-button-wrapper:first-child {
      border-radius: 4px 0 0 4px;
    }

    .ant-radio-button-wrapper:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  .ant-radio-group,
  .ant-radio-group.custom-radio-group {
    background: ${({ theme: { colors } }) => colors.greys.grey01};
    border-radius: 4px;
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 14px;
    padding: 2px;
    display: flex;

    &.ant-radio-group-solid {
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: ${({ theme: { colors } }) => colors.primary};
        color: #ffffff;
        font-weight: 700;
        z-index: 0;
      }
    }

    .ant-radio-button-wrapper {
      background: transparent;
      border: solid 1px ${({ theme: { colors } }) => colors.greys.grey02};
      border-radius: 0;
      color: ${({ theme: { colors } }) => colors.text};
      line-height: 1rem;
      height: 28px;
      text-align: center;
      padding: 0 16px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      min-width: max-content;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:not(:first-child)::before {
        display: none;
      }
    }

    &.small-radio-group {
      padding: 0;

      .ant-radio-button-wrapper {
        padding: 0 10px;
        height: 24px;
      }
    }
  }

  .ant-input-number {
    display: block;
    width: 100%;
    border-radius: 5px;

    .ant-input-number-handler-up {
      border-top-right-radius: 5px;
    }

    .ant-input-number-handler-down {
      border-bottom-right-radius: 5px;
    }
  }

  .ant-form-item {
    :not(.ant-input-affix-wrapper) .ant-input {
      background-color: #ffffff;
      border: 0;
      border-bottom: solid 3px ${({ theme: { colors } }) => colors.fields.primary.border};
      border-radius: 0;
      color: ${({ theme: { colors } }) => colors.fields.primary.value};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 16px;
      font-weight: 700;
      height: auto;

      &.ant-input-disabled {
        background-color: transparent;
        border-color: ${({ theme: { colors } }) => colors.greys.grey05} !important;
        cursor: not-allowed;
        color: ${({ theme: { colors } }) => colors.greys.grey05} !important;
      }
    }

    .ant-input-affix-wrapper {
      background-color: #ffffff;
      border: 0;
      border-bottom: solid 3px ${({ theme: { colors } }) => colors.fields.primary.border};
      border-radius: 0;
      height: auto;

      &.ant-input-affix-wrapper-disabled {
        background-color: transparent;
        border-color: ${({ theme: { colors } }) => colors.greys.grey05} !important;
        cursor: not-allowed;

        > .ant-input  {
          background-color: transparent;
          color: ${({ theme: { colors } }) => colors.greys.grey05};
        }
      }

      &.ant-input-affix-wrapper-readonly {
        background-color: transparent;
        border-color: ${({ theme: { colors } }) => colors.greys.grey05} !important;

        > .ant-input {
          background-color: transparent;
          color: ${({ theme: { colors } }) => colors.greys.grey05};
        }
      }

      .ant-input,
      .ant-input-password {
        border: 0;
        border-radius: 0;
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 16px;
        font-weight: 700;
      }

      .ant-input-suffix {
        > .anticon {
          &:hover {
            color: ${props => props.theme.colors.primary};
          }
        }
      }

      &:not(.ant-form-item-with-help) .ant-input,
      &:not(.ant-form-item-with-help) .ant-input-password {
        border-color: ${({ theme: { colors } }) => colors.fields.primary.border};
      }
    }

    &.ant-form-item-has-success .ant-input,
    &.ant-form-item-has-success .ant-input:hover,
    &.ant-form-item-has-success .ant-input.textarea,
    &.ant-form-item-has-success .ant-input.textarea:hover,
    &.ant-form-item-has-success .ant-input-affix-wrapper,
    &.ant-form-item-has-success .ant-input-affix-wrapper:hover,
    &.ant-form-item-has-success .ant-input-number,
    &.ant-form-item-has-success .ant-input-number:hover,
    &.ant-form-item-has-success .ant-picker,
    &.ant-form-item-has-success .ant-picker:hover,
    &.ant-form-item-has-success .ant-select,
    &.ant-form-item-has-success .ant-select:hover {
      border-color: ${({ theme: { colors } }) => colors.success};
    }
    &.ant-form-item-has-success .ant-form-item-control .ant-form-item-explain,
    &.ant-form-item-has-success .ant-form-item-control .ant-form-item-split {
      color: ${({ theme: { colors } }) => colors.success};
    }

    &.ant-form-item-has-error .ant-input,
    &.ant-form-item-has-error .ant-input:hover,
    &.ant-form-item-has-error .ant-input.textarea,
    &.ant-form-item-has-error .ant-input.textarea:hover,
    &.ant-form-item-has-error .ant-input-affix-wrapper,
    &.ant-form-item-has-error .ant-input-affix-wrapper:hover,
    &.ant-form-item-has-error .ant-input-number,
    &.ant-form-item-has-error .ant-input-number:hover,
    &.ant-form-item-has-error .ant-picker,
    &.ant-form-item-has-error .ant-picker:hover,
    &.ant-form-item-has-error .ant-select,
    &.ant-form-item-has-error .ant-select:hover {
      border-color: ${({ theme: { colors } }) => colors.danger};
    }
    &.ant-form-item-has-error .ant-form-item-control .ant-form-item-explain,
    &.ant-form-item-has-error .ant-form-item-control .ant-form-item-split {
      color: ${({ theme: { colors } }) => colors.danger};
    }

    &.ant-form-item-has-warning .ant-input,
    &.ant-form-item-has-warning .ant-input:hover,
    &.ant-form-item-has-warning .ant-input.textarea,
    &.ant-form-item-has-warning .ant-input.textarea:hover,
    &.ant-form-item-has-warning .ant-input-affix-wrapper,
    &.ant-form-item-has-warning .ant-input-affix-wrapper:hover,
    &.ant-form-item-has-warning .ant-input-number,
    &.ant-form-item-has-warning .ant-input-number:hover,
    &.ant-form-item-has-warning .ant-picker,
    &.ant-form-item-has-warning .ant-picker:hover,
    &.ant-form-item-has-warning .ant-select,
    &.ant-form-item-has-warning .ant-select:hover {
      border-color: ${({ theme: { colors } }) => colors.warning};
    }
    &.ant-form-item-has-warning .ant-form-item-control .ant-form-item-explain,
    &.ant-form-item-has-warning .ant-form-item-control .ant-form-item-split {
      color: ${({ theme: { colors } }) => colors.warning};
    }

    &.ant-input-custom .ant-input-affix-wrapper {
      max-height: initial !important;
    }

    .ant-form-item-label > label.ant-form-item-required::before {
      color: ${({ theme: { colors } }) => colors.danger};
    }

    .ant-input-number {
      background-color: #ffffff;
      border: 0;
      border-bottom: solid 3px ${({ theme: { colors } }) => colors.fields.primary.border};
      border-radius: 0;
      height: auto;
      min-height: 36px;

      &.ant-input-number-disabled {
        background-color: transparent;
        border-color: ${({ theme: { colors } }) => colors.greys.grey05} !important;
        cursor: not-allowed;

        .ant-input-number-input-wrap .ant-input-number-input  {
          background-color: transparent;
          color: ${({ theme: { colors } }) => colors.greys.grey05};
        }
      }

      .ant-input-number-input-wrap .ant-input-number-input {
        border: 0;
        border-radius: 0;
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 16px;
        font-weight: 700;
      }
    }

    .ant-select {
      background-color: #ffffff;
      border-radius: 0;
      border: 0;
      border-bottom: solid 3px ${({ theme: { colors } }) => colors.fields.primary.border};
      height: auto;

      .ant-select-selector {
        border: 0;
        border-radius: 0;
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 16px;
        font-weight: 700;

        min-height: 33px;
      }

      &.ant-select-disabled {
        background-color: transparent;
        border-color: ${({ theme: { colors } }) => colors.greys.grey05} !important;
        cursor: not-allowed;

        > .ant-select-selector  {
          background-color: transparent;
          color: ${({ theme: { colors } }) => colors.greys.grey05};
        }
      }

      &.ant-select-show-arrow > .ant-select-arrow,
      &.ant-select-allow-clear > .ant-select-clear {
        > .anticon {
          &:hover {
            color: ${props => props.theme.colors.primary};
          }
        }
      }
    }

    .ant-picker {
      background-color: #ffffff;
      border: 0;
      border-bottom: solid 3px ${({ theme: { colors } }) => colors.fields.primary.border};
      border-radius: 0;
      height: auto;

      .ant-picker-input > input {
        border: 0;
        border-radius: 0;
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 16px;
        font-weight: 700;
      }

      &.ant-picker-disabled {
        background-color: transparent;
        border-color: ${({ theme: { colors } }) => colors.greys.grey05} !important;
        cursor: not-allowed;

        .ant-picker-input > input  {
          background-color: transparent;
          color: ${({ theme: { colors } }) => colors.greys.grey05};
        }
      }

      .ant-picker-input > .ant-picker-suffix,
      .ant-picker-input > .ant-picker-clear {
        > .anticon {
          &:hover {
            color: ${props => props.theme.colors.primary};
          }
        }
      }
    }

    .ant-input-affix-wrapper,
    .ant-input-number,
    .ant-select,
    .ant-picker {
      &:hover {
        border-color: ${({ theme: { colors } }) => colors.fields.primary.border};
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
      }

      &:focus {
        border-color: ${({ theme: { colors } }) => colors.fields.primary.border};
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
      }
    }

    &.ant-form-item-with-help .ant-form-item-control {
      .ant-form-item-explain,
      .ant-form-item-extra {
        font-family: ${props => props.theme.fonts.primary} !important;
        font-size: 12px !important;
      }
    }

    .ant-upload.ant-upload-drag {
      border-radius: 5px;
    }
  }

	.form-basic-filter {
		display: flex;
		/* flex-wrap: wrap; */
		align-items: center;
		& > * {
			/* min-width: 200px; */
			flex-shrink: 1;
			flex-grow: 1;
		}

		.buttons {
			display: flex;
			gap: 5px;
			flex-wrap: wrap;

			& > * {
				flex-grow: 1;
				flex-shrink:1;
				max-width: 200px;
			}
		}
	}

  .form-secondary {
    .form-item-without-label {
      display: flex;
      align-items: baseline;
      padding-top: 17px;

      .ant-btn {
        margin: 6px 0;
      }
    }

    &.form-filters {
      .ant-form-item {
        margin-bottom: 0;
      }
    }



    .ant-form-item {
      :not(.ant-input-affix-wrapper) .ant-input {
        background-color: #ffffff;
        border: solid 1px #DAE3E3;
        border-radius: 5px;
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 16px;
        font-weight: 700;
        max-height: 32px;
      }

      .ant-input-affix-wrapper {
        background-color: #ffffff;
        border: solid 1px #DAE3E3;
        border-radius: 5px;

        height: 32px;
        overflow: hidden;

        .ant-input,
        .ant-input-password {
          border: 0;
          border-radius: 5px;
          color: ${({ theme: { colors } }) => colors.fields.primary.value};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-size: 16px;
          font-weight: 700;
        }
      }

      .ant-input-number {
        background-color: #ffffff;
        border: solid 1px #DAE3E3;
        border-radius: 5px;

        height: 32px;
        min-height: 32px;

        .ant-input-number-input-wrap {
          .ant-input-number-input {
            border: 0;
            border-radius: 5px;
            color: ${({ theme: { colors } }) => colors.fields.primary.value};
            font-family: ${({ theme: { fonts } }) => fonts.primary};
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .ant-select {
        background-color: #ffffff;
        border: solid 1px #DAE3E3;
        border-radius: 5px;
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 16px;
        font-weight: 700;

        height: 32px;
        overflow: hidden;

        .ant-select-selector {
          border: 0;
          border-radius: 5px;

          min-height: unset;
          height: 32px;
        }
      }

      .ant-picker {
        background-color: #ffffff;
        border: solid 1px #DAE3E3;
        border-radius: 5px;
        color: ${({ theme: { colors } }) => colors.fields.primary.value};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 16px;
        font-weight: 700;

        height: 32px;

        .ant-picker-input > input {
          border: 0;
          border-radius: 5px;
        }
      }

      &.form-item-without-label .ant-radio-group {
        margin: 7px 0;
      }
    }
  }

  .ant-modal {
    .ant-modal-content {
      border-radius: 5px;

      .ant-modal-header {
        border-radius: 5px 5px 0 0;
      }

      .ant-modal-footer {
        border-radius: 0 0 5px 5px;
      }
    }

    &.modal-with-custom-footer {
      .ant-modal-footer {
        border-top: none;
        padding: 16px;
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    .ant-btn:not(.custom-btn) {
      border-radius: 20px;
      background: ${({ theme: { colors } }) => colors.buttons.secondary.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.secondary.border};
      color: ${({ theme: { colors } }) => colors.buttons.secondary.label};

      &:focus,
      &:active {
        background: ${({ theme: { colors } }) => colors.buttons.secondary.background};
        border-color: ${({ theme: { colors } }) => colors.buttons.secondary.border};
        color: ${({ theme: { colors } }) => colors.buttons.secondary.label};
      }

      &:hover {
        background: ${({ theme: { colors } }) => colors.buttons.secondary.border};
        border-color: ${({ theme: { colors } }) => colors.buttons.secondary.border};
        color: ${({ theme: { colors } }) => colors.text};
      }
    }

    .ant-btn.ant-btn-primary:not(.custom-btn) {
      border-radius: 20px;
      background: ${({ theme: { colors } }) => colors.buttons.primary.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.primary.border};
      color: ${({ theme: { colors } }) => colors.buttons.primary.label};

      &:focus,
      &:active {
        background: ${({ theme: { colors } }) => colors.buttons.primary.background};
        border-color: ${({ theme: { colors } }) => colors.buttons.primary.border};
        color: ${({ theme: { colors } }) => colors.buttons.primary.label};
      }

      &:hover {
        background: ${({ theme: { colors } }) => colors.buttons.primary.border};
        border-color: ${({ theme: { colors } }) => colors.buttons.primary.border};
        color: #ffffff;
      }
    }

    .ant-form-item:last-of-type {
      margin-bottom: 0;
    }
  }

  .modal-status {
    &.modal-status-success .modal-status-icon {
      color: ${({ theme: { colors } }) => colors.success};
    }

    &.modal-status-error .modal-status-icon {
      color: ${({ theme: { colors } }) => colors.danger};
    }

    &.modal-status-info .modal-status-icon {
      color: ${({ theme: { colors } }) => colors.info};
    }

    &.modal-status-warning .modal-status-icon {
      color: ${({ theme: { colors } }) => colors.warning};
    }

    &.modal-status-confirm .modal-status-icon {
      color: ${({ theme: { colors } }) => colors.primary};
    }

    .ant-modal-content {
      border-radius: 5px;
      border: 1px solid #e3eaea;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);

      .modal-status-icon {
        font-size: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 100%;
      }

      .ant-modal-confirm-title {
        color: ${({ theme: { colors } }) => colors.text};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
        text-align: center;
      }

      .ant-modal-confirm-content {
        color: ${({ theme: { colors } }) => colors.text};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        padding: 8px 0;
        text-align: center;

        .modal-status-subtitle {
          color: ${({ theme: { colors } }) => colors.text};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-size: 14px;
          font-weight: 400;
        }

        .modal-status-description {
          margin-top: 15px;
          color: ${({ theme: { colors } }) => colors.text};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .ant-modal-confirm-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      float: none;
      margin-top: 16px;
      padding: 8px 0;

      .ant-btn:not(.custom-btn) {
        border-radius: 20px;
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 14px;
        font-weight: 600;
        min-width: 80px;

        background: ${({ theme: { colors } }) => colors.buttons.secondaryOutline.background};
        border-color: ${({ theme: { colors } }) => colors.buttons.secondaryOutline.border};
        color: ${({ theme: { colors } }) => colors.buttons.secondaryOutline.label};

        &:focus,
        &:active {
          background: ${({ theme: { colors } }) => shade(0.3, colors.buttons.secondaryOutline.background)};
          border-color: ${({ theme: { colors } }) => shade(0.3, colors.buttons.secondaryOutline.border)};
          color: ${({ theme: { colors } }) => colors.buttons.secondaryOutline.label};
        }

        &:hover {
          background: ${({ theme: { colors } }) => shade(0.3, colors.buttons.secondaryOutline.border)};
          border-color: ${({ theme: { colors } }) => shade(0.3, colors.buttons.secondaryOutline.border)};
          color: #ffffff;
        }
      }
    }

    &.modal-status-success .ant-btn.ant-btn-primary:not(.custom-btn) {
      background: ${({ theme: { colors } }) => colors.buttons.success.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.success.border};
      color: ${({ theme: { colors } }) => colors.buttons.success.label};

      &:focus,
      &:active {
        background: ${({ theme: { colors } }) => colors.buttons.success.background};
        border-color: ${({ theme: { colors } }) => colors.buttons.success.border};
        color: ${({ theme: { colors } }) => colors.buttons.success.label};
      }

      &:hover {
        background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.success.background)};
        border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.success.border)};
        color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.success.label)};
      }
    }

    &.modal-status-error .ant-btn.ant-btn-primary:not(.custom-btn) {
      background: ${({ theme: { colors } }) => colors.buttons.danger.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.danger.border};
      color: ${({ theme: { colors } }) => colors.buttons.danger.label};

      &:focus,
      &:active {
        background: ${({ theme: { colors } }) => colors.buttons.danger.background};
        border-color: ${({ theme: { colors } }) => colors.buttons.danger.border};
        color: ${({ theme: { colors } }) => colors.buttons.danger.label};
      }

      &:hover {
        background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.danger.background)};
        border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.danger.border)};
        color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.danger.label)};
      }
    }

    &.modal-status-info .ant-btn.ant-btn-primary:not(.custom-btn) {
      background: ${({ theme: { colors } }) => colors.buttons.info.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.info.border};
      color: ${({ theme: { colors } }) => colors.buttons.info.label};

      &:focus,
      &:active {
        background: ${({ theme: { colors } }) => colors.buttons.info.background};
        border-color: ${({ theme: { colors } }) => colors.buttons.info.border};
        color: ${({ theme: { colors } }) => colors.buttons.info.label};
      }

      &:hover {
        background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.info.background)};
        border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.info.border)};
        color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.info.label)};
      }
    }

    &.modal-status-warning .ant-btn.ant-btn-primary:not(.custom-btn) {
      background: ${({ theme: { colors } }) => colors.buttons.warning.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.warning.border};
      color: ${({ theme: { colors } }) => colors.buttons.warning.label};

      &:focus,
      &:active {
        background: ${({ theme: { colors } }) => colors.buttons.warning.background};
        border-color: ${({ theme: { colors } }) => colors.buttons.warning.border};
        color: ${({ theme: { colors } }) => colors.buttons.warning.label};
      }

      &:hover {
        background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.warning.background)};
        border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.warning.border)};
        color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.warning.label)};
      }
    }

    &.modal-status-confirm .ant-btn.ant-btn-primary:not(.custom-btn) {
      background: ${({ theme: { colors } }) => colors.buttons.primary.background};
      border-color: ${({ theme: { colors } }) => colors.buttons.primary.border};
      color: ${({ theme: { colors } }) => colors.buttons.primary.label};

      &:focus,
      &:active {
        background: ${({ theme: { colors } }) => colors.buttons.primary.background};
        border-color: ${({ theme: { colors } }) => colors.buttons.primary.border};
        color: ${({ theme: { colors } }) => colors.buttons.primary.label};
      }

      &:hover {
        background: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.background)};
        border-color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.border)};
        color: ${({ theme: { colors } }) => shade(0.1, colors.buttons.primary.label)};
      }
    }
  }

  .ant-tree {
    .ant-tree-title {
      color: ${({ theme: { colors } }) => colors.text};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 16px;
      font-weight: 400;
      word-break: break-word;
    }

    .anticon {
      color: ${({ theme: { colors } }) => colors.primary};
    }

    &.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
      .ant-tree-title {
        color: #ffffff;
      }

      .anticon {
        color: #ffffff;
      }
    }


  .ant-tree-checkbox-checked {
    .ant-tree-checkbox-inner {
      background-color:  ${({ theme: { colors } }) => colors.primary};
      border-color:  ${({ theme: { colors } }) => colors.primary};
    }

    &::after {
      border-color: ${({ theme: { colors } }) => colors.primary};
    }
  }

  }

  .custom-tabs {
    > .ant-tabs {
      &.ant-tabs-top {
        .ant-tabs-nav-container {
          .ant-tabs-tab-btn-disabled {
            display: none;
          }
        }

        .ant-tabs-nav {
          padding: 0;
          margin-bottom: 8px;
          width: 100%;

          .ant-tabs-nav-list,
          > div:not(.ant-tabs-nav-operations) {
            width: 100%;
          }

          &::before {
            border-bottom: 1px solid ${({ theme: { colors } }) => colors.greys.grey03};
          }

          .ant-tabs-ink-bar {
            height: 4px;
            background-color: ${({ theme: { colors } }) => colors.primary} !important;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          .ant-tabs-tab,
          .ant-tabs-tab .ant-tabs-tab-btn {
            color: ${({ theme: { colors } }) => colors.text};
            font-family: ${({ theme: { fonts } }) => fonts.primary};
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
            margin: 0;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &.ant-tabs-tab:active,
            &.ant-tabs-tab-active {
              color: ${({ theme: { colors } }) => colors.primary};
            }
          }

          .ant-tabs-tab .ant-tabs-tab-btn {
            padding: 8px;
          }

          .ant-tabs-tab.ant-tabs-tab-active,
          .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: ${({ theme: { colors } }) => colors.primary};
            font-family: ${({ theme: { fonts } }) => fonts.primary};
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }

    .ant-tabs-content-holder {
      overflow: hidden;
    }

    &.credit-request-tabs > .ant-tabs.ant-tabs-top .ant-tabs-nav {
      .ant-tabs-nav-list,
      > div:not(.ant-tabs-nav-wrap) {
        display: grid;
        grid-template-columns: 1.5fr 2fr 2fr 1.5fr 1.5fr 1.5fr;
        grid-template-areas: 'ant-tabs-tab ant-tabs-tab ant-tabs-tab ant-tabs-tab';
      }
    }

    &.credit-request-tabs.credit-request-tabs-new > .ant-tabs.ant-tabs-top .ant-tabs-nav {
      .ant-tabs-nav-list,
      > div:not(.ant-tabs-nav-wrap) {
        display: grid;
        grid-template-columns: 1.5fr 2fr;
        grid-template-areas: 'ant-tabs-tab ant-tabs-tab';
      }
    }

    &.downloads-tabs > .ant-tabs.ant-tabs-top .ant-tabs-nav {
      .ant-tabs-nav-list,
      > div:not(.ant-tabs-nav-wrap) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'ant-tabs-tab ant-tabs-tab';
      }
    }

    &.kanban-tabs > .ant-tabs.ant-tabs-top .ant-tabs-nav {
      .ant-tabs-nav-list,
      > div:not(.ant-tabs-nav-wrap) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'ant-tabs-tab ant-tabs-tab';
      }
    }
  }

  .ant-upload.ant-upload-drag.custom-dragger {
    border-radius: 5px;

    & + .ant-upload-list {
      display: none !important;
    }
  }

  .ant-drawer {
    &.notifications-drawer {
      .ant-drawer-body {
        padding: 0;
      }
    }

    .ant-drawer-title {
      color: ${({ theme: { colors } }) => colors.primary};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .ant-notification {
    .anticon.ant-notification-notice-icon-info {
      color: ${({ theme: { colors } }) => colors.info};
    }

    .ant-notification-notice-with-icon {
      .ant-notification-notice-message,
      .ant-notification-notice-description {
        color: ${({ theme: { colors } }) => colors.text};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 16px;
      }

      .ant-notification-notice-message {
        font-weight: 600;
      }
    }
  }

  .selected-document {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    background: #ffffff;
    border: 1px dashed ${({ theme: { colors } }) => colors.primary};
    border-radius: 5px;

    > span {
      margin-bottom: 4px;
      color: ${({ theme: { colors } }) => colors.text};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 14px;
      font-weight: 400;
    }
  }

  .utils {
    &-danger { color: ${({ theme: { colors } }) => `${colors.danger} !important`}; }
    &-info { color: ${({ theme: { colors } }) => `${colors.info} !important`}; }
    &-success { color: ${({ theme: { colors } }) => `${colors.success} !important`}; }
    &-warning { color: ${({ theme: { colors } }) => `${colors.warning} !important`}; }
    &-primary { color: ${({ theme: { colors } }) => `${colors.primary} !important`}; }
    &-secondary { color: ${({ theme: { colors } }) => `${colors.secondary} !important`}; }
    &-danger-hover:hover { color: ${({ theme: { colors } }) => `${colors.danger} !important`}; }
    &-info-hover:hover { color: ${({ theme: { colors } }) => `${colors.info} !important`}; }
    &-success-hover:hover { color: ${({ theme: { colors } }) => `${colors.success} !important`}; }
    &-warning-hover:hover { color: ${({ theme: { colors } }) => `${colors.warning} !important`}; }
    &-primary-hover:hover { color: ${({ theme: { colors } }) => `${colors.primary} !important`}; }
    &-secondary-hover:hover { color: ${({ theme: { colors } }) => `${colors.secondary} !important`}; }
  }

  .kanban-card-tooltip,
  .status-historic-tooltip,
  .table-tooltip {
    .ant-tooltip-inner {
      font-size: 12px;
    }
  }

  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: ${({ theme: { colors } }) => colors.secondary};
    color: ${({ theme: { colors } }) => colors.greys.grey05};
    font-family: ${({ theme: { colors } }) => colors.primary};
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: auto;
    line-height: 1.5;
    min-height: 24px;
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    text-overflow: unset;
    white-space: normal;
    word-break: break-word;
  }

  .ant-menu-dark {
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-weight: 600;

    &.ant-menu > .ant-menu-item-divider {
      background-color: #514e49;
      height: 3px;
    }

    .ant-menu-item svg,
    .ant-menu-submenu svg {
      margin-right: 8px;
    }

    &.ant-menu-inline-collapsed > .ant-menu-item,
    &.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    &.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
    &.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 24px;
    }

    .ant-menu-item:hover > a,
    .ant-menu-item-active > a,
    .ant-menu-submenu-active > a,
    .ant-menu-submenu-open > a,
    .ant-menu-submenu-selected > a,
    .ant-menu-submenu-title:hover > a,
    .ant-menu-item:hover > span > a,
    .ant-menu-item-active > span > a,
    .ant-menu-submenu-active > span > a,
    .ant-menu-submenu-open > span > a,
    .ant-menu-submenu-selected > span > a,
    .ant-menu-submenu-title:hover > span > a {
      color: ${({ theme: { colors } }) => colors.primary};

      &.active,
      &.active svg {
        color: ${({ theme: { colors } }) => colors.primary};
      }
    }

    .ant-menu-item > a.active,
    .ant-menu-item > span > a.active {
      color: ${({ theme: { colors } }) => colors.primary};
    }

    .ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 24px;
    }
  }

  tr.editing {
    font-weight: bold;

    .ant-table-cell:nth-child(2)::after {
      content: '*';
    }
  }
`;
