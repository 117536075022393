import styled, { css, keyframes } from 'styled-components';

interface SidebarProps {
  isMini: boolean;
}
interface StripProps {
  environment: string;
}

const slideIn = keyframes`
  0% {
    width: 97px;
  }

  100% {
    width: 265px;
  }
`;

const slideOut = keyframes`
  0% {
    width: 265px;
  }

  100% {
    width: 97px;
  }
`;

export const Container = styled.div<SidebarProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.secondary};
  font-family: ${({ theme: { colors } }) => colors.primary};
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.3s;
  z-index: 3;

  &.sidebar-mini {
    animation: ${slideOut} 0.6s;
  }

  &.sidebar-large {
    animation: ${slideIn} 0.6s;
  }

  ${({ isMini }) => {
    if (isMini) {
      return css`
        width: 82px;

        .item {
          height: 40px;

          .item-submenu {
            display: none;
          }

          .item-label {
            display: none;
          }

          svg,
          img {
            width: 100%;
            height: 100%;
            padding: 8px;
            display: flex;
            justify-content: center;
          }
        }

        .logo-company {
          > strong {
            display: none !important;
          }
        }
      `;
    }

    return css`
      max-width: 265px;
      width: 265px;

      .item {
        svg,
        img {
          width: 26px;
          display: inline-flex;
          justify-content: center;
          margin-right: 4px;
        }
      }
    `;
  }}

  .ant-menu-inline-collapsed .ant-menu-item span:not(.changelog-version),
  .ant-menu-inline-collapsed .ant-menu-submenu span {
    display: none;
  }

  .ant-menu-item-icon svg,
  .ant-menu-submenu svg {
    font-size: 18px;
  }

  .ant-menu-inline-collapsed .ant-menu-item svg,
  .ant-menu-inline-collapsed .ant-menu-submenu svg {
    font-size: 26px !important;
  }

  .ant-menu-dark.ant-menu > .ant-menu-item-divider {
    margin: 0;
  }

  .menu-first-item,
  .menu-last-item {
    display: flex;
    justify-content: center;
    padding: ${({ isMini }) => (isMini ? '16px 10px' : 0)};
  }

  .menu-first-item,
  .menu-last-item {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1.5 !important;

    > span:not(.changelog-version) {
      display: none;
    }

    > span.changelog-version {
      display: block;
    }
  }

  .menu-first-item {
    height: ${({ isMini }) => (isMini ? '71px' : '71px')};
  }

  .menu-last-item {
    height: ${({ isMini }) => (isMini ? '91px' : '110px')};
  }

  .ant-menu-item-group {
    /* 
     * Contar os 6px das alturas das divisões do menu e as alturas das seções de logos client e company 
     */
    height: ${({ isMini }) => (isMini ? 'calc(100vh - 190px)' : 'calc(100vh - 227px)')};
    margin: 0;
    overflow-y: auto;
  }

  .show-strip-environment {
    height: ${({ isMini }) => (isMini ? 'calc(100vh - 272px) !important' : 'calc(100vh - 310px) !important')};
  }

  .logo-client {
    border: 0;
    display: flex;
    justify-content: center;
    cursor: default;

    img {
      max-width: 100%;
      ${({ isMini }) => {
        if (isMini) {
          return css`
            margin: 11px 0;
            width: 48px;
          `;
        }
        return css`
          max-height: 48px;
          margin: 10px 0;
          width: auto;
        `;
      }}
    }
  }

  .menu {
    margin: 0;
    padding: 32px 16px;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    max-height: calc(100vh - 220px);

    .title {
      color: #ffffff;
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .logo-company {
    border: 0;
    color: ${({ theme: { colors } }) => colors.greys.grey08};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      color: ${({ theme: { colors } }) => colors.greys.grey08};
      font-size: 11px;
      font-weight: 400;
    }

    > strong {
      display: block;
      font-size: 14px;
      padding-bottom: 0;
      padding-top: 8px;
      text-align: center;
    }

    > a {
      display: flex;
      justify-content: center;

      img {
        ${({ isMini }) => {
          if (isMini) {
            return css`
              margin: 11px 0;
              width: 48px;
            `;
          }
          return css`
            max-height: 48px;
            margin: 10px 0;
            width: auto;
          `;
        }}
      }
    }
  }
`;

export const SStrip = styled.div<StripProps>`
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  ${({ environment }) => {
    switch (environment) {
      case 'localhost':
        return stripEnvironment.dev;
      case 'cofcodev.agrodata.agr.br':
        return stripEnvironment.dev;
      case 'cofcotest.agrodata.agr.br':
        return stripEnvironment.test;
      case 'cofcohomolog.agrodata.agr.br':
        return stripEnvironment.homolog;
      case 'cofcoqas.agrodata.agr.br':
        return stripEnvironment.qas;
      case 'cofcosap.agrodata.agr.br':
        return stripEnvironment.sap;
      case 'cofcosap-qas.agrodata.agr.br':
        return stripEnvironment.sapqas;
      case 'cofcosap-uat.agrodata.agr.br':
        return stripEnvironment.sapuat;
      case 'cofcofeature01.agrodata.agr.br':
        return stripEnvironment.feature01;
      case 'cofcofeature02.agrodata.agr.br':
        return stripEnvironment.feature02;
      case 'cofcofeature03.agrodata.agr.br':
        return stripEnvironment.feature03;
      case 'cofcofeature04.agrodata.agr.br':
        return stripEnvironment.feature04;
      default:
        return stripEnvironment.default;
    }
  }}
`;
const stripEnvironment = {
  default: css`
    background-color: transparent;
  `,
  dev: css`
    background-color: #159734;
  `,
  test: css`
    background-color: #f1d20d;
  `,
  homolog: css`
    background-color: #e03400;
  `,
  qas: css`
    background-color: #f1d20d;
  `,
  sap: css`
    background-color: #00458b;
  `,
  sapqas: css`
    background-color: #007eff;
  `,
  sapuat: css`
    background-color: #5faeff;
  `,
  feature01: css`
    background-color: #58eded;
  `,
  feature02: css`
    background-color: #004545;
  `,
  feature03: css`
    background-color: #337d7d;
  `,
  feature04: css`
    background-color: #009595;
  `,
};
