const APP_PREFIX_APP = 'Agrodata-COFCO';

export const SESSION_USER_PERMISSIONS = `@${APP_PREFIX_APP}:USER:permissions`;
export const SESSION_USER_REGIONS = `@${APP_PREFIX_APP}:USER:regions`;
export const SESSION_USER_ROLES = `@${APP_PREFIX_APP}:USER:roles`;
export const SESSION_USER_SUBSIDIARIES = `@${APP_PREFIX_APP}:USER:subsidiaries`;
export const SESSION_USER_TEAMS = `@${APP_PREFIX_APP}:USER:teams`;
export const SESSION_USER_TOKEN = `@${APP_PREFIX_APP}:USER:token`;
export const SESSION_USER_TOKEN_EXPIRATION = `@${APP_PREFIX_APP}:USER:token_expiration`;
export const SESSION_USER_TOKEN_TYPE = `@${APP_PREFIX_APP}:USER:token_type`;
export const SESSION_USER = `@${APP_PREFIX_APP}:USER:user`;
export const SESSION_USER_LAST_PASS_CHANGE = `@${APP_PREFIX_APP}:USER:password_last_change`;
export const SESSION_USER_IS_EXTERNAL = `@${APP_PREFIX_APP}:USER:is_external`;

export const SESSION_CREDIT_CACHE = `@${APP_PREFIX_APP}:CACHE:credit-request`;
export const SESSION_PERSON_CACHE = `@${APP_PREFIX_APP}:CACHE:person`;

export const SYSTEM_VERSION = `@${APP_PREFIX_APP}:SYSTEM:version`;
