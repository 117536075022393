import { Breadcrumb } from 'antd';
import styled from 'styled-components';

export const SBreadcrumb = styled(Breadcrumb)`
  &.ant-breadcrumb {
    background: #ededed;
    display: block;
    padding: 4px 16px;

    .ant-breadcrumb-link,
    .ant-breadcrumb-link a {
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme: { colors } }) => colors.greys.grey07};

      &:hover {
        color: ${({ theme: { colors } }) => colors.primary};
      }
    }

    & span:last-of-type span.ant-breadcrumb-link,
    & span:last-of-type span.ant-breadcrumb-link a {
      color: ${({ theme: { colors } }) => colors.primary};
    }
  }

  .ant-breadcrumb-separator {
    color: ${({ theme: { colors } }) => colors.greys.grey07} !important;
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 14px;
    font-weight: 400;
  }
`;

export default SBreadcrumb;
