import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { Breadcrumb } from '@/components';
import { FlatButton } from '@/components/ui/button';
import { PageTitle } from '@/components/ui/page-title';
import { Can } from '@/hooks/ability';

import { PermissionGroupsTable } from './permission-groups.table';

export const PermissionGroups = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-col overflow-y-hidden">
      <Breadcrumb items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.users') }]} />

      <div className="flex h-fit max-h-full flex-col gap-2 overflow-y-hidden p-4">
        <div className="flex justify-between">
          <PageTitle>{t('permission-groups')}</PageTitle>

          <Can I="admin.user.store" a="">
            <a href="permission-groups/new" target="_blank" rel="noreferrer">
              <FlatButton>
                <span className="flex items-center gap-2">
                  <FaPlus />
                  {t('register-permission-group')}
                </span>
              </FlatButton>
            </a>
          </Can>
        </div>

        <PermissionGroupsTable />
      </div>
    </div>
  );
};
