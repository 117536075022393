import { Dispatch } from 'react';

import { getURL } from '@/configs/apiURL';
import axios, { AxiosError } from 'axios';

import { SESSION_USER_TOKEN } from '../../configs/storage';
import exceptionHandler from '../exceptions';
import i18n from '../i18next';

const api = axios.create({
  baseURL: import.meta.env.VITE_ENVIRONMENT === 'local' ? getURL() : import.meta.env.VITE_API_BASE_URL,
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  // },
  timeout: 300000,
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem(SESSION_USER_TOKEN);

  config.params = config.params || {};
  config.params.language = i18n.language;
  const configs = config;

  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    const blockedMethods = ['post', 'put', 'patch', 'delete', 'get'];
    if (blockedMethods.includes(config.method ?? '') && getURL().includes('api-cofco')) {
      throw new Error('Você está tentando alterar dados no ambiente de produção');
    }
  }

  if (token) {
    configs.headers.Authorization = `Bearer ${token}`;
  }

  return { ...configs };
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem(SESSION_USER_TOKEN);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export const handleRequest = async (
  request: any,
  loadingCallback: Dispatch<React.SetStateAction<boolean>> = () => false,
) => {
  try {
    loadingCallback(true);
    const response = await request();
    return response.data;
  } catch (err) {
    if ((err as AxiosError).response?.status === 401) {
      localStorage.removeItem(SESSION_USER_TOKEN);
      window.location.href = '/login';
    }
    exceptionHandler(err);
    return null;
  } finally {
    loadingCallback(false);
  }
};

export default api;
