import { TablePaginationConfig } from 'antd';
import { StateCreator } from 'zustand';

export type HistoricSlice = {
  historicStatusTablePaginationConfig: TablePaginationConfig;
  setHistoricStatusTablePaginationConfig: (paginationConfig: TablePaginationConfig) => void;
  historicChangelogTablePaginationConfig: TablePaginationConfig;
  setHistoricChangelogTablePaginationConfig: (paginationConfig: TablePaginationConfig) => void;
};

export const historicSliceInitialState: HistoricSlice = {
  historicStatusTablePaginationConfig: {
    current: 1,
    total: 0,
    pageSize: 10,
  },
  setHistoricStatusTablePaginationConfig: () => null,
  historicChangelogTablePaginationConfig: {
    current: 1,
    total: 0,
    pageSize: 10,
  },
  setHistoricChangelogTablePaginationConfig: () => null,
};

export const createHistoricSlice: StateCreator<HistoricSlice, [], [], HistoricSlice> = set => ({
  ...historicSliceInitialState,
  setHistoricStatusTablePaginationConfig: (paginationConfig: TablePaginationConfig) =>
    set({
      historicStatusTablePaginationConfig: paginationConfig,
    }),
  setHistoricChangelogTablePaginationConfig: (paginationConfig: TablePaginationConfig) =>
    set({
      historicChangelogTablePaginationConfig: paginationConfig,
    }),
});
