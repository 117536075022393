import React from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '@/components/ui/loading';
import { PageTitle } from '@/components/ui/page-title';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import UserData from '@/types/User/UserData';

import { UserPermissionsDivisionsTable } from './user-permissions-table/user-permissions-divisions.table';
import { UserPermissionsOperationsTable } from './user-permissions-table/user-permissions-operations.table';
import { UserPermissionsPhasesTable } from './user-permissions-table/user-permissions-phases.table';
import { UserPermissionsStatusTable } from './user-permissions-table/user-permissions-status.table';
import { UserPermissionsSubdivisionsTable } from './user-permissions-table/user-permissions-subdivisions.table';
import { UserPermissionsTeamsTable } from './user-permissions-table/user-permissions-teams.table';
import { UserPermissionsForm } from './user-permissions.form';

type Props = {
  user: UserData;
  isLoading: boolean;
};

export const UserPermissionsTab = ({ user, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <UserPermissionsForm user={user} />

      <div className="flex h-full w-full flex-col overflow-hidden px-4 pb-10">
        <PageTitle>{t('groups-permissions')}</PageTitle>
        <Loading isLoading={isLoading}>
          <Tabs defaultValue="phases" className="flex h-full w-full flex-col overflow-hidden bg-white">
            <TabsList className="w-full bg-transparent pt-2">
              <TabsTrigger value="phases" title={t('phases')}>
                {t('phases')}
              </TabsTrigger>
              <TabsTrigger value="teams" title={t('teams')}>
                {t('teams')}
              </TabsTrigger>
              <TabsTrigger value="status" title={t('status')}>
                {t('status')}
              </TabsTrigger>
              <TabsTrigger value="divisions" title={t('divisions')}>
                {t('divisions')}
              </TabsTrigger>
              <TabsTrigger value="sub-divisions" title={t('sub-divisions')}>
                {t('sub-divisions')}
              </TabsTrigger>
              <TabsTrigger value="operations" title={t('operations')}>
                {t('operations')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="phases" className="flex max-h-full w-full flex-col overflow-y-hidden">
              <UserPermissionsPhasesTable user={user} />
            </TabsContent>
            <TabsContent value="teams" className="flex max-h-full w-full flex-col overflow-y-hidden">
              <UserPermissionsTeamsTable user={user} />
            </TabsContent>
            <TabsContent value="status" className="flex max-h-full w-full flex-col overflow-y-hidden">
              <UserPermissionsStatusTable user={user} />
            </TabsContent>
            <TabsContent value="divisions" className="flex max-h-full w-full flex-col overflow-y-hidden">
              <UserPermissionsDivisionsTable user={user} />
            </TabsContent>
            <TabsContent value="sub-divisions" className="flex max-h-full w-full flex-col overflow-y-hidden">
              <UserPermissionsSubdivisionsTable user={user} />
            </TabsContent>
            <TabsContent value="operations" className="flex max-h-full w-full flex-col overflow-y-hidden">
              <UserPermissionsOperationsTable user={user} />
            </TabsContent>
          </Tabs>
        </Loading>
      </div>
    </div>
  );
};
