import React from 'react';
import { useTranslation } from 'react-i18next';

import { OPERATION_FERTILIZER_SUPPLIERS_ID } from '@/configs/constants';
import { Tag } from 'antd';

interface Props {
  type: 'credit-request' | string;
}

export const NotificationTag = ({ type }: Props) => {
  const { t } = useTranslation();
  const treatedType = type.replaceAll('@', 'TYPE');

  switch (type) {
    case 'serasa':
      return <Tag color="magenta">{t('pages.notifications.tags.serasa')}</Tag>;
    case 'edocuments':
      return <Tag color="geekblue">{t('pages.notifications.tags.edocuments')}</Tag>;
    case 'survey':
      return <Tag color="volcano">{t('pages.notifications.tags.monitoring')}</Tag>;
    case 'sustainability':
      return <Tag color="green">{t(`pages.notifications.tags.${treatedType}`)}</Tag>;
    case 'sustainability@3627478e-091f-4ce9-8d60-54818dcaa95d':
    case 'sustainability@8957c4da-6168-4b70-9447-68043d65c044':
    case 'sustainability@d3bb44b1-c95e-43d2-ae33-e64c51e92306':
      return <Tag color="green">Sust. {t(`pages.notifications.tags.${treatedType}`)}</Tag>;
    case 'credit-request@71171620-41b7-4c65-b994-3358b8c3f49e':
      return <Tag color="purple">{t('pages.notifications.tags.creditRequestPrefin')}</Tag>;
    case 'credit-request@704a3d8a-aa25-4141-9862-431f85600aab':
      return <Tag color="blue">{t('pages.notifications.tags.creditRequestPrepay')}</Tag>;
    case 'credit-request@3f38c05a-b61f-442d-a374-89cd33aaa3de':
      return <Tag color="cyan">{t('pages.notifications.tags.creditRequestMtm')}</Tag>;
    case 'credit-request@6b5d9a81-e3fc-443b-a0db-f979f9c25ecb':
      return <Tag color="lime">{t('pages.notifications.tags.creditRequestInternMarket')}</Tag>;
    case `credit-request@${OPERATION_FERTILIZER_SUPPLIERS_ID}`:
      return <Tag color="cyan">{t('pages.notifications.tags.creditRequestFertilizerSupplier')}</Tag>;
    case 'credit-request':
      return <Tag color="gold">{t('pages.notifications.tags.creditRequest')}</Tag>;
    default:
      return <Tag>{t('pages.notifications.tags.notification')}</Tag>;
  }
};
