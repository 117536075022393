import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ModalStatus } from '@/components';
import { Button } from '@/components/ui/button';
import { ComboBox } from '@/components/ui/combo';
import { Form } from '@/components/ui/form';
import { FormGrid } from '@/components/ui/form-grid';
import { FormItem } from '@/components/ui/form-item';
import { Input, PasswordInput } from '@/components/ui/input';
import Loading from '@/components/ui/loading';
import { PageTitle } from '@/components/ui/page-title';
import { ProfilePicker } from '@/components/ui/profile-picker';
import { useAuth } from '@/hooks/auth';
import { useCache } from '@/hooks/cache';
import { usePage } from '@/hooks/page';
import { ErrorResponse } from '@/types/ErrorResponse';
import { CacheOptions } from '@/types/Hooks/cache';
import { UserFormData } from '@/types/User/UserFormData';
import { Converter } from '@/utils/Converter';
import { FileUtils } from '@/utils/File';
import { generatePassword } from '@/utils/Password';

import monitoringApi from '../../../services/api/monitoring';
import { useGetUserQuery } from './api/use-get-user.query';
import { useStoreUserMutation } from './api/use-store-user.mutation';
import { useUpdateUserMutation } from './api/use-update-user.mutation';
import { useFilterSubsidiariesByRegion } from './hooks/use-filter-subsidiaries-by-region';
import { useFormPasswordValidation } from './hooks/useFormPasswordValidation';
import { PageParams } from './user-details';

const initialValues: UserFormData = {
  id: '',
  avatar: null,
  roles: [],
  regions: [],
  subsidiaries: [],
  operators: [],
  position_id: '',
  name: '',
  password: '',
  confirm: '',
  email: '',
};

export const UserDetailsTab = () => {
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: initialValues,
  });
  const { updateUserData, roles: userRoles } = useAuth();
  const { alertStatus } = usePage();
  const { getCreditOptions } = useCache();
  const formPasswordValidation = useFormPasswordValidation();
  const filterSubsidiariesByRegion = useFilterSubsidiariesByRegion();

  const selectedRegions = form.watch('regions');

  const [roles, setRoles] = useState<CacheOptions[]>([]);
  const [regions, setRegions] = useState<CacheOptions[]>([]);
  const [subsidiaries, setSubsidiaries] = useState<CacheOptions[]>([]);
  const [operators, setOperators] = useState<CacheOptions[]>([]);

  const params = useParams<PageParams>();
  const { id: idParam } = params;

  const editing = idParam !== 'new';

  const storeUserMutation = useStoreUserMutation();
  const updateUserMutation = useUpdateUserMutation();
  const getUserQuery = useGetUserQuery(idParam !== 'new' ? (idParam ?? '') : '');

  const handleSuccess = (messageSuccess = '') =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleEdit = async (values: UserFormData) => {
    await updateUserMutation.mutateAsync(
      {
        ...values,
        photo: await FileUtils.getBase64(values.avatar),
      },
      {
        onSuccess: response => {
          handleSuccess(t('user-updated-with-success'));
          if (response)
            form.reset({
              id: response.id,
              avatar: response.photo ? FileUtils.base64ToFile(response.photo, 'test') : null,
              name: response.name,
              position_id: response.position_id,
              email: response.email,
              roles: response.roles?.map(r => r.id) ?? [],
              regions: response.regions?.map(r => r.id) ?? [],
              subsidiaries: response.subsidiaries?.map(r => r.id) ?? [],
              operators: response.operators?.map(r => r.id) ?? [],
            });
          updateUserData();
          getUserQuery.refetch();
        },
        onError: err => {
          alertStatus(err, 'error');
        },
      },
    );
  };

  const handleRegister = async (values: UserFormData) => {
    await storeUserMutation.mutateAsync(
      {
        ...values,
        photo: await FileUtils.getBase64(values.avatar),
      },
      {
        onSuccess: () => {
          handleSuccess(t('user-created-with-success'));
          form.reset(initialValues);
          updateUserData();
        },
        onError: err => {
          alertStatus(err, 'error');
        },
      },
    );
  };

  const handleGeneratePassword = () => {
    const password = generatePassword();
    form.reset({ ...form.getValues(), confirm: password, password });
  };

  const loadOperators = async () => {
    await monitoringApi.operator
      .get()
      .then((response: any) => {
        setOperators(response.data.data.data);
      })
      .catch((err: ErrorResponse) => alertStatus(err, 'error'));
  };

  function loadSubsidiaries(regionsValue: string[]) {
    const filteredSubsidiaries = filterSubsidiariesByRegion(getCreditOptions('subsidiary'), regionsValue);
    setSubsidiaries(filteredSubsidiaries);

    const subsidiariesForm = form.getValues('subsidiaries');

    if (subsidiariesForm) {
      const valuesFiltered = filteredSubsidiaries.map(item => item.value);
      const filteredForm = valuesFiltered.filter(id => subsidiariesForm.includes(id));
      form.setValue('subsidiaries', filteredForm);
    }
  }

  useEffect(() => {
    form.setFocus('name');
  }, []);

  useEffect(() => {
    if (getUserQuery.data) {
      form.reset({
        id: getUserQuery.data.id,
        name: getUserQuery.data.name,
        position_id: getUserQuery.data.position_id,
        email: getUserQuery.data.email,
        roles: getUserQuery.data.roles?.map(r => r.id) ?? [],
        regions: getUserQuery.data.regions?.map(r => r.id) ?? [],
        teams: getUserQuery.data.teams?.map(r => r.id) ?? [],
        subsidiaries: getUserQuery.data.subsidiaries?.map(r => r.id) ?? [],
        operators: getUserQuery.data.operators?.map(r => r.id) ?? [],
        avatar: getUserQuery.data.photo ? FileUtils.base64ToFile(getUserQuery.data.photo, 'test') : null,
      });
      setSubsidiaries(
        filterSubsidiariesByRegion(getCreditOptions('subsidiary'), getUserQuery.data.regions?.map(r => r.id) ?? []),
      );
    }
  }, [getUserQuery.data]);

  useEffect(() => {
    setRoles([...userRoles].map(r => ({ value: r.id, key: r.id, label: r.name })));
    setRegions(getCreditOptions('region'));
    setSubsidiaries(getCreditOptions('subsidiary'));
    loadOperators();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="p-4">
      <PageTitle>{t('pages.admin.user.registerTitle')}</PageTitle>
      <Loading isLoading={getUserQuery.isLoading}>
        <Form form={form} onSubmit={form.handleSubmit(editing ? handleEdit : handleRegister)}>
          <FormGrid>
            <div className="mb-6 flex flex-col items-center">
              <span className="font-bold">{t('profile-picture')}</span>
              <FormItem name="avatar" className="items-center">
                <ProfilePicker />
              </FormItem>
            </div>
          </FormGrid>

          <FormGrid>
            <FormItem
              name="name"
              label={t('user-name')}
              rules={{
                required: true,
              }}
            >
              <Input maxLength={255} />
            </FormItem>
            <FormItem
              name="email"
              label={t('email')}
              rules={{
                required: true,
              }}
            >
              <Input maxLength={255} type="email" />
            </FormItem>
            <div />
          </FormGrid>

          {!editing ? (
            <FormGrid>
              <FormItem
                name="password"
                label={t('new-password')}
                rules={{
                  required: true,
                  validate: formPasswordValidation,
                }}
              >
                <PasswordInput maxLength={255} />
              </FormItem>
              <FormItem
                name="confirm"
                label={t('confirm-password')}
                rules={{
                  required: true,
                  validate: {
                    confirm: (value: string) => {
                      if (value !== form.getValues().password) {
                        return t('user.form.password.validator.confirm');
                      }
                      return true;
                    },
                  },
                }}
              >
                <PasswordInput maxLength={255} />
              </FormItem>

              <div className="flex h-full w-full items-end">
                <Button type="button" className="w-full" onClick={handleGeneratePassword}>
                  {t('suggest-password')}
                </Button>
              </div>
            </FormGrid>
          ) : null}

          <FormGrid>
            <FormItem name="position_id" label={t('role')} rules={{ required: true }}>
              <ComboBox options={getCreditOptions('position')} />
            </FormItem>

            <FormItem name="roles" label={t('profiles')} className="col-span-2" rules={{ required: true }}>
              <ComboBox selectAll options={[...roles]} mode="multi" />
            </FormItem>
          </FormGrid>

          <FormGrid>
            <FormItem name="regions" label={t('regions')} rules={{ required: true }}>
              <ComboBox
                options={[...regions]}
                mode="multi"
                selectAll
                onChange={evt => loadSubsidiaries(evt.target.value as any as string[])}
              />
            </FormItem>
          </FormGrid>
          <FormGrid>
            <FormItem name="subsidiaries" label={t('subsidiaries')} rules={{ required: true }}>
              <ComboBox
                options={[...(subsidiaries ?? [])]}
                mode="multi"
                selectAll
                disabled={selectedRegions.length === 0}
              />
            </FormItem>
          </FormGrid>
          <FormGrid>
            <FormItem name="operators" label={t('operators')} rules={{ required: true }}>
              <ComboBox options={Converter.selectOptionArray(operators, 'id', 'id', 'name')} mode="multi" selectAll />
            </FormItem>
          </FormGrid>

          <div className="mt-6 flex w-full justify-between">
            <Button type="button" variant="outline">
              {t('cancel')}
            </Button>
            <Button type="submit" isLoading={storeUserMutation.isPending || updateUserMutation.isPending}>
              {t('save')}
            </Button>
          </div>
        </Form>
      </Loading>
    </div>
  );
};
