import React from 'react';

import { RowProps } from 'antd/lib/grid';

import { SRow } from './styles';

const Row: React.FC<RowProps> = ({ children }) => {
  return <SRow>{children}</SRow>;
};

export default Row;
