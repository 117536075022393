import moment from 'moment';
import { Store } from 'rc-field-form/lib/interface';

import appConfig from '../configs/app';

export const formatValuesToFormData = (
  formValues: Store,
  formData: FormData,
  fileKeys: string[] = [],
  ignoreKeys: string[] = [],
  arrayToJson = false,
) => {
  Object.keys(formValues).forEach((key: string) => {
    if (ignoreKeys.indexOf(key) === -1) {
      if (fileKeys.indexOf(key) > -1) {
        formValues[key]?.forEach((file: File) => formData.append(key, file));
      } else {
        if (formValues[key] !== undefined && formValues[key] !== null && key !== 'documents[]') {
          if (typeof formValues[key] === 'object') {
            if (moment.isMoment(formValues[key])) {
              if (moment(formValues[key]).format(appConfig.formatApiDate).toString() !== 'Invalid date') {
                formData.append(key, moment(formValues[key]).format(appConfig.formatApiDate));
              }
            } else {
              if (arrayToJson) {
                formData.append(key, JSON.stringify(formValues[key]));
              } else {
                formValues[key].map((value: string) => formData.append(key, value));
              }
            }
          } else if (typeof formValues[key] === 'boolean') {
            formData.append(key, formValues[key] === true ? '1' : '0');
          } else {
            formData.append(key, formValues[key]);
          }
        }
      }
    }
  });

  return formData;
};
