import { ValidateMessages } from 'rc-field-form/lib/interface';

function messages(lang: string): ValidateMessages {
  /* eslint no-template-curly-in-string: "off" */
  const typeTemplate = "'${label}' não é um tipo valido de ${type}";
  switch (lang) {
    case 'pt-br':
    case 'pt-BR':
      return {
        default: "Erro de validação no campo '${label}'",
        required: "'${label}' é obrigatório",
        enum: "'${label}' deve ser um dos [${enum}]",
        whitespace: "'${label}' não pode ser vazio",
        date: {
          format: "'${label}' é inválido para o formato 'date'",
          parse: "'${label}' não pode ser alterado para 'date'",
          invalid: "'${label}' não é uma data válida",
        },
        types: {
          string: typeTemplate,
          method: typeTemplate,
          array: typeTemplate,
          object: typeTemplate,
          number: typeTemplate,
          date: typeTemplate,
          boolean: typeTemplate,
          integer: typeTemplate,
          float: typeTemplate,
          regexp: typeTemplate,
          email: typeTemplate,
          url: typeTemplate,
          hex: typeTemplate,
        },
        string: {
          len: "'${label}' deve ter exatamente ${len} caracteres",
          min: "'${label}'deve ter ao menos ${min} caracteres",
          max: "'${label}' não deve ser maior que ${max} caracteres",
          range: "'${label}' deve ter entre ${min} e ${max} caracteres",
        },
        number: {
          len: "'${label}' deve ser igual a ${len}",
          min: "'${label}' não pode ser menor que ${min}",
          max: "'${label}' não pode ser maior que ${max}",
          range: "'${label}' deve ser entre ${min} and ${max}",
        },
        array: {
          len: "'${label}' deve ser exatamente ${len} de tamanho",
          min: "'${label}' não pode ser menor que ${min} de tamanho",
          max: "'${label}' não pode ser maior que ${max} de tamanho",
          range: "'${label}' deve ter entre ${min} e ${max} de tamanho",
        },
        pattern: {
          mismatch: "'${label}' não corresponde ao padrão ${pattern}",
        },
      };
    default:
      return {
        default: "Validation error on field '${label}'",
        required: "'${label}' is required",
        enum: "'${label}' must be one of [${enum}]",
        whitespace: "'${label}' cannot be empty",
        date: {
          format: "'${label}' is invalid for format date",
          parse: "'${label}' could not be parsed as date",
          invalid: "'${label}' is invalid date",
        },
        types: {
          string: typeTemplate,
          method: typeTemplate,
          array: typeTemplate,
          object: typeTemplate,
          number: typeTemplate,
          date: typeTemplate,
          boolean: typeTemplate,
          integer: typeTemplate,
          float: typeTemplate,
          regexp: typeTemplate,
          email: typeTemplate,
          url: typeTemplate,
          hex: typeTemplate,
        },
        string: {
          len: "'${label}' must be exactly ${len} characters",
          min: "'${label}' must be at least ${min} characters",
          max: "'${label}' cannot be longer than ${max} characters",
          range: "'${label}' must be between ${min} and ${max} characters",
        },
        number: {
          len: "'${label}' must equal ${len}",
          min: "'${label}' cannot be less than ${min}",
          max: "'${label}' cannot be greater than ${max}",
          range: "'${label}' must be between ${min} and ${max}",
        },
        array: {
          len: "'${label}' must be exactly ${len} in length",
          min: "'${label}' cannot be less than ${min} in length",
          max: "'${label}' cannot be greater than ${max} in length",
          range: "'${label}' must be between ${min} and ${max} in length",
        },
        pattern: {
          mismatch: "'${label}' does not match pattern ${pattern}",
        },
      };
  }
}

export default messages;
export { messages };
