export const appConfigs = {
  clientTitle: import.meta.env.VITE_TITLE_CLIENT,
  companyTitle: import.meta.env.VITE_TITLE_COMPANY,
  companySite: import.meta.env.VITE_SITE_COMPANY,
  formatApiDate: 'YYYY-MM-DD',
  formatDate: 'DD/MM/YYYY',
  formatDateTime: 'DD/MM/YYYY HH:mm',
  formatDateFullTime: 'DD/MM/YYYY HH:mm:ss',
  formatTime: 'HH:mm',
};

export default appConfigs;
