import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  sidebarIsMini: boolean;
}

const contentToFull = keyframes`
  0% {
    left: 97px;
    width: calc(100% - 97px);
  }

  100% {
    left: 265px;
    width: calc(100% - 265px);
  }
`;

const contentToSmall = keyframes`
  0% {
    left: 265px;
    width: calc(100% - 265px);
  }

  100% {
    left: 97px;
    width: calc(100% - 97px);
  }
`;

const maxWidthMd = '991px';

export const Container = styled.header<ContainerProps>`
  background-color: #ffffff;
  border-bottom: 3px solid ${({ theme: { colors } }) => colors.greys.grey03};
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;

  &.sidebar-mini {
    animation: ${contentToSmall} 0.6s;
  }

  &.sidebar-large {
    animation: ${contentToFull} 0.6s;
  }

  ${({ sidebarIsMini }) => {
    if (sidebarIsMini) {
      return css`
        left: 82px;
        width: calc(100% - 82px);
      `;
    }
    return css`
      left: 265px;
      width: calc(100% - 265px);
    `;
  }}

  @media (max-width: ${maxWidthMd}) {
    min-height: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-base-actions {
    display: flex;
  }
`;

export const ToggleSidebar = styled.div`
  color: ${({ theme: { colors } }) => colors.primary};
  cursor: pointer;
  font-size: 28px;
  margin-right: 30px;
`;

export const BackLink = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 6px 16px;
  color: ${({ theme: { colors } }) => colors.text};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin-right: 6px;
  }
`;

export const ContainerDropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: solid ${({ theme: { colors } }) => colors.greys.grey02} 1px;
  padding: 0;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;

    &.notifications {
      border-right: solid ${({ theme: { colors } }) => colors.greys.grey02} 1px;
      color: ${({ theme: { colors } }) => colors.primary};
      padding-right: 20px;
      padding-left: 10px;
    }

    .person-data {
      min-width: 180px;
    }

    .person {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 12px;
      text-align: left;

      .name-person {
        color: ${({ theme: { colors } }) => colors.primary};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 14px;
        font-weight: 700;
        margin: 0;
      }

      .profile-person {
        color: ${({ theme: { colors } }) => colors.greys.grey09};
        margin: 0;
        font-size: 12px;
      }
    }

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;

      .img-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 1px solid transparent;
        border-radius: 50px;
      }

      svg {
        color: ${({ theme: { colors } }) => colors.greys.grey06};
        cursor: pointer;
        font-size: 20px;
        margin: 0 0 0 8px;
      }
    }
  }
`;
